import { SvgIcon, SvgIconProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const ScopeAndSequenceIcon = (props: SvgIconProps) => {
  const { stroke, fill, ...SvgIconProps } = props;
  return (
    <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" {...SvgIconProps}>
      <path
        d="M2.33325 4.33356C2.33325 3.22899 3.22868 2.33356 4.33325 2.33356H8.33325C9.43782 2.33356 10.3333 3.22899 10.3333 4.33356V8.33356C10.3333 9.43813 9.43782 10.3336 8.33325 10.3336H4.33325C3.22868 10.3336 2.33325 9.43813 2.33325 8.33356V4.33356Z"
        fill={fill ?? hootTokens.palette.neutral['100']}
        stroke={stroke ?? hootTokens.palette.black}
        strokeWidth="1.5"
      />
      <path
        d="M22.3333 6.33356C22.3333 8.5427 20.5424 10.3336 18.3333 10.3336C16.1242 10.3336 14.3333 8.5427 14.3333 6.33356C14.3333 4.12442 16.1242 2.33356 18.3333 2.33356C20.5424 2.33356 22.3333 4.12442 22.3333 6.33356Z"
        fill={fill ?? hootTokens.palette.neutral['100']}
        stroke={stroke ?? hootTokens.palette.black}
        strokeWidth="1.5"
      />
      <path
        d="M10.3333 18.3336C10.3333 20.5427 8.54239 22.3336 6.33325 22.3336C4.12411 22.3336 2.33325 20.5427 2.33325 18.3336C2.33325 16.1245 4.12411 14.3336 6.33325 14.3336C8.54239 14.3336 10.3333 16.1245 10.3333 18.3336Z"
        fill={fill ?? hootTokens.palette.neutral['100']}
        stroke={stroke ?? hootTokens.palette.black}
        strokeWidth="1.5"
      />
      <path
        d="M14.3333 16.3336C14.3333 15.229 15.2287 14.3336 16.3333 14.3336H20.3333C21.4379 14.3336 22.3333 15.229 22.3333 16.3336V20.3336C22.3333 21.4382 21.4379 22.3336 20.3333 22.3336H16.3333C15.2287 22.3336 14.3333 21.4382 14.3333 20.3336V16.3336Z"
        fill={fill ?? hootTokens.palette.neutral['100']}
        stroke={stroke ?? hootTokens.palette.black}
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};

export default ScopeAndSequenceIcon;
