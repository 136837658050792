import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { NativeSelectInputProps } from '@mui/material/NativeSelect/NativeSelectInput';
import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { Select } from '@hoot/ui/components/v2/core/Select';
import { FocusAreaId } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/ScopeAndSequenceFocusAreaCard';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface IFocusArea {
  id: string;
  title: string;
  imageUrl: string;
  imageAlt: string;
}

const focusAreas: IFocusArea[] = [
  // TODO J: Get Dustin to provide lower quality images for these tiles when he's back to work.
  {
    id: FocusAreaId.preWordReading,
    title: 'Pre-word Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/pre-word-reading-tile-image.png',
    imageAlt: 'Pre-word reading splash image',
  },
  {
    id: FocusAreaId.earlyWordReading,
    title: 'Early-word Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/early-word-reading-tile-image.png',
    imageAlt: 'Early-word reading splash image',
  },
  {
    id: FocusAreaId.complexWordReading,
    title: 'Complex-word Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/complex-word-reading-tile-image.png',
    imageAlt: 'Complex-word reading splash image',
  },
  {
    id: FocusAreaId.textReading,
    title: 'Text Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/text-reading-tile-image.png',
    imageAlt: 'Text-word reading splash image',
  },
];

const ScopeAndSequencePage = () => {
  const { focusAreaId } = useParams<{ focusAreaId: FocusAreaId }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.values(FocusAreaId).includes(focusAreaId!)) {
      navigate(routesDictionary.scopeAndSequence.focusArea.url(FocusAreaId.preWordReading), { replace: true });
    }
  }, [focusAreaId, navigate]);

  return (
    <>
      <SubHeader
        title={{
          label: (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '50px',
                  height: '50px',
                  borderRadius: '8px',
                  border: '1px solid',
                  borderColor: hootTokens.surface[4],
                  background: hootTokens.palette.white,
                  boxShadow: hootTokens.elevation.elevation2,
                }}
              >
                <img src="/images/hoot-shapes.svg" alt="scope-and-sequence-logo" height="33px" width="33px" />
              </Box>
              Hoot Scope & Sequence
            </Stack>
          ),
          isPII: false,
        }}
      />

      <Page pageTitle="Scope & Sequence | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ md: 4, xs: 12 }}>
            <FocusAreaSelectorCard selectedFocusAreaId={focusAreaId!} />
          </Grid>
          <Grid size={{ md: 8, xs: 12 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

interface FocusAreaSelectorCardProps {
  selectedFocusAreaId: FocusAreaId;
}

const FocusAreaSelectorCard = (props: FocusAreaSelectorCardProps) => {
  const { selectedFocusAreaId } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const onFocusAreaSelectChange: NativeSelectInputProps['onChange'] = (event) => {
    navigate(routesDictionary.scopeAndSequence.focusArea.url(event.target.value), { replace: true });
  };

  return (
    <Card title="Select a Focus Area">
      {isMobile && (
        <Select label="Drop Down Options" value={selectedFocusAreaId} onChange={onFocusAreaSelectChange} sx={{ width: '100%' }}>
          {focusAreas.map((fa) => (
            <option key={fa.id} value={fa.id}>
              {fa.title}
            </option>
          ))}
        </Select>
      )}
      {!isMobile && (
        <>
          {focusAreas.map((fa) => (
            <Stack
              key={fa.id}
              direction="row"
              alignItems="center"
              gap={2}
              p={1}
              sx={{
                borderRadius: '8px',
                ...(selectedFocusAreaId === fa.id && {
                  border: '2px solid',
                  borderColor: hootTokens.palette.success['100'],
                  background: hootTokens.palette.success['160'],
                }),
              }}
            >
              <AsyncImage
                src={fa.imageUrl}
                alt={fa.imageAlt}
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '8px',
                  objectFit: 'cover',
                  ...(selectedFocusAreaId !== fa.id && {
                    filter: 'grayscale(100%)',
                  }),
                }}
              />
              <Link to={fa.id} replace>
                <HootTypography variant="labellarge" isPII={false}>
                  {fa.title}
                </HootTypography>
              </Link>
            </Stack>
          ))}
        </>
      )}
    </Card>
  );
};

export default ScopeAndSequencePage;
