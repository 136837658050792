import { DialogContent, DialogTitle, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BackdroplessDialog from '@hoot/ui/components/v2/core/BackdroplessDialog';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { LottieMultiSelectButton } from '@hoot/ui/components/v2/lottie/LottieMultiSelectButton';
import { StudentDecodableLibraryFilterOptionSelectProps } from '@hoot/ui/pages/v2/student/library/StudentDecodableAccessLibraryFilterDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentDecodableAccessLibraryFilterSelectDialogProps {
  isOpen: boolean;
  filterOptionSelect: StudentDecodableLibraryFilterOptionSelectProps | undefined;
  defaultValue: null | string | Set<string>;
  onApply: (value: string | Set<string>) => void;
  onCancel: () => void;
  onDialogDismissAnimationCompleted: () => void;
}

const StudentDecodableAccessLibraryFilterSelectDialog = (props: StudentDecodableAccessLibraryFilterSelectDialogProps) => {
  const { isOpen, filterOptionSelect, defaultValue, onApply, onCancel, onDialogDismissAnimationCompleted } = props;

  const [filterSelection, setFilterSelection] = useState(defaultValue);

  // Reset default selection when dialog is opened.
  useEffect(
    () => {
      if (isOpen) {
        setFilterSelection(defaultValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  const onApplyMultiFilterSelect = () => {
    onApply(filterSelection as Set<string>);
  };

  return (
    <BackdroplessDialog
      open={isOpen && !!filterOptionSelect}
      onClose={onCancel}
      TransitionProps={{
        onExited: () => onDialogDismissAnimationCompleted(),
      }}
    >
      <DialogTitle
        component={'div'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 5,
          height: '104px',
          ...hootTokens.text.displaysmall,
        }}
      >
        <span>{filterOptionSelect?.label}</span>
        <Stack direction={'row'}>
          <LottieButton
            tooltipLabel="Cancel"
            aria-label="Cancel"
            lottieFile={LottieFile.ErrorCross}
            onClick={onCancel}
            variant="transparent"
            sx={{ p: 1, width: '104px', height: '104px' }}
          />
          {filterOptionSelect?.isMultiSelect && (
            <LottieButton
              lottieFile={LottieFile.ApprovedCheck}
              tooltipLabel={`Apply ${filterOptionSelect.label}`}
              aria-label={`Apply ${filterOptionSelect.label}`}
              onClick={onApplyMultiFilterSelect}
              variant="transparent"
              sx={{ p: 1, width: '104px', height: '104px' }}
            />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 5, px: 5 }}>
        <Stack gap={5}>
          {Object.entries(filterOptionSelect?.options ?? {}).map(([value, label]) => {
            if (filterOptionSelect?.isMultiSelect) {
              // Note: if isMultiSelect flag is true, then we're dealing with a Set<string>.
              const valueSet = new Set(filterSelection as Set<string>);

              const toggleValue = () => {
                if (valueSet.has(value)) {
                  valueSet.delete(value);
                } else {
                  valueSet.add(value);
                }
                setFilterSelection(valueSet);
              };
              return (
                <LottieMultiSelectButton key={value} onClick={toggleValue} isSelected={valueSet.has(value)}>
                  {label}
                </LottieMultiSelectButton>
              );
            } else {
              return (
                <PlainButton key={value} onClick={() => onApply(value)}>
                  {label}
                </PlainButton>
              );
            }
          })}
        </Stack>
      </DialogContent>
    </BackdroplessDialog>
  );
};

export default StudentDecodableAccessLibraryFilterSelectDialog;
