import { PMAUnitIdentifier } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment';
import { PMAStepTestAnswer } from '@hoot-reading/hoot-core/dist/enums/progress-monitoring-assessment/pma-step-test-answer.enum';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSearchPMAUnitSubmissionData, {
  ProgressMonitoringAssessmentUnitSubmissionDataResponse,
} from '@hoot/hooks/api/pma/useSearchPMAUnitSubmissionData';
import { PMAUnitSubmission, useSubmitPMAUnit } from '@hoot/hooks/api/pma/useSubmitAssessmentUnit';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import QuestionnaireLayout3, { Q3UnitQuestionAnswerPair } from '@hoot/ui/components/v2/assessments/teacher/questionnaires/QuestionnaireLayout3';
import { pmaInstructions, pmaTitles } from '@hoot/ui/pages/v2/teacher/lesson/progress-monitoring-assessment/enum';
import useTeacherPMAController from '@hoot/ui/pages/v2/teacher/lesson/progress-monitoring-assessment/useTeacherPMAController';

export interface PMAQuestionnaireLayout_3_Prompts {
  sentence: string;
  wordToTest: string;
}

interface Props {
  unitIdentifier:
    | PMAUnitIdentifier.Module_2_Unit_1
    | PMAUnitIdentifier.Module_2_Unit_2
    | PMAUnitIdentifier.Module_2_Unit_3
    | PMAUnitIdentifier.Module_2_Unit_4
    | PMAUnitIdentifier.Module_2_Unit_5
    | PMAUnitIdentifier.Module_2_Unit_6
    | PMAUnitIdentifier.Module_3_Unit_1
    | PMAUnitIdentifier.Module_3_Unit_2
    | PMAUnitIdentifier.Module_3_Unit_3
    | PMAUnitIdentifier.Module_3_Unit_4
    | PMAUnitIdentifier.Module_3_Unit_5
    | PMAUnitIdentifier.Module_3_Unit_6;
  prompts: PMAQuestionnaireLayout_3_Prompts[];
}

const PMA_QUESTIONNAIRE_LAYOUT_3 = (props: Props) => {
  const { prompts, unitIdentifier } = props;

  const [hasUnitBeenSubmitted, setHasUnitBeenSubmitted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(0);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [questionAnswerPairs, setQuestionAnswerPairs] = useState<Q3UnitQuestionAnswerPair[]>(
    prompts.map<Q3UnitQuestionAnswerPair>((p, i) => ({
      displayIndex: i + 1,
      fullPrompt: p.sentence,
      wordPrompt: p.wordToTest,
      answer: PMAStepTestAnswer.NoAnswer, // Note: if submission data exists, we'll overwrite this.
    })),
  );
  const currentStudentPrompt = currentQuestionIndex !== null ? questionAnswerPairs[currentQuestionIndex]?.fullPrompt : undefined;

  const isFormIncomplete = questionAnswerPairs.length > 0 && questionAnswerPairs.some((q) => q.answer === PMAStepTestAnswer.NoAnswer);

  const dispatch = useAppDispatch();
  const pmaController = useTeacherPMAController();

  const assessmentId = useSelector((state: RootState) => state.pma.assessmentId);
  const isNextEnabled = useSelector((state: RootState) => state.pma.isNextEnabled);
  const isPrevEnabled = useSelector((state: RootState) => state.pma.isPrevEnabled);
  const isUnitComplete = useSelector((state: RootState) => state.pma.isUnitComplete);
  const lesson = useSelector((state: RootState) => state.activeLesson.inLesson)!;

  const { isFetching } = useSearchPMAUnitSubmissionData(assessmentId!, unitIdentifier, {
    enabled: !!assessmentId,
    onSuccess: (data: ProgressMonitoringAssessmentUnitSubmissionDataResponse) => {
      if (!!data.submissionData) {
        // Note: we're actually replacing the questions here too, in case the prior submission data used a different
        // set of questions (however likely that may be)
        setHasUnitBeenSubmitted(true);
        setQuestionAnswerPairs(data.submissionData as Q3UnitQuestionAnswerPair[]);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the PMA submission data.` }));
    },
  });

  const submitUnitRequest = useSubmitPMAUnit(assessmentId, unitIdentifier);

  // Once the form is completely filled out, emit a "complete" event. The student
  useEffect(
    () => {
      if (!isFormIncomplete && !isUnitComplete) {
        pmaController.onUnitComplete(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFormIncomplete, isUnitComplete],
  );

  // When the current question changes, we need to tell the student what to see.
  useEffect(
    () => {
      if (currentStudentPrompt) {
        pmaController.onSetText(currentStudentPrompt);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStudentPrompt],
  );

  const onSubmitUnit = () => {
    const answers = questionAnswerPairs.filter((x) => !x.isExampleQuestion);
    const unitSubmissionData: PMAUnitSubmission = {
      studentProfileId: lesson.studentId,
      teacherAccountId: lesson.teacherId,
      lessonId: lesson.lessonId,
      submissionData: answers,
    };
    submitUnitRequest.mutate(unitSubmissionData, {
      onSuccess: () => {
        setIsFormDirty(false);
        dispatch(createFlashMessage({ message: 'Unit submission successfully sent.' }));
        pmaController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: 'There was a problem saving the unit submission.' }));
      },
    });
  };

  const onNavToPreviousUnit = () => {
    pmaController.onPrevUnit();
  };

  const onSelectQuestionAtIndex = (index: number | null) => setCurrentQuestionIndex(index);

  const onQuestionAnswered = (questionIndex: number, answer: PMAStepTestAnswer) => {
    // If the new answer is the same as the previous answer, then we don't need to do anything.
    if (questionAnswerPairs[questionIndex].answer === answer) {
      return;
    }
    const updatedQAPairs = [...questionAnswerPairs];
    updatedQAPairs[questionIndex].answer = answer;
    setQuestionAnswerPairs(updatedQAPairs);
    setIsFormDirty(true);
  };

  return (
    <>
      {!isFetching && (
        <QuestionnaireLayout3
          unitIdentifier={unitIdentifier}
          title="Progress Monitoring Assessment"
          unitTitle={pmaTitles[unitIdentifier]}
          instruction={pmaInstructions[unitIdentifier]}
          isFormIncomplete={isFormIncomplete}
          currentQuestionIndex={currentQuestionIndex}
          onSelectQuestionAtIndex={onSelectQuestionAtIndex}
          questionAnswerPairs={questionAnswerPairs}
          onQuestionAnswered={onQuestionAnswered}
          isFormDirty={isFormDirty}
          isSubmitting={submitUnitRequest.isLoading}
          onNavToPreviousUnit={onNavToPreviousUnit}
          onSubmit={onSubmitUnit}
          hasUnitBeenSubmitted={hasUnitBeenSubmitted}
          isNextUnitEnabled={isNextEnabled}
          isPreviousUnitEnabled={isPrevEnabled}
          onExitAssessment={pmaController.onExitAssessment}
        />
      )}
    </>
  );
};

export default PMA_QUESTIONNAIRE_LAYOUT_3;
