import { Box, Fade, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useGetInstructionalUnits from '@hoot/hooks/api/library/useGetInstructionalUnits';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import GrowList from '@hoot/ui/components/v2/GrowList';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import ScopeAndSequenceIcon from '@hoot/ui/components/v2/icons/ScopeAndSequenceIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const splashImageTransitionTimeoutMs = 500;

export enum FocusAreaId {
  preWordReading = 'pre-word-reading',
  earlyWordReading = 'early-word-reading',
  complexWordReading = 'complex-word-reading',
  textReading = 'text-reading',
}

const focusAreaDataDictionary: Record<
  FocusAreaId,
  {
    instructionalFocusId: string;
    label: string;
    imageUrl: string;
    imageAlt: string;
    tileColours: { primary: string; secondary: string }[];
  }
> = {
  [FocusAreaId.preWordReading]: {
    instructionalFocusId: '062ff663-d269-00ac-7703-c944874cde5e',
    label: 'Pre-word Reading',
    // TODO J: Ask Duster for lower quality images!
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/pre-word-reading-splash-image.png',
    imageAlt: 'Pre-word reading splash image',
    tileColours: [{ primary: '#740128', secondary: '#815363' }],
  },
  [FocusAreaId.earlyWordReading]: {
    instructionalFocusId: 'd21bfa7f-7ae4-1847-d931-668c388b02e9',
    label: 'Early-word Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/early-word-reading-splash-image.png',
    imageAlt: 'Early-word reading splash image',
    tileColours: [
      { primary: '#B80201', secondary: '#815363' },
      { primary: '#A7006A', secondary: '#A55387' },
      { primary: '#111C54', secondary: '#576193' },
      { primary: '#01591C', secondary: '#387A4C' },
      { primary: '#180D87', secondary: '#5C55A9' },
      { primary: '#880394', secondary: '#AA51B2' },
    ],
  },
  [FocusAreaId.complexWordReading]: {
    instructionalFocusId: '72e9e1a2-56c4-d876-3f54-e1f84945548d',
    label: 'Complex-word Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/complex-word-reading-splash-image.png',
    imageAlt: 'Complex-word reading splash image',
    tileColours: [
      { primary: '#034B73', secondary: '#306989' },
      { primary: '#034140', secondary: '#276766' },
      { primary: '#660264', secondary: '#873886' },
      { primary: '#2E0065', secondary: '#593980' },
      { primary: '#405F3F', secondary: '#448342' },
      { primary: '#4B0C2B', secondary: '#994870' },
    ],
  },
  [FocusAreaId.textReading]: {
    instructionalFocusId: '53a6971a-d545-465d-6029-c50a525ccd90',
    label: 'Text Reading',
    imageUrl: 'https://images.hootreading.com/scope-and-sequence/text-reading-splash-image.png',
    imageAlt: 'Text reading splash image',
    tileColours: [{ primary: hootTokens.palette.black, secondary: hootTokens.palette.neutral['100'] }],
  },
};

const ScopeAndSequenceFocusAreaCard = () => {
  const { focusAreaId } = useParams<{ focusAreaId: FocusAreaId }>();

  const { instructionalFocusId, label, imageUrl, imageAlt, tileColours } = focusAreaDataDictionary[focusAreaId!] ?? {};

  const [splashImageLoaded, setSplashImageLoaded] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getInstructionalUnitsRequest = useGetInstructionalUnits(
    {
      instructionalFocuses: [instructionalFocusId],
    },
    {
      enabled: !!instructionalFocusId,
    },
  );

  useEffect(() => {
    if (getInstructionalUnitsRequest.isFetching) {
      setSplashImageLoaded(false);
    }
  }, [getInstructionalUnitsRequest.isFetching, setSplashImageLoaded]);

  const onSplashImageLoaded = () => {
    setSplashImageLoaded(true);
  };

  return (
    <Card>
      <Stack gap={2}>
        <Box sx={{ width: '100%', height: '300px', position: 'relative' }}>
          <Fade in key={`splash-${instructionalFocusId}`} timeout={splashImageTransitionTimeoutMs}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <AsyncImage
                key={imageUrl}
                src={imageUrl}
                alt={imageAlt}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  objectFit: 'cover',
                }}
                onImageLoaded={onSplashImageLoaded}
              />
            </Box>
          </Fade>
          {/* Don't display text until the image is loaded. It's super hard to read while superimposed on top of the Skeleton component. */}
          {splashImageLoaded && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 3,
              }}
            >
              <Grid>
                <Grid container>
                  <Grid size={{ xs: 12, md: 8 }}>
                    <HootTypography isPII={false} variant="displaylarge" sx={{ color: hootTokens.palette.white }}>
                      {label}
                    </HootTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        {getInstructionalUnitsRequest.isFetching ? (
          <Fade in key={`skeleton-${focusAreaId}`}>
            <Stack direction="row" justifyContent="center" gap={2} sx={{ opacity: 0 }}>
              {[...Array(2)].map((_, i) => (
                <InstructionalUnitTileSkeletonTile key={`skeleton-${focusAreaId}-${i}`} />
              ))}
            </Stack>
          </Fade>
        ) : (
          <GrowList
            StackProps={{
              ...(isMobile && {
                direction: 'column',
              }),
              ...(!isMobile && {
                direction: 'row',
                justifyContent: 'center',
              }),
              gap: 2,
            }}
            items={getInstructionalUnitsRequest.data?.categorizedInstructionalUnits.flatMap((x) => x.listItems) ?? []}
            ItemWrapperProps={{ width: isMobile ? '100%' : '132px' }}
            getKey={(item) => item.value}
            renderItem={(x, i) => (
              <InstructionalUnitTile
                key={x.value}
                label={x.label}
                primaryColour={tileColours[i % tileColours.length].primary}
                secondaryColour={tileColours[i % tileColours.length].secondary}
              />
            )}
          />
        )}
      </Stack>
    </Card>
  );
};

interface InstructionalUnitTileProps {
  label: string;
  primaryColour: string;
  secondaryColour: string;
}

const InstructionalUnitTile = (props: InstructionalUnitTileProps) => {
  const { label, primaryColour, secondaryColour } = props;
  return (
    <Stack
      sx={{
        width: '100%', // Each GrowList item is wrapped in a Box (div). This is where the actual width is defined for this component.
        height: '132px',
        background: hootTokens.palette.neutral['190'],
        boxShadow: hootTokens.elevation['elevation1'],
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1,
        gap: 1,
      }}
    >
      <Box
        sx={{
          borderRadius: '8px',
          width: '100%',
          height: '16px',
          backgroundColor: primaryColour,
        }}
      />
      <HootTypography isPII={false} variant="labelsmall" sx={{ textAlign: 'center', lineHeight: '1.5em', minHeight: '3em' }}>
        {/* TODO SC-13169: Add actual links here.  */}
        <Link to="#">{label}</Link>
      </HootTypography>
      <ScopeAndSequenceIcon fill={secondaryColour} stroke={primaryColour} />
    </Stack>
  );
};

const InstructionalUnitTileSkeletonTile = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: '132px',
      height: '132px',
      borderRadius: '8px',
    }}
  />
);

export default ScopeAndSequenceFocusAreaCard;
