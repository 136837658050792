import { Navigate, Route, Routes } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import Logout from '@hoot/ui/components/v2/Logout';
import AppBarLayout from '@hoot/ui/components/v2/layout/AppBarLayout';
import { ProvidersLayoutV2 } from '@hoot/ui/components/v2/layout/ProvidersLayoutV2';
import PublicApplicationLayout from '@hoot/ui/components/v2/layout/PublicApplicationLayout';
import SelectProfilePage from '@hoot/ui/pages/v2/no-profile/SelectProfilePage';
import { ParentDashboard } from '@hoot/ui/pages/v2/parent/dashboard/ParentDashboard';
import { default as ScheduleV2 } from '@hoot/ui/pages/v2/parent/schedule/Schedule';
import ParentAccountSettingsPage from '@hoot/ui/pages/v2/parent/settings/ParentAccountSettingsPage';
import PublicStudentSchedule from '@hoot/ui/pages/v2/public/public-student-schedule/PublicStudentSchedule';

const ParentRouter = () => {
  return (
    <Routes>
      {/* Main Routes */}
      <Route element={<ProvidersLayoutV2 />}>
        <Route element={<AppBarLayout />}>
          <Route path={routesDictionary.dashboard.path} element={<ParentDashboard />} />
          <Route path={routesDictionary.schedule.path} element={<ScheduleV2 />} />
          <Route path={routesDictionary.settings.path}>
            <Route path={routesDictionary.settings.account.path} element={<ParentAccountSettingsPage />} />
          </Route>
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PublicApplicationLayout />}>
        <Route path={routesDictionary.selectProfile.path} element={<SelectProfilePage />} />
        <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
        <Route path={routesDictionary.publicStudentSchedule.path}>
          <Route path={routesDictionary.publicStudentSchedule.location.path} element={<PublicStudentSchedule />} />
        </Route>
      </Route>

      {/* Index & Catch-all Routes */}
      <Route index element={<Navigate replace to={routesDictionary.dashboard.url} />} />
      <Route path={routesDictionary.logout.path} element={<Logout />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ParentRouter;
