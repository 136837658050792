import { UserProfileType } from './user';

export interface StudentProfile {
  id: string;
  userId: string;
  name: string;
  number: number;
  type: UserProfileType;
  isEnabled: boolean;
  createdAt: number;
  birthYear: number;
  interests: string;
  focusChallenges: FocusChallenges;
  schedulePreferences: string;
  pronouns: string;
  parentNotes: string;
  status: StudentStatus;
  leadInitiationDate: Date;
  conversionDate: Date;
  notConvertedReason: NotConvertedReason;
  pauseEndDate: Date;
  inactiveDate: Date;
  inactiveReason: InactiveReason;
  studentType: StudentType;
}

export type InactiveReason =
  | 'TOO_YOUNG'
  | 'PAYMENT'
  | 'PROGRAM_CONTENT'
  | 'CHILD_TOO_BUSY'
  | 'LESSON_EXPERIENCE'
  | 'NO_RESPONSE'
  | 'FUNDING_PERIOD'
  | 'PROGRAM_EXPIRATION'
  | 'GRADUATED'
  | 'LESSON_SCHEDULING'
  | 'PRIVACY'
  | 'TOO_OLD'
  | 'LOW_ATTENDANCE'
  | 'NOT_RIGHT_FIT'
  | 'MOVED_SCHOOLS'
  | 'OTHER';

export type NotConvertedReason =
  | 'TOO_OLD'
  | 'TOO_YOUNG'
  | 'PAYMENT'
  | 'PROGRAM_CONTENT'
  | 'CHILD_TOO_BUSY'
  | 'LESSON_SCHEDULING'
  | 'LESSON_EXPERIENCE'
  | 'NO_RESPONSE'
  | 'PRIVACY'
  | 'OTHER';

type StudentStatus = 'ACTIVE' | 'LEAD' | 'COLD' | 'PAUSED' | 'INACTIVE';

export enum StudentStatusEnum {
  Active = 'ACTIVE',
  Lead = 'LEAD',
  Cold = 'COLD',
  Paused = 'PAUSED',
  Lapsed = 'INACTIVE',
}

export enum FocusChallenges {
  Yes = 'YES',
  No = 'NO',
}

export enum StudentType {
  HootDecodableAccess = 'HOOT_DECODABLE_ACCESS',
  HootTutoring = 'HOOT_TUTORING',
}

export enum PasswordStatus {
  Set = 'SET',
  NotSet = 'NOT_SET',
}
