import { DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LessonReviewEngagement } from '@hoot/models/api/enums/lesson-review-enums';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import {
  LessonReviewEngagementExternalFactorsState,
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

interface LessonReviewStudentEngagementForm {
  studentEngagement: LessonReviewEngagement | undefined;
  studentEngagementExternalFactors: LessonReviewEngagementExternalFactorsState | undefined;
}

const LessonReviewStudentEngagementStep = () => {
  const {
    lessonToReview,
    studentEngagementState,
    studentEngagementExternalFactorsState,
    actions: { goToNextStep, goToPreviousStep, attachStudentEngagementData, attachStudentEngagementExternalFactorsData },
  } = useLessonReviewWizardContext();

  const { getValues, control, watch } = useForm<LessonReviewStudentEngagementForm>({
    defaultValues: {
      studentEngagement: studentEngagementState,
      studentEngagementExternalFactors: {
        classroomOrSurroundingsWereLoud: studentEngagementExternalFactorsState?.classroomOrSurroundingsWereLoud ?? false,
        peopleInSurroundingsDistracting: studentEngagementExternalFactorsState?.peopleInSurroundingsDistracting ?? false,
        audioOrVideoIssues: studentEngagementExternalFactorsState?.audioOrVideoIssues ?? false,
      },
    },
  });

  const submitAndGoToNextStep = () => {
    const { studentEngagement, studentEngagementExternalFactors } = getValues();
    if (studentEngagement && studentEngagementExternalFactors) {
      attachStudentEngagementData(studentEngagement);
      attachStudentEngagementExternalFactorsData(studentEngagementExternalFactors);
      goToNextStep();
    }
  };

  const { studentEngagement } = watch();

  return (
    <>
      <DialogContent>
        <Stack gap={'16px'}>
          <HootTypography isPII={false} variant="titlemedium">
            {' '}
            Step {LessonReviewWizardStepEnum.StudentEngagement} - Student Engagement
          </HootTypography>
          <HootTypography isPII={true} variant="bodymedium">
            How engaged was <strong>{lessonToReview?.student.name}</strong> during the reading lesson?
          </HootTypography>
          <Stack>
            <Controller
              control={control}
              name="studentEngagement"
              render={({ field }) => (
                <RadioGroup defaultValue={studentEngagementState} onChange={field.onChange} sx={{ padding: '8px' }}>
                  <Stack direction={'column'} gap={'16px'}>
                    <FormControlLabel
                      value={LessonReviewEngagement.HighlyEngaged}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={false}>
                          <strong>{lessonToReview?.student.name}</strong> was highly engaged.
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.Engaged}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={false}>
                          <strong>{lessonToReview?.student.name}</strong> was engaged.
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.FairlyEngaged}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={false}>
                          <strong>{lessonToReview?.student.name}</strong> was fairly engaged.
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.SomewhatEngaged}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={false}>
                          <strong>{lessonToReview?.student.name}</strong> was somewhat engaged.
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.NotAtAllEngaged}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={false}>
                          <strong>{lessonToReview?.student.name}</strong> was not at all engaged.
                        </HootTypography>
                      }
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </Stack>
          <HootTypography isPII={false} variant="bodymedium">
            Indicate if any of the following external factors impacted the lesson environment
          </HootTypography>
          <Stack direction={'column'} gap={'16px'}>
            <Controller
              name="studentEngagementExternalFactors.classroomOrSurroundingsWereLoud"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value || false}
                  onChange={(e) => field.onChange(e.target.checked)}
                  label="Their classroom or surroundings were loud."
                />
              )}
            />
            <Controller
              name="studentEngagementExternalFactors.peopleInSurroundingsDistracting"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value || false}
                  onChange={(e) => field.onChange(e.target.checked)}
                  label="There were people in their surroundings distracting them."
                />
              )}
            />
            <Controller
              name="studentEngagementExternalFactors.audioOrVideoIssues"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value || false}
                  onChange={(e) => field.onChange(e.target.checked)}
                  label="There was audio or video issues in the lesson."
                />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!studentEngagement}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewStudentEngagementStep;
