import { Box, Fade, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import usePageReadAuditor from '@hoot/hooks/usePageReadAuditor';
import { UserProfileType } from '@hoot/models/api/user';
import { ReaderContext, getNextPageId, getPreviousPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import useTeacherOutOfLessonReaderController from '@hoot/ui/pages/v2/teacher/library/reader/useTeacherOutOfLessonReaderController';
import TeacherReader from '@hoot/ui/pages/v2/teacher/reader/TeacherReader';

export enum TeacherStudentSpecificLibraryReaderPageQueryParams {
  PageId = 'pageId',
  DoublePage = 'doublePage',
}

const TeacherStudentSpecificLibraryReaderPage = () => {
  const { studentProfileId, bookId: bookIdPath } = useParams() as { studentProfileId: string; bookId: string };

  const { studentSpecificLibraryReader } = useReaders();
  const { book: loadedBook, pageIndex, pageZoom, isDoublePage } = studentSpecificLibraryReader;
  const loadedBookId = loadedBook?.id;

  const [searchParams, setSearchParams] = useSearchParams();
  const pageIdQuery = searchParams.get(TeacherStudentSpecificLibraryReaderPageQueryParams.PageId);
  const isDoublePageQuery = searchParams.get(TeacherStudentSpecificLibraryReaderPageQueryParams.DoublePage);

  // The loaded book in redux may not be the book that we want to open.
  // If this is the case, then show a loading indicator.
  const readerBook = bookIdPath === loadedBookId ? loadedBook : null;

  usePageReadAuditor({ book: readerBook ?? undefined, pageIndex, userProfileType: UserProfileType.Teacher });

  const controller = useTeacherOutOfLessonReaderController({
    readerContext: ReaderContext.StudentSpecificLibraryReader,
    studentProfileId,
    defaultPageId: pageIdQuery ?? undefined,
  });

  // If no book has been loaded, or if the loaded book's ID doesn't match the book ID in the current path,
  // then load the correct book.
  useEffect(
    () => {
      if (bookIdPath !== loadedBookId) {
        controller.onLoadBook(bookIdPath);
      } else if (pageIdQuery) {
        // Else if the book is already loaded, and a default page ID was specific, then flip to this page.
        controller.onGoToPageWithId(pageIdQuery);
      } else {
        // Else, flip to the first page.
        controller.onGoToFirstPage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookIdPath, loadedBookId, pageIdQuery],
  );

  // Update double-page mode based on URL query.
  useEffect(
    () => {
      const isDoublePage = isDoublePageQuery === Boolean(true).toString();
      controller.onChangeDoublePageMode(isDoublePage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDoublePageQuery],
  );

  const onGoToNextPage = () => {
    const newPageId = getNextPageId(readerBook!, isDoublePage, pageIndex);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(TeacherStudentSpecificLibraryReaderPageQueryParams.PageId, newPageId);
    setSearchParams(newSearchParams, { replace: true });
  };

  const onGoToPreviousPage = () => {
    const newPageId = getPreviousPageId(readerBook!, isDoublePage, pageIndex);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(TeacherStudentSpecificLibraryReaderPageQueryParams.PageId, newPageId);
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <>
      {!readerBook && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      {readerBook && (
        <Fade in>
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <TeacherReader
              openedBook={readerBook}
              pageIndex={pageIndex}
              pageZoom={pageZoom}
              isDoublePage={isDoublePage}
              onGoToNextPage={onGoToNextPage}
              onGoToPreviousPage={onGoToPreviousPage}
            />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default TeacherStudentSpecificLibraryReaderPage;
