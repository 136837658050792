import { Skeleton, Stack } from '@mui/material';
import { useMemo } from 'react';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { copyToClipboard } from '@hoot/utils/clipboard';
import { toProperCase } from '@hoot/utils/text-display';

const LocationDetails = () => {
  const dispatch = useAppDispatch();
  const { districtRepLocationDetails, isFetching } = useDistrictRepLocationDetails();

  const viewState = useMemo<ViewStateEnum>(() => {
    if (isFetching) {
      return ViewStateEnum.Loading;
    } else {
      return ViewStateEnum.Results;
    }
  }, [isFetching]);

  const copy = () => {
    if (districtRepLocationDetails?.studentAccessLink) {
      copyToClipboard(districtRepLocationDetails.studentAccessLink);
      dispatch(createFlashMessage({ message: 'Copied to Clipboard' }));
    }
  };

  return (
    <Card title={`${toProperCase(districtRepLocationDetails?.locationType ?? 'Location')} Details`} isLoading={isFetching}>
      <Stack gap={2}>
        <ViewState
          state={viewState}
          loadingContent={<SkeletonItems />}
          EmptyStateIllustrationProps={{
            title: 'Your Bookmark card is empty',
            subtitle: 'All saved notifications will be listed here',
          }}
        >
          <ReadOnlyTextField
            label={`${toProperCase(districtRepLocationDetails?.locationType ?? 'Location')} ID`}
            body={districtRepLocationDetails?.billableEntityId ?? ''}
            sx={{ background: hootTokens.palette.neutral[190] }}
            invertLabelStyles
          />
          <ReadOnlyTextField
            label={'Student Access Link'}
            body={districtRepLocationDetails?.studentAccessLink ?? ''}
            sx={{
              background: hootTokens.palette.neutral[190],
              '&:hover': {
                cursor: 'pointer',
              },
              alignItems: 'center',
            }}
            endAdornment={<Icon name="clipboard" />}
            onClick={copy}
            invertLabelStyles
          />
        </ViewState>
      </Stack>
    </Card>
  );
};

const SkeletonItems = () => (
  <Stack gap={2}>
    <Skeleton variant="rounded" sx={{ width: '100%', height: '60px' }} />
    <Skeleton variant="rounded" sx={{ width: '100%', height: '60px' }} />
  </Stack>
);

export default LocationDetails;
