import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDistrictRepGetSchoolSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetSchoolSnippet';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Page from '@hoot/ui/components/v2/core/Page';

const HFSSchoolReportingPage = () => {
  const { schoolId } = useParams();
  const navigate = useNavigate();

  const { data: schoolSnippet } = useDistrictRepGetSchoolSnippet(schoolId ?? '', {
    enabled: !!schoolId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this school, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.schools.url, { replace: true });
      }
    },
  });
  const pageTitle = schoolSnippet?.name ? `${schoolSnippet.name} Reporting | Hoot Reading` : 'School Reporting | Hoot Reading';

  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingTab = locationHasReporting;

  if (!showReportingTab) {
    return <Navigate to="/" />;
  }

  return <Page pageTitle={pageTitle}>{/* sc-12742 will complete this portion */}</Page>;
};

export default HFSSchoolReportingPage;
