import { Box, Card, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { NotificationCallToAction, NotificationMessage, NotificationType } from '@hoot/events/messages/notification-message';
import { NotificationTagMessage, NotificationTagType } from '@hoot/events/messages/notification-tag';
import useDistrictRepGetEnrolment from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetEnrolment';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hootTokens } from '../../theme/v2/tokens';
import { Button } from './core/Button';
import HootTypography from './core/HootTypography';
import { Icon } from './core/Icon';
import IconButton from './core/IconButton';
import Tag, { TagColor } from './core/Tag';

export function formatNotificationTimestamp(timestamp: number) {
  const dateTime = DateTime.fromMillis(timestamp);

  const dateDiffSeconds = dateTime.diffNow('seconds').negate();
  const dateDiffMinutes = dateTime.diffNow('minutes').negate();

  if (dateDiffSeconds.seconds < 60) {
    return dateDiffMinutes.toFormat("s 's ago'");
  } else if (dateDiffMinutes.minutes < 60) {
    return dateDiffMinutes.toFormat("m 'm ago'");
  } else {
    return dateTime.toFormat('cccc, LLLL dd, hh:mm a');
  }
}

export const notificationTypeLabelDictionary: Record<NotificationType, string> = {
  [NotificationType.Enrolment]: 'Enrolment',
  [NotificationType.Assessment]: 'Assessment',
  [NotificationType.LessonAdded]: 'Lesson - Added',
  [NotificationType.LessonDidNotOccur]: 'Lesson - Did Not Occur',
  [NotificationType.LessonCancelled]: 'Lesson - Cancelled',
  [NotificationType.LessonMissed]: 'Lesson - Missed',
  [NotificationType.LessonUpdated]: 'Lesson - Updated',
  [NotificationType.Student]: 'Student',
};

export const notificationTypeColorDictionary: Record<NotificationType, TagColor> = {
  [NotificationType.Enrolment]: TagColor.Success,
  [NotificationType.Assessment]: TagColor.Info,
  [NotificationType.LessonAdded]: TagColor.Warning,
  [NotificationType.LessonDidNotOccur]: TagColor.Warning,
  [NotificationType.LessonCancelled]: TagColor.Warning,
  [NotificationType.LessonMissed]: TagColor.Warning,
  [NotificationType.LessonUpdated]: TagColor.Warning,
  [NotificationType.Student]: TagColor.Info,
};

export function isDisplayableTag(_tag: NotificationTagMessage): boolean {
  // sc-12804 & sc-12366
  // We aren't currently displaying any notification tags to provide additional filtering, but may add this
  // ability back at some point (see history of this method in git)
  // NOTE: We do still use notification tags to provide additional filtering of CTA button clicks
  return false;
}

interface NotificationProps {
  notification: NotificationMessage;
  isBookmarkToggling?: boolean;
  onBookmarked?: () => void;
  onCallToActionClicked?: (route: string) => void;
}

export function Notification(props: NotificationProps) {
  const { notification, isBookmarkToggling, onBookmarked, onCallToActionClicked } = props;

  const navigate = useNavigate();

  const enrolmentIdTag = notification.tags.find((x) => {
    return x.type === NotificationTagType.EnrolmentId;
  });

  const { data: enrolment } = useDistrictRepGetEnrolment(enrolmentIdTag?.tag ?? '', {
    enabled: !!enrolmentIdTag,
  });

  const callToActionDictionary = (callToActionType: NotificationCallToAction): { label: string; route: string } | null => {
    switch (callToActionType) {
      case NotificationCallToAction.ViewSchedule:
        const studentIdTag = notification.tags.find((x) => x.type === NotificationTagType.StudentId);
        if (studentIdTag) {
          return { label: 'View Students Schedule', route: routesDictionary.students.student.schedule.url(studentIdTag.tag) };
        }
        return null;
      case NotificationCallToAction.ViewStudents:
        return { label: 'Go to Students Page', route: '/students' };
      case NotificationCallToAction.BookTestCall:
        return { label: 'Book A Test Call', route: 'https://calendly.com/helpdesk-testcall/15min' };
      case NotificationCallToAction.ConnectWithUs:
        return { label: 'Questions? Connect With Us!', route: 'mailto:help@hootreading.com' };
    }
  };

  const callToActionMapEntry = callToActionDictionary(notification.callToAction);

  const handleBookmarkClick = () => {
    if (onBookmarked) {
      onBookmarked();
    }
  };

  const _onCallToActionClicked = () => {
    const studentNameTag = notification.tags.find((x) => x.type === NotificationTagType.StudentName);
    const lessonDateTag = notification.tags.find((x) => x.type === NotificationTagType.LessonDate);

    const params = createSearchParams();
    if (studentNameTag) {
      params.set('name', studentNameTag.tag);
    }
    if (enrolment) {
      params.set('enrolmentId', enrolment.id);
    }

    if (notification.callToAction === NotificationCallToAction.ViewSchedule) {
      if (lessonDateTag && !isNaN(Number(lessonDateTag.tag))) {
        params.set('date', DateTime.fromMillis(parseInt(lessonDateTag.tag)).toISODate()!);
      } else if (!enrolment) {
        params.set('date', DateTime.fromMillis(notification.sentAt).toISODate()!);
      }
    }

    let route = `${callToActionMapEntry!.route}`;
    if (notification.callToAction !== NotificationCallToAction.ViewSchedule) {
      route = `${callToActionMapEntry!.route}?${params}`;
    }
    onCallToActionClicked?.(route);
  };

  const handleTagClick = (tag: NotificationTagMessage) => {
    const schoolIdTag = notification.tags.find((t) => t.type === NotificationTagType.SchoolId);
    // We want to ignore clicking on enrolment UUIDs that are lingering in previous notifications before the bug was fixed
    if (tag.type === NotificationTagType.EnrolmentId && isDisplayableTag(tag)) {
      navigate({
        pathname: routesDictionary.schedule.url,
        search: createSearchParams(
          enrolment
            ? {
                enrolmentId: enrolment.id,
              }
            : undefined,
        ).toString(),
      });
    } else if (tag.type === NotificationTagType.SchoolName && schoolIdTag && isDisplayableTag(tag)) {
      navigate({
        pathname: routesDictionary.schedule.url,
        search: createSearchParams({
          school: schoolIdTag.tag,
        }).toString(),
      });
    }
  };

  return (
    <Card
      sx={{
        ...hootTokens.elevation.elevation2,
        backgroundColor: hootTokens.palette.neutral[195],
        padding: '16px',
      }}
    >
      <Stack direction="row" gap={2}>
        <Stack flex={1} justifyContent="space-between">
          <Box>
            <HootTypography isPII={true} variant="bodylarge">
              {notification.title}
            </HootTypography>
            <HootTypography isPII={true} sx={{ marginTop: '8px' }} variant="bodysmall">
              {notification.content}
            </HootTypography>
            <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
              <Tag
                key={`n-${notification.id}-${notification.notificationType}`}
                label={notificationTypeLabelDictionary[notification.notificationType]}
                color={notificationTypeColorDictionary[notification.notificationType] ?? TagColor.Neutral}
              />
              {notification.tags
                .filter((tag) => isDisplayableTag(tag))
                .map((tag) => (
                  <Box key={`nt-${tag.notificationId}-${tag.type}`} sx={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleTagClick(tag)}>
                    <Tag key={`nt-${tag.notificationId}-${tag.type}`} label={tag.tag} />
                  </Box>
                ))}
            </Box>
          </Box>
          <Box sx={{ marginTop: '8px' }}>
            <HootTypography isPII={false} variant="bodysmall">
              {formatNotificationTimestamp(notification.sentAt)}
            </HootTypography>
          </Box>
        </Stack>
        <Stack justifyContent="space-between">
          <Stack direction="row" justifyContent="flex-end">
            <IconButton isLoading={isBookmarkToggling} onClick={handleBookmarkClick} color={notification.isBookmarked ? 'success.100' : undefined}>
              <Icon name={notification.isBookmarked ? 'bookmark_added' : 'bookmark_add'} />
            </IconButton>
          </Stack>
          {callToActionMapEntry && (
            <Stack sx={{ mt: 1 }} direction="row" justifyContent="flex-end">
              <Button onClick={_onCallToActionClicked} variant="contained" color="primary">
                {callToActionMapEntry.label}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
