import { debounce, throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  DefaultColorStyle,
  DefaultFontStyle,
  DefaultSizeStyle,
  Editor,
  StoreListener,
  TLDrawShape,
  TLGeoShape,
  TLRecord,
  TLShapeId,
  TLStoreWithStatus,
  Vec,
  createShapeId,
  createTLStore,
  loadSnapshot,
} from 'tldraw';
import { EventType } from '@hoot/events/eventType';
import { WhiteboardDelta } from '@hoot/events/interfaces/whiteboard-delta';
import { WhiteboardTool, WhiteboardToolColour } from '@hoot/events/interfaces/whiteboard.state';
import { GetWhiteboardSnapshotResponseMessage } from '@hoot/events/messages/get-whiteboard-snapshot-response.message';
import { GetWhiteboardStateResponseMessage } from '@hoot/events/messages/get-whiteboard-state-response.message';
import { WhiteboardChangeRequestMessage } from '@hoot/events/messages/whiteboard-change-request.message';
import { WhiteboardChangeResponseMessage } from '@hoot/events/messages/whiteboard-change-response.message';
import { WhiteboardFlushChangesRequestMessage } from '@hoot/events/messages/whiteboard-flush-changes-request.message';
import { WhiteboardGetSnapshotRequestMessage } from '@hoot/events/messages/whiteboard-get-snapshot-request.message';
import { WhiteboardGetStateRequestMessage } from '@hoot/events/messages/whiteboard-get-state-request.message';
import { WhiteboardRecenterResponseMessage } from '@hoot/events/messages/whiteboard-recenter-response.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { handleSyncWhiteboardState } from '@hoot/redux/reducers/whiteboardSlice';
import { useAppDispatch } from '@hoot/redux/store';
import {
  assembleWhiteboardDelta,
  keepDrawPointsInsideFrame,
  keepShapeInsideFrame,
  mergeIncomingChanges,
} from '@hoot/ui/components/v2/whiteboard/whiteboard-utils';

const BOUNDARY_FRAME_ID = 'boundaryFrameId';

// Publishes a batch of whiteboard events every X milliseconds.
const updateEmitterThrottleTimeMs = 50;

// Publish an event X milliseconds after the last canvas update to signal a "flush" event to the API.
const flushChangesDebounceTimeMs = 1000;

// The amount of time (in millis) it takes the camera to pan the entire whiteboard into view.
const cameraRecenterAnimationTimeMs = 500;

interface UseWhiteboardManagerProps {
  lessonId: string;
  whiteboardId: string;
  editor: Editor | undefined;
  defaultTool: WhiteboardTool;
  defaultToolColour: WhiteboardToolColour;
  readOnlyMode?: boolean;
}

/**
 * In-lesson whiteboard manager for teacher and student.
 * Whiteboard sync logic is based off of tldraw's socket example here -> https://github.com/tldraw/tldraw-sockets-example/
 */
export const useWhiteboardManager = (props: UseWhiteboardManagerProps) => {
  const { lessonId, whiteboardId, editor, defaultTool, defaultToolColour, readOnlyMode = false } = props;

  const dispatch = useAppDispatch();

  const [store] = useState(createTLStore());
  // The `store` state above will eventually be fed into this once a store snapshot response is received.
  const [storeWithStatus, setStoreWithStatus] = useState<TLStoreWithStatus>({
    status: 'loading',
  });
  const status = storeWithStatus.status;

  const getWhiteboardStateRequestEmitter = useEmit<WhiteboardGetStateRequestMessage>(EventType.WhiteboardGetStateRequest, undefined, {
    enabled: false,
  });
  const getWhiteboardSnapshotRequestEmitter = useEmit<WhiteboardGetSnapshotRequestMessage>(EventType.WhiteboardGetSnapshotRequest, undefined, {
    enabled: false,
  });

  // Emit local changes from the Tldraw canvas to the API.
  const clientUpdateEmitter = useEmit<WhiteboardChangeRequestMessage>(EventType.WhiteboardChangeRequest, undefined, { enabled: false });

  // Notifies the API that we're done editing a shape.
  const flushChangesEmitter = useEmit<WhiteboardFlushChangesRequestMessage>(EventType.WhiteboardFlushChangeRequest, undefined, { enabled: false });

  // Notifies the API that we're done editing a shape. The API will then write all deltas into its own version of the whiteboard (in Redis).
  // Note: this function is debounced; the flush event is only emitted after X millis after the last invocation.
  const flushChanges = debounce(function () {
    flushChangesEmitter.emit({ whiteboardId, lessonId });
  }, flushChangesDebounceTimeMs);

  // On page-load, request the state and latest snapshot of the whiteboard.
  useEffect(
    () => {
      getWhiteboardStateRequestEmitter.emit({
        lessonId,
        whiteboardId,
      });
      getWhiteboardSnapshotRequestEmitter.emit({
        lessonId,
        whiteboardId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lessonId, whiteboardId],
  );

  // Handle whiteboard state changes (not including snapshot). We're handling metadata changes here.
  useSocketSubscription<GetWhiteboardStateResponseMessage>(EventType.WhiteboardGetStateResponse, {
    onEventReceived: (response) => {
      if (response.whiteboardState) {
        const { currentTool, currentColour } = response.whiteboardState;
        _setWhiteboardTool(currentTool, currentColour);
      }
      dispatch(handleSyncWhiteboardState(response.whiteboardState));
    },
  });

  // When we receive the _full_ whiteboard snapshot response, load it up into the Tldraw canvas.
  useSocketSubscription<GetWhiteboardSnapshotResponseMessage>(EventType.WhiteboardGetSnapshotResponse, {
    onEventReceived: (response) => {
      const storeSnapshot = response.storeSnapshot;

      // If no snapshot was returned, then there's a problem.
      if (!storeSnapshot) {
        console.log('Whiteboard response received, but missing snapshot. Bailing out.');
        setStoreWithStatus({
          status: 'not-synced',
          store,
        });
        return;
      }
      console.log('Whiteboard snapshot received. Loading canvas...');

      // Else, we have a snapshot that we can pass that into the tldraw canvas.
      store.mergeRemoteChanges(() => {
        loadSnapshot(store, { document: storeSnapshot });

        if (response.pendingChanges.length > 0) {
          console.log('Applying pending changes not yet applied to whiteboard...');
          for (const change of response.pendingChanges) {
            mergeIncomingChanges(store, change);
          }
          console.log(`Applied ${response.pendingChanges.length} changes to whiteboard.`);
        }
      });

      // If a snapshot has been reloaded (this can happen if the whiteboard has been reset), then zoom to fit the board,
      // else we might only see a portion of the loaded template.
      editor?.zoomToFit({ force: true });

      // Set connection status.
      setStoreWithStatus({
        status: 'synced-remote',
        connectionStatus: 'online',
        store,
      });
    },
  });

  // When we're ready (everything is initialized, and we have a valid snapshot), create a handler for local changes on
  // the Tldraw canvas, and push these changes over to the API for the other participant to receive.
  useEffect(
    () => {
      if (!whiteboardId || !lessonId || !store || status !== 'synced-remote') {
        return;
      }
      // Listen for whiteboard changes, and publish them to the API.
      let batchedDeltas: WhiteboardDelta | null = null;

      // Send deltas over the wire (throttled, so we don't flood the API).
      const sendChanges = throttle(() => {
        if (!batchedDeltas) return;

        // Publish updates.
        clientUpdateEmitter.emit({
          whiteboardId,
          lessonId,
          changes: batchedDeltas,
        });
        batchedDeltas = null;

        flushChanges();
      }, updateEmitterThrottleTimeMs);

      const handleChange: StoreListener<TLRecord> = (historyEntry) => {
        const delta = assembleWhiteboardDelta(historyEntry);

        if (!delta) return;

        if (!batchedDeltas) {
          // Fresh batch of changes.
          batchedDeltas = delta;
        } else {
          // Else, we have batched changes waiting to roll out. We need to merge the newest delta with the existing ones...

          // Apply and/or replace all PUT ops to the batch.
          batchedDeltas.put = {
            ...batchedDeltas.put,
            // Just overwrite any existing keys (if any).
            ...delta.put,
          };
          // Assemble draw shape patches to the batch.
          const batchedDrawShapePatches = batchedDeltas.drawShapePatch;
          Object.entries(delta.drawShapePatch).forEach(([id, patch]) => {
            const shapeId = id as TLShapeId;
            if (!batchedDrawShapePatches[shapeId]) {
              batchedDrawShapePatches[shapeId] = patch;
            } else {
              const appendedPoints = [...(batchedDrawShapePatches[shapeId].appendPoints ?? []), ...(patch.appendPoints ?? [])];
              batchedDrawShapePatches[shapeId] = {
                ...batchedDrawShapePatches[shapeId],
                ...patch,
                appendPoints: appendedPoints.length > 0 ? appendedPoints : undefined,
              };
            }
          });
          // Assemble regular shape patches to the batch.
          batchedDeltas.shapePatch = {
            ...batchedDeltas.shapePatch,
            // Just overwrite any existing keys (if any).
            ...delta.shapePatch,
          };
          // Assemble list of shape/record IDs to remove.
          batchedDeltas.remove = [...batchedDeltas.remove, ...delta.remove];
        }
        sendChanges();
      };
      const unsubs = [
        store.listen(handleChange, {
          source: 'user',
          scope: 'document',
        }),
      ];
      // Unsubscribe events.
      return () => {
        unsubs.forEach((fn) => fn());
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [whiteboardId, lessonId, store, status],
  );

  // Listen for incoming whiteboard _changes_ (we're not getting the full-blown snapshot here), and sync them into our local store.
  // Note: All incoming lesson-specific messages are handled in `useLessonMessageHandler` _except_ in this case. We need
  // direct access to the whiteboard store when receiving whiteboard events.
  useSocketSubscription<WhiteboardChangeResponseMessage>(EventType.WhiteboardChangeResponse, {
    onEventReceived: (response) => {
      // We need to be ready to accept these changes before we can merge anything into the whiteboard.
      if (status !== 'synced-remote') {
        return;
      }
      mergeIncomingChanges(store, response.changes);
    },
  });

  // Re-center whiteboard camera handler.
  useSocketSubscription<WhiteboardRecenterResponseMessage>(EventType.WhiteboardRecenterResponse, {
    onEventReceived: () => {
      editor?.zoomToFit({ force: true, animation: { duration: cameraRecenterAnimationTimeMs } });
    },
  });

  /**
   * Select the tool and colour from the remote whiteboard state.
   * Note: If the whiteboard state request hasn't come back yet, then the currentTool and currentToolColour will just be the defaults.
   */
  const _setWhiteboardTool = useCallback(
    (tool: WhiteboardTool, toolColour: WhiteboardToolColour) => {
      if (!editor) return;

      // If we're in readonly mode, then don't bother changing the active tool. We can't edit the whiteboard.
      if (readOnlyMode) return;

      if (!Object.values(WhiteboardTool).includes(tool)) {
        console.warn(`Invalid whiteboard tool requested "${tool}". Defaulting to "${WhiteboardTool.Draw}".`);
        tool = WhiteboardTool.Draw;
      }
      if (!Object.values(WhiteboardTool).includes(tool)) {
        console.warn(`Invalid whiteboard tool colour requested "${toolColour}". Defaulting to "${WhiteboardToolColour.Black}".`);
        toolColour = WhiteboardToolColour.Black;
      }
      editor.setCurrentTool(tool);
      editor.setStyleForNextShapes(DefaultColorStyle, toolColour);

      switch (tool) {
        case WhiteboardTool.Select:
        case WhiteboardTool.Text:
          editor.setStyleForNextShapes(DefaultSizeStyle, 'xl');
          editor.setStyleForNextShapes(DefaultFontStyle, 'sans');
          break;
        case WhiteboardTool.Draw:
          editor.setStyleForNextShapes(DefaultSizeStyle, 'l');
          break;
        case WhiteboardTool.Eraser:
          // Nothing to change here.
          break;
      }
    },
    [editor, readOnlyMode],
  );

  /**
   * Enable/disable readonly mode.
   */
  const _setWriteMode = useCallback(() => {
    if (!editor) return;

    editor.updateInstanceState({ isReadonly: readOnlyMode });

    // If we're in re-only mode, then we'll override the selected tool (just locally) to be the laser pointer.
    if (readOnlyMode) {
      editor.setCurrentTool(WhiteboardTool.Select);
    } else {
      // Else if we can edit the whiteboard, then make sure we're using the right tool.
      _setWhiteboardTool(defaultTool, defaultToolColour);
    }
  }, [readOnlyMode, editor, _setWhiteboardTool, defaultTool, defaultToolColour]);

  // Handle readonly mode toggled.
  useEffect(() => {
    _setWriteMode();
  }, [_setWriteMode]);

  return {
    store: storeWithStatus,
    onEditorReady: (editor: Editor) => {
      // When the editor is ready, reset the camera position so that we can see the entire whiteboard template.
      // If the template hasn't been loaded yet, then this does nothing.
      editor.zoomToFit({ force: true });

      _setWhiteboardTool(defaultTool, defaultToolColour);
      _setWriteMode();

      // Register a handler so that we don't delete any shapes that are part of the template.
      editor.sideEffects.registerBeforeDeleteHandler('shape', (shape) => {
        if (!(shape.meta?.isDeletable ?? true)) {
          return false;
        }
        return;
      });

      // Register a handler to ensure that new records added to the canvas are always within bounds (if a boundary rect exists)
      editor.sideEffects.registerBeforeCreateHandler('shape', (record, source) => {
        if (source !== 'user') return record;

        const boundaryFrameId = createShapeId(BOUNDARY_FRAME_ID);
        const boundaryFrame = editor.getShape<TLGeoShape>(boundaryFrameId);

        // If there is no boundary shape, then bail. We don't need to do anything.
        if (!boundaryFrame) return record;

        // Get the coordinates of the new record.
        const point = Vec.From(record);

        const boundaryAbsMinX = boundaryFrame.x;
        const boundaryAbsMaxX = boundaryFrame.x + boundaryFrame.props.w;
        const boundaryAbsMinY = boundaryFrame.y;
        const boundaryAbsMaxY = boundaryFrame.y + boundaryFrame.props.h;

        // The x-y coordinates of the new record must be within bounds.
        const newX = point.x < boundaryAbsMinX ? boundaryFrame.x : point.x > boundaryAbsMaxX ? boundaryAbsMaxX : point.x;
        const newY = point.y < boundaryAbsMinY ? boundaryAbsMinY : point.y > boundaryAbsMaxY ? boundaryAbsMaxY : point.y;

        return {
          ...record,
          x: newX,
          y: newY,
        };
      });

      // Register a handler to ensure that updated records are always within bounds (if a boundary rect exists)
      editor.sideEffects.registerBeforeChangeHandler('shape', (prev, next, source) => {
        if (source !== 'user') return next;

        // Programmatically disallowing shape rotation. Would be more optimal to just hide the "rotate" function
        // from the UI, but couldn't find how to do that.
        if (next.rotation !== prev.rotation) {
          return prev;
        }

        const boundaryFrameId = createShapeId(BOUNDARY_FRAME_ID);
        const boundaryFrame = editor.getShape<TLGeoShape>(boundaryFrameId);

        // If there is no boundary shape, then bail. We don't need to do anything.
        if (!boundaryFrame) return next;

        if (next.type === 'draw') {
          const drawShape = next as TLDrawShape;
          const prevDrawShape = prev as TLDrawShape;

          const drawSegments = drawShape.props.segments;

          // If we don't have _exactly_ one segment (there is only one segment when drawing freehand), then bail else the following logic won't work.
          if ((drawSegments?.length ?? 0) !== 1) return next;

          const prevPointCount = prevDrawShape.props.segments.flatMap((s) => s.points).length;
          const currPointCount = drawSegments.flatMap((s) => s.points).length;
          const pointCountDelta = currPointCount - prevPointCount;

          switch (pointCountDelta) {
            /**
             * If there are _no_ new points than there was previously, then we're either translating or resizing the shape (we're not drawing).
             */
            case 0: {
              return keepShapeInsideFrame(next, boundaryFrame, editor);
            }
            /**
             * If we are drawing freehand, then there will be exactly one more point than there was previously.
             * Ensure that the newest point stays within bounds.
             */
            case 1: {
              return keepDrawPointsInsideFrame(drawShape, boundaryFrame);
            }
            default: {
              return next;
            }
          }
        } else {
          return keepShapeInsideFrame(next, boundaryFrame, editor);
        }
      });
    },
  };
};
