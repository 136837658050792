import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Fade, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { DATE_WITH_TIME } from '@hoot/constants/constants';
import { LessonReviewResponse } from '@hoot/hooks/api/lesson-reviews/useGetLessonReview';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

// sc-11671 We want to use different labels for lesson types than the standard labels in scheduledLessonTypeLookup
export const getLessonReviewLessonTypeLabel = (lessonType: ScheduledLessonType): string => {
  switch (lessonType) {
    case ScheduledLessonType.Assessment:
      return 'HRA';
    case ScheduledLessonType.ProgressMonitoring:
      return 'PMA';
    case ScheduledLessonType.Interview:
      return 'Interview';
    default:
      return 'Instruction';
  }
};

interface StudentDetailsCardProps {
  lessonReview: LessonReviewResponse | undefined;
  isLoading: boolean;
}

const LessonReviewDetailsCard = (props: StudentDetailsCardProps) => {
  const { lessonReview, isLoading } = props;

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (lessonReview) {
      return ViewStateEnum.Results;
    } else {
      // If not loading, and we don't have a student, then this is an invalid state.
      return ViewStateEnum.Error;
    }
  };

  const emptyLabel = '---';
  const date = lessonReview?.startsAt ? DateTime.fromMillis(lessonReview.startsAt).toFormat(DATE_WITH_TIME) : emptyLabel;

  return (
    <Card title="Lesson Details" isLoading={isLoading}>
      <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
        <Fade in>
          <Grid container spacing={2}>
            <Grid size={12}>
              <ReadOnlyTextField label="Lesson Number" body={lessonReview?.number.toString() ?? emptyLabel} />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField label="Lesson Date" body={date} />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField label="Lesson Type" body={lessonReview ? getLessonReviewLessonTypeLabel(lessonReview.lessonType) : emptyLabel} />
            </Grid>
          </Grid>
        </Fade>
      </ViewState>
    </Card>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default LessonReviewDetailsCard;
