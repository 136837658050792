import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

async function getLessonFormativeAssessmentBookIds(lessonId: string): Promise<string[]> {
  const { data } = await axios.get<string[]>(`${config.apiUrl}/library/lesson/${lessonId}/lesson-formative-assessment-library`);
  return data;
}

export default function useGetLessonFormativeAssessmentBookIds(lessonId: string, options?: Omit<UseQueryOptions<string[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetLessonFormativeAssessmentBookIds, lessonId], () => getLessonFormativeAssessmentBookIds(lessonId), options);
}
