import { Navigate } from 'react-router-dom';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';

const Reporting = () => {
  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingPage = locationHasReporting;

  if (!showReportingPage) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <SubHeader title={{ label: 'Reporting Dashboard', isPII: false }} />
      <Page pageTitle={'Reporting Dashboard | Hoot Reading'}>{/* sc-12358 will complete this portion */}</Page>
    </>
  );
};

export default Reporting;
