import { StudentGrade, studentGradeLookup } from '@hoot-reading/hoot-core/dist/enums/student-grade';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSetStudentGrade } from '@hoot/hooks/api/assessment/useSetStudentGrade';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { error } from '@hoot/redux/reducers/alertSlice';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

export interface StudentProfileGradeConfirmationProp {
  id: string;
  name: string;
  grade: StudentGrade | null;
}

interface StudentGradeConfirmationDialogProps {
  show: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  studentName: string;
  studentId: string;
  studentGrade: StudentGrade | null;
}

const StudentGradeConfirmationDialog = (props: StudentGradeConfirmationDialogProps) => {
  const { show, onConfirm, onDismiss, studentName, studentId, studentGrade } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const setGradeRequest = useSetStudentGrade();
  const [newStudentGrade, setNewStudentGrade] = useState<StudentGrade | null>(studentGrade);

  // Reset dropdown state when modal is launched.
  useEffect(
    () => {
      if (show) {
        setNewStudentGrade(studentGrade);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  const onConfirmGrade = () => {
    if (newStudentGrade) {
      setGradeRequest.mutate(
        { studentProfileId: studentId, grade: newStudentGrade },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.GetStudentProfileSnippet);
            onConfirm();
          },
          onError: (ex) => {
            console.error(ex);
            dispatch(error(`An error occurred while attempting to set the student's grade.`));
          },
        },
      );
    }
  };

  return (
    <BasicAlertDialog
      show={show}
      onDismiss={onDismiss}
      title={`Confirm ${studentName}'s Grade`}
      content={
        <Stack gap={2}>
          <HootTypography
            isPII={true}
          >{`To ensure the accuracy and relevance of the assessment, please confirm ${studentName}’s current grade.`}</HootTypography>
          <Select
            label="Grade"
            helperText="Note: Grade will be applied to the student’s profile and cannot be edited after submitting."
            name="studentGrade"
            value={newStudentGrade}
            onChange={(event) => setNewStudentGrade(event.target.value as StudentGrade)}
            required
            fullWidth
          >
            <option value="">Select</option>
            {Object.values(StudentGrade).map((option) => (
              <option value={option} key={`grade-opt-${option}`}>
                {studentGradeLookup[option]}
              </option>
            ))}
          </Select>
        </Stack>
      }
      primaryAction={{
        label: 'Submit',
        onClick: onConfirmGrade,
        props: {
          disabled: !newStudentGrade,
          color: 'success.60',
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: onDismiss,
      }}
    />
  );
};

export default StudentGradeConfirmationDialog;
