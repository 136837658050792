import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export enum EdlinkIntegrationProvider {
  Canvas = 'CANVAS',
  Clever = 'CLEVER',
  Google = 'GOOGLE',
  ClassLink = 'CLASSLINK',
}

export interface GetLocationResponse {
  name: string;
  integrationId: string | null;
  integrationProvider: EdlinkIntegrationProvider | null;
}

async function getLocation(friendlyId: string) {
  const { data } = await axios.get<GetLocationResponse>(`${config.apiUrl}/public/hfs/location/${friendlyId}`);
  return data;
}

export function useGetLocation(friendlyId: string, options?: Omit<UseQueryOptions<GetLocationResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetLocation], () => getLocation(friendlyId), options);
}
