import { CircularProgress, Stack, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import useGetPublicStudentSchedule from '@hoot/hooks/api/schedule/useGetPublicStudentSchedule';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import HootLogo from '@hoot/ui/components/v2/icons/HootLogo';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import TextField from '../../../../components/v2/core/TextField';

enum ContentState {
  StudentNumberPrompt,
  Schedule,
}

interface Form {
  studentNumber: string;
}

const PublicStudentSchedule = () => {
  usePageTitle('Student Schedule | Hoot Reading');

  const [studentNumber, setStudentNumber] = useState<string>();
  const [contentState, setContentState] = useState<ContentState>(ContentState.StudentNumberPrompt);

  const theme = useTheme();

  const handleOnStudentNumberSet = (studentNumber: string) => {
    setStudentNumber(studentNumber.trim());
    setContentState(ContentState.Schedule);
  };

  const handleGoBack = () => {
    setStudentNumber('');
    setContentState(ContentState.StudentNumberPrompt);
  };

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          padding: '24px',
          backgroundImage: 'url("/images/flf-background.webp")',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            maxWidth: '800px',
            padding: '24px',
            borderRadius: '8px',
            background: hootTokens.palette.white,
            boxShadow: theme.shadows[3],
            alignItems: 'center',
          }}
        >
          <Link to={routesDictionary.home.url}>
            <HootLogo sx={{ width: 134, height: 48, margin: theme.spacing(4, 'auto', 3) }} />
          </Link>

          {studentNumber ? (
            <HootTypography variant="titlelarge" textAlign="center" isPII={true}>
              Student {studentNumber}'s Schedule
            </HootTypography>
          ) : (
            <HootTypography variant="titlelarge" textAlign="center" mb={2} isPII={true}>
              Student Schedule
            </HootTypography>
          )}

          {contentState === ContentState.StudentNumberPrompt && <StudentNumberPrompt onStudentNumberSet={handleOnStudentNumberSet} />}

          {contentState === ContentState.Schedule && studentNumber && <Schedule studentNumber={studentNumber} onGoBack={handleGoBack} />}
        </Stack>
      </Stack>
    </>
  );
};

function StudentNumberPrompt(props: { onStudentNumberSet: (studentNumber: string) => void }) {
  const { control, handleSubmit } = useForm<Form>();

  const onSubmitted: SubmitHandler<Form> = (data) => {
    props.onStudentNumberSet(data.studentNumber);
  };

  return (
    <>
      <HootTypography variant="bodymedium" textAlign="center" mb={2} isPII={true}>
        Enter your student number below:
      </HootTypography>
      <form onSubmit={handleSubmit(onSubmitted)}>
        <Stack gap="32px">
          <Controller
            name="studentNumber"
            control={control}
            render={({ field: { onChange }, fieldState }) => (
              <TextField
                data-hoot-canary-id="studentNumber"
                label={'Student Number'}
                error={!!fieldState.error}
                onChange={onChange}
                inputProps={{ maxLength: 10 }}
                fullWidth
                required
                autoFocus
              />
            )}
          />
          <Button color="primary" type="submit" variant="contained" fullWidth>
            Get Schedule
          </Button>
        </Stack>
      </form>
    </>
  );
}

function Schedule(props: { studentNumber: string; onGoBack: () => void }) {
  const { locationId } = useParams() as { locationId: string };

  const userTimeZone = moment.tz.guess();

  const { data, isFetching, error } = useGetPublicStudentSchedule(locationId, props.studentNumber, userTimeZone, {
    retry: false,
  });

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          borderRadius: '8px',
          padding: '16px',
        }}
      >
        {isFetching ? (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '100px' }}>
            <CircularProgress />
          </Stack>
        ) : error ? (
          <HootTypography variant="bodymedium" color="error" textAlign="center" mb={2} isPII={false}>
            Error loading schedule. Please try again.
          </HootTypography>
        ) : (
          <Grid container spacing={0}>
            {daysOfWeek.map((day) => (
              <React.Fragment key={day}>
                <Grid
                  size={{ xs: 6 }}
                  sx={{
                    textAlign: 'left',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <HootTypography variant="titlesmall" isPII={false}>
                    {day}'s:
                  </HootTypography>
                </Grid>
                <Grid
                  size={{ xs: 6 }}
                  sx={{
                    textAlign: 'left',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <HootTypography variant="bodymedium" isPII={false}>
                    {data
                      ? data.lessonDays[day.toLowerCase()]?.length > 0
                        ? data.lessonDays[day.toLowerCase()].join(', ')
                        : 'No Lessons'
                      : 'Error Loading Schedule'}
                  </HootTypography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Stack>
      <Button color="primary" variant="contained" onClick={props.onGoBack} sx={{ marginTop: 2 }}>
        Back
      </Button>
      <HootTypography variant="bodysmall" isPII={false} sx={{ marginTop: 4, textAlign: 'center' }}>
        Disclaimer: The times listed above reflect your child's regular Hoot Reading schedule. Please note that changes may occur. If you have any
        questions about your child's schedule, feel free to reach out to <Link to="mailto:help@hootreading.com">help@hootreading.com</Link> or contact
        your school administrator.
      </HootTypography>
    </Stack>
  );
}

export default PublicStudentSchedule;
