import { Card } from '@mui/material';
import { Box } from '@mui/system';
import { Editor, TLStoreWithStatus, Tldraw } from 'tldraw';
import 'tldraw/tldraw.css';
import { WhiteboardTool } from '@hoot/events/interfaces/whiteboard.state';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Icon, IconName } from '../core/Icon';
import IconButton from '../core/IconButton';

interface TeacherInLessonWhiteboardProps {
  store: TLStoreWithStatus;
  onEditorReady: (editor: Editor) => void;
  currentTool?: WhiteboardTool;
}

const whiteboardToolToIconDictionary: Record<WhiteboardTool, IconName> = {
  [WhiteboardTool.Draw]: 'pen',
  [WhiteboardTool.Select]: 'drag',
  [WhiteboardTool.Eraser]: 'eraser',
  [WhiteboardTool.Text]: 'type_letters',
};

/**
 * Tldraw whiteboard at its base (UI controls are stripped out), and wrapped in a card.
 */
const Whiteboard = (props: TeacherInLessonWhiteboardProps) => {
  const { store, onEditorReady, currentTool } = props;

  return (
    <Card
      sx={{
        height: '100%',
        position: 'relative',
        padding: 0,
        ...hootTokens.elevation.get(1),
      }}
    >
      <Box
        className="tldraw__editor"
        sx={{
          height: '100%',
          '& .tl-background': {
            backgroundColor: hootTokens.palette.white,
          },
        }}
      >
        <Tldraw
          hideUi
          autoFocus
          store={store}
          onMount={(e) => onEditorReady(e)}
          cameraOptions={{
            wheelBehavior: 'none',
            isLocked: true,
          }}
          components={{
            // This remove the TLDraw context menu when right-clicking on the editor.
            ContextMenu: null,
          }}
        />
      </Box>
      {currentTool ? (
        <IconButton variant={'contained'} disabled sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Icon name={whiteboardToolToIconDictionary[currentTool]} />
        </IconButton>
      ) : null}
    </Card>
  );
};

export default Whiteboard;
