import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetStudentGrade } from '@hoot/hooks/api/assessment/useGetStudentGrade';
import useSearchStudentTeacherPMAs, { PublicSearchStudentTeacherPMAsRequest } from '@hoot/hooks/api/pma/useSearchStudentTeacherPMAs';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { ProgressMonitoringAssessmentStatus } from '@hoot/models/api/enums/pma-enums';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { RootState } from '@hoot/redux/store';
import useTeacherHRAController from '@hoot/ui/pages/v2/teacher/lesson/hoot-reading-assessment/useTeacherHRAController';
import StudentGradeConfirmationDialog from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/StudentGradeConfirmationDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { StudentAssessmentsQuerySortKeyEnum, useGetAssessmentsV2 } from '../../../../../hooks/api/assessment/useGetStudentAssessmentsV2';
import { HootAssessmentStatus } from '../../../../../models/api/enums/hoot-reading-assessment';
import { OrderBy } from '../../../../../models/api/enums/queryEnums';
import HootTypography from '../../core/HootTypography';
import { Icon } from '../../core/Icon';
import HRANotificationDialog from '../../dialogs/HRANotificationDialog';
import PMANotificationDialog from '../../dialogs/PMANotificationDialog';

const LessonNotification = () => {
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const { activePage } = useActiveLessonState();

  const isLessonPMAType = inLesson?.type === ScheduledLessonType.ProgressMonitoring;
  const isLessonHRAType = inLesson?.type === ScheduledLessonType.Assessment;

  if (activePage !== ActiveLessonPage.Library) {
    return null;
  }

  return (
    <>
      {isLessonPMAType ? <PMANotification /> : null}
      {isLessonHRAType ? <HRANotification /> : null}
    </>
  );
};

function PMANotification() {
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const { activePage } = useActiveLessonState();

  // === PMA Notification Logic Start ===
  const [isPMANotificationModalOpen, setIsPMANotificationModalOpen] = useState<boolean>(false);

  // PMA
  const searchStudentTeacherPMAsQuery: PublicSearchStudentTeacherPMAsRequest = {
    studentProfileId: inLesson?.studentId ?? '',
    teacherId: inLesson?.teacherId ?? '',
  };

  const { data: studentTeacherPMAs } = useSearchStudentTeacherPMAs(searchStudentTeacherPMAsQuery, {
    enabled: !!inLesson,
  });

  const inProgressPMA = studentTeacherPMAs?.progressMonitoringAssessments.find((pma) => pma.status === ProgressMonitoringAssessmentStatus.InProgress);
  const completedTodayPMA = studentTeacherPMAs?.progressMonitoringAssessments.find(
    (pma) => pma.status === ProgressMonitoringAssessmentStatus.Completed && DateTime.now().toISODate() === pma.statusUpdateDate,
  );

  const shouldNotifyForPMA = !!inLesson && (!!inProgressPMA || !completedTodayPMA);

  const notificationCount = shouldNotifyForPMA ? 1 : 0;

  // === PMA Notification Logic End ===

  if (notificationCount === 0 || activePage === ActiveLessonPage.PMA) {
    return null;
  }

  const onViewClick = () => {
    setIsPMANotificationModalOpen(true);
  };

  return (
    <>
      <Stack
        gap={1}
        marginY="24px"
        onClick={onViewClick}
        sx={{
          width: '100%',
          backgroundColor: hootTokens.palette.warning[140],
          padding: '8px',
          cursor: 'pointer',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'center'}
          gap={1}
          sx={{
            animation: '1750ms infinite pulse',
            '@keyframes pulse': {
              '0%': { transform: 'scale(.9)' },
              '50%': { transform: 'scale(1.2)' },
              '100%': { transform: 'scale(.9)' },
            },
          }}
        >
          <Icon name="bell_notification" />
          <HootTypography isPII={true} sx={{ textDecoration: 'underline' }} variant="titlesmall" textAlign="center">
            {inLesson?.studentName} Requires an Assessment - Time to step in!
          </HootTypography>
        </Stack>
      </Stack>

      <PMANotificationDialog isOpen={isPMANotificationModalOpen} setIsOpen={setIsPMANotificationModalOpen} isResumingAssessment={!!inProgressPMA} />
    </>
  );
}

function HRANotification() {
  const assessmentId = useSelector((state: RootState) => state.activeLesson.assessmentId);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const [isGradeConfirmationDialogOpen, setIsGradeConfirmationDialogOpen] = useState<boolean>(false);

  // === HRA Notification Logic Start ===
  const [isHRANotificationModalOpen, setIsHRANotificationModalOpen] = useState<boolean>(false);

  // HRA
  const assessments = useGetAssessmentsV2(inLesson?.studentId!, {
    page: 1,
    pageSize: 5, // We only really need one though. Tacking on a few extra "just in case".
    orderBy: StudentAssessmentsQuerySortKeyEnum.StartDate,
    sortDirection: OrderBy.Desc,
  });

  // Check if student has grade set
  const { data, refetch: refetchGrade } = useGetStudentGrade(inLesson?.studentId ?? '', { enabled: !!inLesson?.studentId });

  const studentGrade = data?.grade ?? null;

  const hraForThisLesson = assessments.data?.data.find((a) => a.id === assessmentId);

  const hraController = useTeacherHRAController();

  // === HRA Notification Logic End ===

  const onDismissHraNotificationDialog = () => setIsHRANotificationModalOpen(false);

  const onViewClick = () => {
    if (studentGrade) {
      setIsHRANotificationModalOpen(true);
    } else {
      setIsGradeConfirmationDialogOpen(true);
    }
  };

  const onConfirmGrade = () => {
    refetchGrade();
    setIsGradeConfirmationDialogOpen(false);
    setIsHRANotificationModalOpen(true);
  };

  if (hraForThisLesson?.status === HootAssessmentStatus.Completed) {
    return null;
  }

  return (
    <>
      <Stack
        gap={1}
        marginY="24px"
        onClick={onViewClick}
        sx={{
          width: '100%',
          backgroundColor: hootTokens.palette.warning[140],
          padding: '8px',
          cursor: 'pointer',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'center'}
          gap={1}
          sx={{
            animation: '1750ms infinite pulse',
            '@keyframes pulse': {
              '0%': { transform: 'scale(.9)' },
              '50%': { transform: 'scale(1.2)' },
              '100%': { transform: 'scale(.9)' },
            },
          }}
        >
          <Icon name="bell_notification" />
          <HootTypography isPII={true} sx={{ textDecoration: 'underline' }} variant="titlesmall" textAlign="center">
            {inLesson?.studentName} Requires an Assessment - Time to step in!
          </HootTypography>
        </Stack>
      </Stack>
      <HRANotificationDialog
        isOpen={isHRANotificationModalOpen}
        isResumingAssessment={!!hraForThisLesson}
        onOpenHra={hraController.onNavigateToHRA}
        onDismiss={onDismissHraNotificationDialog}
        key={`hra-notification-dialog-${isHRANotificationModalOpen}`}
      />
      <StudentGradeConfirmationDialog
        show={isGradeConfirmationDialogOpen}
        onConfirm={onConfirmGrade}
        onDismiss={() => setIsGradeConfirmationDialogOpen(false)}
        studentName={inLesson!.studentName}
        studentId={inLesson!.studentId}
        studentGrade={studentGrade}
      />
    </>
  );
}

export default LessonNotification;
