import { Checkbox, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import { ChangeEvent, useMemo, useState } from 'react';
import useGetFormativeAssessmentBookDetails, { FormativeAssessmentBook } from '@hoot/hooks/api/lesson-reviews/useGetFormativeAssessmentBookDetails';
import useGetLessonFormativeAssessmentBookIds from '@hoot/hooks/api/library/useGetLessonFormativeAssessmentBookIds';
import { FormativeAssessmentType, formativeAssessmentLetters } from '@hoot/models/api/enums/lesson-review-enums';
import CheckList, { BaseCheckListItem, CheckListItem, CheckListProps } from '@hoot/ui/components/v2/CheckList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card, { CardProps } from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import TextField from '@hoot/ui/components/v2/core/TextField';
import TimePicker from '@hoot/ui/components/v2/core/TimePicker';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import {
  LessonReviewWizardStepEnum,
  PerformanceMetrics,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { BookPickerModalProps } from '../book-picker/BookPickerModal';
import FormativeAssessmentBookPicker from '../book-picker/FormativeAssessmentBookPicker';

const MAX_SELECTED_FORMATIVE_ASSESSMENT_BOOKS = 3;

const LessonReviewFormativeAssessmentStep = () => {
  const {
    lessonToReview,
    formativeAssessmentState,
    actions: { goToNextStep, goToPreviousStep, attachFormativeAssessmentData },
  } = useLessonReviewWizardContext();

  const [useFormativeAssessment, setUseFormativeAssessment] = useState(!!formativeAssessmentState?.formativeAssessmentBooks.length);
  const [enableAddBookButton, setEnableAddBookButton] = useState(false);
  const [showNoLessonHistoryBooks, setShowNoLessonHistoryBooks] = useState(false);
  const [showAddBookModal, setShowAddBookModal] = useState(false);
  const [selectedFormativeAssessmentBooks, setSelectedFormativeAssessmentBooks] = useState<FormativeAssessmentBook[]>(
    formativeAssessmentState?.formativeAssessmentBooks ?? [],
  );
  const [wordsReadCorrectlyDictionary, setWordsReadCorrectlyDictionary] = useState<Map<string, Set<string>>>(
    formativeAssessmentState?.wordsReadCorrectly ?? new Map(),
  );

  const [performanceMetricsDictionary, setPerformanceMetricsDictionary] = useState<Map<string, PerformanceMetrics>>(
    formativeAssessmentState?.performanceMetrics ?? new Map(),
  );

  const [lettersReadCorrectlyDictionary, setLettersReadCorrectlyDictionary] = useState<Map<string, Set<string>>>(
    formativeAssessmentState?.lettersReadCorrectly ?? new Map(),
  );

  const [bookToRemove, setBookToRemove] = useState<FormativeAssessmentBook>();
  const [selectedBookId, setSelectedBookId] = useState<string>('');

  const { data: lessonHistoryBookIds } = useGetLessonFormativeAssessmentBookIds(lessonToReview?.lessonId!, {
    retry: false,
    enabled: !!lessonToReview?.lessonId,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (data: string[]) => {
      if (data.length === 0) {
        setShowNoLessonHistoryBooks(true);
      } else {
        setEnableAddBookButton(true);
      }
    },
  });

  const { isLoading } = useGetFormativeAssessmentBookDetails(selectedBookId, {
    retry: false,
    enabled: !!selectedBookId,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (data) => {
      setSelectedFormativeAssessmentBooks((selectedFormativeAssessmentBooks) => [...selectedFormativeAssessmentBooks, data.formativeAssessmentBook]);
      onDismissAddBookModal();
    },
  });

  // You can go to the next step if:
  // - You've selected "no" (no formative assessment books used), or
  // - You've selected "yes" (used word formative assessment books) and at least one book as been selected. If a selectedb
  const textReadingBooks = selectedFormativeAssessmentBooks.filter((book) => book.formativeAssessmentType === FormativeAssessmentType.TextReading);
  const wordReadingBooks = selectedFormativeAssessmentBooks.filter((book) => book.formativeAssessmentType === FormativeAssessmentType.WordReading);
  const lettersAndSoundsReadingBooks = selectedFormativeAssessmentBooks.filter(
    (book) => book.formativeAssessmentType === FormativeAssessmentType.LettersAndSounds,
  );

  const allValuesDefined =
    performanceMetricsDictionary.size === textReadingBooks.length &&
    Array.from(performanceMetricsDictionary.values()).every((value) => Object.values(value).every((val) => val !== undefined));

  const canGoToNextStep =
    !useFormativeAssessment ||
    ((wordReadingBooks.length > 0 || lettersAndSoundsReadingBooks.length > 0) && !textReadingBooks.length) ||
    (!!textReadingBooks.length && allValuesDefined);

  const onUseFormativeAssessmentChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'no') {
      setUseFormativeAssessment(false);
      setSelectedBookId('');
      setWordsReadCorrectlyDictionary(new Map());
      setLettersReadCorrectlyDictionary(new Map());
      setSelectedFormativeAssessmentBooks([]);
    } else {
      setUseFormativeAssessment(true);
    }
  };

  const onShowAddBookModal = () => {
    setShowAddBookModal(true);
  };

  const onWordsReadCorrectlyChanged = (bookId: string, wordsReadCorrectly: Set<string>) => {
    const updatedWordsReadCorrectlyDictionary = new Map(wordsReadCorrectlyDictionary);
    updatedWordsReadCorrectlyDictionary.set(bookId, wordsReadCorrectly);
    setWordsReadCorrectlyDictionary(updatedWordsReadCorrectlyDictionary);
  };

  const onPerformanceMetricsChanged = (bookId: string, performanceMetrics: PerformanceMetrics) => {
    const updatedPerformanceMetrics = new Map(performanceMetricsDictionary);
    updatedPerformanceMetrics.set(bookId, performanceMetrics);
    setPerformanceMetricsDictionary(updatedPerformanceMetrics);
  };

  const onLettersReadCorrectlyChanged = (bookId: string, lettersReadCorrectly: Set<string>) => {
    const updatedLettersReadCorrectlyDictionary = new Map(lettersReadCorrectlyDictionary);
    updatedLettersReadCorrectlyDictionary.set(bookId, lettersReadCorrectly);
    setLettersReadCorrectlyDictionary(updatedLettersReadCorrectlyDictionary);
  };

  const onRemoveFormativeAssessmentBook = (removedBookId: string) => {
    // Dismiss the confirmation modal.
    setBookToRemove(undefined);

    setSelectedFormativeAssessmentBooks((selectedFormativeAssessmentBooks) => [
      ...selectedFormativeAssessmentBooks.filter((book) => book.bookId !== removedBookId),
    ]);
    const updatedWordsReadCorrectlyDictionary = new Map(wordsReadCorrectlyDictionary);

    updatedWordsReadCorrectlyDictionary.delete(removedBookId);
    setWordsReadCorrectlyDictionary(updatedWordsReadCorrectlyDictionary);

    const updatedPerformanceMetricsDictionary = new Map(performanceMetricsDictionary);
    updatedPerformanceMetricsDictionary.delete(removedBookId);
    setPerformanceMetricsDictionary(updatedPerformanceMetricsDictionary);
  };

  const onAddBook: BookPickerModalProps['onApply'] = (book) => {
    setSelectedBookId(book.id);
  };

  const onDismissAddBookModal = () => {
    setShowAddBookModal(false);
  };

  const submitAndGoToNextStep = () => {
    attachFormativeAssessmentData(
      selectedFormativeAssessmentBooks,
      wordsReadCorrectlyDictionary,
      performanceMetricsDictionary,
      lettersReadCorrectlyDictionary,
    );
    goToNextStep();
  };

  return (
    <>
      {showAddBookModal ? (
        <FormativeAssessmentBookPicker
          show={showAddBookModal}
          onApply={onAddBook}
          onDismiss={onDismissAddBookModal}
          isLoading={isLoading}
          existingBookIds={selectedFormativeAssessmentBooks.map((book) => book.bookId)}
          lessonHistoryBookIds={lessonHistoryBookIds}
          isFormativeAssessment
        />
      ) : null}
      <BasicAlertDialog
        show={!!bookToRemove}
        onDismiss={() => setBookToRemove(undefined)}
        title="Remove Book?"
        content={
          <HootTypography isPII={false} variant="bodylarge">
            Are you sure you want to remove <strong>{bookToRemove?.title}</strong>?
          </HootTypography>
        }
        primaryAction={{
          label: 'Remove',
          onClick: () => onRemoveFormativeAssessmentBook(bookToRemove!.bookId),
          props: {
            variant: 'contained',
            color: 'error',
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setBookToRemove(undefined),
        }}
      />
      <DialogContent>
        <Stack gap={2} paddingBottom={1}>
          <HootTypography isPII={false} variant="titlemedium">
            Step {LessonReviewWizardStepEnum.FormativeAssessment} - Formative Assessment Resources
          </HootTypography>
          <Stack gap={1}>
            <HootTypography isPII={false} variant="bodymedium">
              Was a Formative Assessment (Quick Checks or Text Reading FA) completed in the lesson?
            </HootTypography>
            <RadioGroup value={useFormativeAssessment ? 'yes' : 'no'} onChange={onUseFormativeAssessmentChanged} sx={{ px: 1 }}>
              <Stack direction="row" gap={2}>
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              </Stack>
            </RadioGroup>
          </Stack>
          <Stack gap={2} flex={1} minHeight="327px">
            {showNoLessonHistoryBooks ? (
              <HootTypography isPII={false} variant="bodymedium">
                No Formative Assessment books were used during the lesson.
              </HootTypography>
            ) : null}
            {enableAddBookButton && useFormativeAssessment ? (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <HootTypography isPII={false} variant="titlemedium">
                    Select the Formative Assessment book(s) you used during this lesson.
                  </HootTypography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={onShowAddBookModal}
                    disabled={selectedFormativeAssessmentBooks.length >= MAX_SELECTED_FORMATIVE_ASSESSMENT_BOOKS}
                    color="success.60"
                  >
                    + Add Book
                  </Button>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 4,
                  }}
                >
                  {/* Render the selected book cards here. */}
                  {selectedFormativeAssessmentBooks.map((book) => (
                    <Stack
                      key={`book-${book.bookId}`}
                      justifyContent="space-between"
                      gap={2}
                      sx={{
                        flex: 1,
                        width: '100%',
                      }}
                    >
                      {book.formativeAssessmentType === FormativeAssessmentType.TextReading ? (
                        <TextReadingFormativeAssessmentBookCard
                          sx={{ flex: 1, background: hootTokens.surface[2] }}
                          formativeAssessmentBook={book}
                          setBookToRemove={setBookToRemove}
                          performanceMetrics={
                            performanceMetricsDictionary.get(book.bookId) ??
                            ({ wordCount: book.wordCount, errors: undefined, runningTime: undefined } as PerformanceMetrics)
                          }
                          onPerformanceMetricsChanged={onPerformanceMetricsChanged}
                        />
                      ) : null}
                      {book.formativeAssessmentType === FormativeAssessmentType.WordReading ? (
                        <WordReadingFormativeAssessmentBookCard
                          sx={{ flex: 1, background: hootTokens.surface[2] }}
                          formativeAssessmentBook={book}
                          wordsReadCorrectly={wordsReadCorrectlyDictionary.get(book.bookId) ?? new Set()}
                          onWordsReadCorrectlyChanged={onWordsReadCorrectlyChanged}
                          setBookToRemove={setBookToRemove}
                        />
                      ) : null}
                      {book.formativeAssessmentType === FormativeAssessmentType.LettersAndSounds ? (
                        <LettersAndSoundsFormativeAssessmentBookCard
                          sx={{ flex: 1, background: hootTokens.surface[2] }}
                          formativeAssessmentBook={book}
                          lettersReadCorrectly={lettersReadCorrectlyDictionary.get(book.bookId) ?? new Set()}
                          onLettersReadCorrectlyChanged={onLettersReadCorrectlyChanged}
                          setBookToRemove={setBookToRemove}
                        />
                      ) : null}
                    </Stack>
                  ))}
                </Stack>
              </>
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!canGoToNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

interface WordReadingFormativeAssessmentBookCardProps {
  formativeAssessmentBook: FormativeAssessmentBook;
  wordsReadCorrectly: Set<string>;
  onWordsReadCorrectlyChanged: (bookId: string, wordsReadCorrectly: Set<string>) => void;
  setBookToRemove: React.Dispatch<React.SetStateAction<FormativeAssessmentBook | undefined>>;
  sx?: CardProps['sx'];
}

const WordReadingFormativeAssessmentBookCard = (props: WordReadingFormativeAssessmentBookCardProps) => {
  const { formativeAssessmentBook, wordsReadCorrectly, onWordsReadCorrectlyChanged, setBookToRemove, sx = {} } = props;
  const { title, coverImageUrl, formativeAssessmentWords, bookId } = formativeAssessmentBook;

  const formativeAssessmentWordCheckList = useMemo<BaseCheckListItem[]>(() => {
    return formativeAssessmentWords.map<BaseCheckListItem>((word) => {
      return {
        label: word,
        value: word,
      };
    });
  }, [formativeAssessmentWords]);

  const toggleWordReadCorrectly: CheckListProps['toggleOption'] = (checkListItem) => {
    const updatedWordsReadCorrectly = new Set(wordsReadCorrectly);
    if (updatedWordsReadCorrectly.has(checkListItem.value)) {
      updatedWordsReadCorrectly.delete(checkListItem.value);
    } else {
      updatedWordsReadCorrectly.add(checkListItem.value);
    }
    onWordsReadCorrectlyChanged(formativeAssessmentBook.bookId, updatedWordsReadCorrectly);
  };

  return (
    <Card sx={{ ...sx }}>
      <Stack direction="row" gap={3} alignItems="center">
        <Stack direction="column" gap={1}>
          <img
            alt={title}
            src={coverImageUrl}
            style={{
              width: '100%',
              maxWidth: '164px',
              objectFit: 'contain',
              border: '1px solid black',
              borderRadius: '8px',
            }}
          />
        </Stack>
        <Stack width="100%" gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
            <HootTypography isPII={false} variant="titlemedium">
              {title}
            </HootTypography>
            <IconButton key={`delete-book-${bookId}`} color="error.80" onClick={() => setBookToRemove(formativeAssessmentBook)}>
              <Icon name="trash_bin" />
            </IconButton>
          </Stack>
          <Card sx={{ width: '100%' }}>
            <CheckList
              title={'Read Correctly*'}
              items={[...formativeAssessmentWordCheckList]}
              selectedValues={Array.from(wordsReadCorrectly)}
              toggleOption={toggleWordReadCorrectly}
              listSx={{ display: 'flex', flexDirection: 'row', minWidth: '100%', justifyContent: 'space-evenly' }}
            />
          </Card>
        </Stack>
      </Stack>
    </Card>
  );
};

interface TextReadingFormativeAssessmentBookCardProps {
  formativeAssessmentBook: FormativeAssessmentBook;
  setBookToRemove: React.Dispatch<React.SetStateAction<FormativeAssessmentBook | undefined>>;
  performanceMetrics: PerformanceMetrics;
  onPerformanceMetricsChanged: (bookId: string, performanceMetrics: PerformanceMetrics) => void;
  sx?: CardProps['sx'];
}

const TextReadingFormativeAssessmentBookCard = (props: TextReadingFormativeAssessmentBookCardProps) => {
  const { formativeAssessmentBook, setBookToRemove, performanceMetrics, onPerformanceMetricsChanged, sx = {} } = props;
  const { title, coverImageUrl, bookId, wordCount } = formativeAssessmentBook;

  const [runningTimeError, setRunningTimeError] = useState<boolean>(false);

  const secondsToDate = (timeInSeconds: number): DateTime | null => {
    if (!timeInSeconds) return null;
    return DateTime.fromSeconds(timeInSeconds);
  };

  const handleChange = (newValue: DateTime | null) => {
    const minutes = newValue?.minute || 0;
    const seconds = newValue?.second || 0;
    const timeInSeconds = minutes * 60 + seconds;
    handleRunningTimeChange(timeInSeconds);
  };

  const handleWordCountChange = (wordCount: number) => {
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, wordCount: wordCount });
  };

  const handleErrorChange = (newValue: string) => {
    const value = parseInt(newValue);
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, errors: isNaN(value) ? undefined : value });
  };

  const handleRunningTimeChange = (runningTime: number) => {
    setRunningTimeError(runningTime === 0);
    onPerformanceMetricsChanged(bookId, { ...performanceMetrics, runningTime: runningTime === 0 ? undefined : runningTime });
  };

  return (
    <Card sx={{ ...sx, maxWidth: '100%' }}>
      <Stack direction="row" gap={3} alignItems="center">
        <Stack direction="column" gap={1}>
          <img
            alt={title}
            src={coverImageUrl}
            style={{
              width: '100%',
              maxWidth: '164px',
              objectFit: 'contain',
              border: '1px solid black',
              borderRadius: '8px',
            }}
          />
        </Stack>
        <Stack width="100%" gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
            <HootTypography isPII={false} variant="titlemedium">
              {title}
            </HootTypography>
            <IconButton key={`delete-book-${bookId}`} color="error.80" onClick={() => setBookToRemove(formativeAssessmentBook)}>
              <Icon name="trash_bin" />
            </IconButton>
          </Stack>
          <Card>
            <Stack gap={2}>
              <HootTypography isPII={false} variant="titlesmall">
                Performance Metrics
              </HootTypography>
              <Stack direction="row" gap={2} width="100%">
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <TextField
                      type="number"
                      label={'Word Count'}
                      required
                      value={performanceMetrics.wordCount ?? wordCount}
                      onChange={(val) => handleWordCountChange(Number(val.target.value))}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <TextField
                      label={'Errors'}
                      required
                      type="number"
                      value={performanceMetrics.errors ?? ''}
                      onChange={(val) => handleErrorChange(val.target.value)}
                      InputProps={{
                        inputProps: { min: 0, max: wordCount },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <TimePicker
                      label="Running Time"
                      onChange={handleChange}
                      value={secondsToDate(performanceMetrics.runningTime ?? 0)}
                      ampm={false}
                      views={['minutes', 'seconds']}
                      format="mm:ss"
                      helperText="Enter in format MM:SS"
                      error={runningTimeError}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Card>
  );
};

interface LettersAndSoundsFormativeAssessmentBookCardProps {
  formativeAssessmentBook: FormativeAssessmentBook;
  lettersReadCorrectly: Set<string>;
  onLettersReadCorrectlyChanged: (bookId: string, wordsReadCorrectly: Set<string>) => void;
  setBookToRemove: React.Dispatch<React.SetStateAction<FormativeAssessmentBook | undefined>>;
  sx?: CardProps['sx'];
}

const LettersAndSoundsFormativeAssessmentBookCard = (props: LettersAndSoundsFormativeAssessmentBookCardProps) => {
  const { formativeAssessmentBook, lettersReadCorrectly, onLettersReadCorrectlyChanged, setBookToRemove, sx = {} } = props;
  const { title, coverImageUrl, bookId } = formativeAssessmentBook;

  const formativeAssessmentWordCheckList = useMemo<BaseCheckListItem[]>(() => {
    return formativeAssessmentLetters.map<BaseCheckListItem>((letter) => {
      return {
        label: letter,
        value: letter,
      };
    });
  }, [formativeAssessmentLetters]);

  const toggleLetterReadCorrectly: CheckListProps['toggleOption'] = (checkListItem) => {
    const updatedWordsReadCorrectly = new Set(lettersReadCorrectly);
    if (updatedWordsReadCorrectly.has(checkListItem.value)) {
      updatedWordsReadCorrectly.delete(checkListItem.value);
    } else {
      updatedWordsReadCorrectly.add(checkListItem.value);
    }
    onLettersReadCorrectlyChanged(formativeAssessmentBook.bookId, updatedWordsReadCorrectly);
  };

  const displayItems: CheckListItem[] = formativeAssessmentWordCheckList.map((item) => ({
    label: item.label,
    value: item.value,
    isChecked: Array.from(lettersReadCorrectly).includes(item.value),
    isDisabled: false,
  }));

  return (
    <Card sx={{ ...sx }}>
      <Stack direction="row" gap={3} alignItems="center" width="100%">
        <Stack direction="column" gap={1}>
          <img
            alt={title}
            src={coverImageUrl}
            style={{
              width: '100%',
              maxWidth: '164px',
              objectFit: 'contain',
              border: '1px solid black',
              borderRadius: '8px',
            }}
          />
        </Stack>
        <Stack width="100%" gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
            <HootTypography isPII={false} variant="titlemedium">
              {title}
            </HootTypography>
            <IconButton key={`delete-book-${bookId}`} color="error.80" onClick={() => setBookToRemove(formativeAssessmentBook)}>
              <Icon name="trash_bin" />
            </IconButton>
          </Stack>
          <Card sx={{ width: '100%' }}>
            <Grid container rowSpacing={2}>
              {displayItems.map((letter) => (
                <Grid size={{ xs: 4, md: 2, sm: 3 }} onClick={() => toggleLetterReadCorrectly(letter)}>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                        background: hootTokens.surface[2],
                      },
                    }}
                  >
                    <Checkbox checked={letter.isChecked} />
                    <HootTypography isPII={false} variant="labellarge">
                      {letter.label}
                    </HootTypography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Stack>
      </Stack>
    </Card>
  );
};

export default LessonReviewFormativeAssessmentStep;
