const ClassLink = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 209.17 156.82">
        <defs>
          <style>.cls-1&#123;fill:#4e95cc;&#125;</style>
        </defs>
        <g>
          <path
            className="cls-1"
            d="M114,107.84c-6.37,4.33-13.82,18.75-13.82,18.75l-3.85,0a82.14,82.14,0,0,0,5.06-13.81l-1.53.49a73.67,73.67,0,0,1-22.36,4.06C84.77,128.12,98,130.07,98,130.07c18.63,2.5,32.77-7.68,40.35-15.08C122.7,106.47,114,107.84,114,107.84Z"
          />
          <path className="cls-1" d="M61,94.92c-.09.55-.15.89-.15.89-8.13,9.28,0,13.69,0,13.69a7.07,7.07,0,0,0,.92.39A38.13,38.13,0,0,1,61,94.92Z" />
          <path
            className="cls-1"
            d="M174.35,67.77s12.26-30.52-15.83-51.44c0,0-45-29.59-70.15,19.83,0,0-18-18-40.19-4-19,11.92-15.83,28-14.12,36.1,0,0-35.83,10.51-34,48.65,1.71,35.32,43.68,39.93,43.68,39.93l123.3-.58s42.76-3.61,42.12-41S174.35,67.77,174.35,67.77ZM97.12,136.2c-23.81-.27-32.26-18.13-32.26-18.13-.48-.95-.91-1.9-1.3-2.84-6.53-2.43-10.4-7-9.5-13.86s8.68-13.69,8.68-13.69c-.19.55-.39,1.21-.57,1.91,4.17-15,15.29-25.49,15.29-25.49S86,55,95.31,50.31l16.21,2.52C74.78,67.77,72.89,98.72,72.89,98.72a33.34,33.34,0,0,0,1.45,12c2.73-.2,4.72-.49,4.72-.49a94.45,94.45,0,0,0,23.52-6.74c-.22-6.08-3-7.61-3-7.61s-3.34-3.8-17-6l1.31-1.82a39.75,39.75,0,0,0,14.44,0c8.34-1.6,16-7.31,24.44-19l4.11-.18c-8.32,11-8.89,19.36-7.5,24.85a61.51,61.51,0,0,0,12.21-11.58c14.94-20.9-5.82-17.73-5.82-17.73L132,57.12c31.74,6.88,2.61,35.56,2.61,35.56a70.12,70.12,0,0,1-10.86,9c.46.48,6,6.19,16.6,11.31a49.43,49.43,0,0,0,4.26-5.08l4.17.75C124,138.44,97.12,136.2,97.12,136.2ZM108.4,76.65a6.69,6.69,0,1,1-6.83-7A6.71,6.71,0,0,1,108.4,76.65Z"
          />
          <path d="M136.37,2l.15.08,2.11-2L138.35,0C137.75.64,137.09,1.31,136.37,2Z" />
          <path d="M62.17,3A43,43,0,0,0,61,8.33C61.26,7.12,61.67,4.91,62.17,3Z" />
        </g>
      </svg>
      <svg width="1" height="24" viewBox="0 0 1 24" fill="none">
        <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke="#4e95cc" />
      </svg>
    </>
  );
};

export default ClassLink;
