import { Stack, Tabs, TabsProps } from '@mui/material';
import { Outlet, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDistrictRepGetSchoolSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetSchoolSnippet';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export enum HFSSchoolPageTabs {
  Students = 'Students',
  Enrolments = 'Enrolments',
  Reporting = 'Reporting',
}

const tabToRouteDictionary: Record<HFSSchoolPageTabs, (schoolId: string) => string> = {
  [HFSSchoolPageTabs.Students]: (schoolId: string) => routesDictionary.schools.school.students.url(schoolId),
  [HFSSchoolPageTabs.Enrolments]: (schoolId: string) => routesDictionary.schools.school.enrolments.url(schoolId),
  [HFSSchoolPageTabs.Reporting]: (schoolId: string) => routesDictionary.schools.school.reporting.url(schoolId),
};

const HFSSchoolsHeader = () => {
  const { schoolId } = useParams();
  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingTab = locationHasReporting;

  const { data: schoolSnippet, isLoading } = useDistrictRepGetSchoolSnippet(schoolId ?? '', {
    enabled: !!schoolId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this student, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.myStudents.url, { replace: true });
      }
    },
  });

  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const pageTitle = schoolSnippet?.name ? `${schoolSnippet.name} | Hoot Reading` : 'School Details | Hoot Reading';
  const headerTitle = schoolSnippet?.name ?? '';

  const isActiveTabPath = (tab: HFSSchoolPageTabs) => {
    return !!matchPath(tabToRouteDictionary[tab](schoolId!), pathname);
  };

  const getActiveTab = (): HFSSchoolPageTabs => {
    if (isActiveTabPath(HFSSchoolPageTabs.Students)) {
      return HFSSchoolPageTabs.Students;
    } else if (isActiveTabPath(HFSSchoolPageTabs.Enrolments)) {
      return HFSSchoolPageTabs.Enrolments;
    } else if (isActiveTabPath(HFSSchoolPageTabs.Reporting)) {
      return HFSSchoolPageTabs.Reporting;
    } else {
      return HFSSchoolPageTabs.Students;
    }
  };

  const handleTabChange: TabsProps['onChange'] = (_, newTab: HFSSchoolPageTabs) => {
    navigate(tabToRouteDictionary[newTab]?.(schoolId!) ?? routesDictionary.students.student.details.url(schoolId!), {
      state: { previousPath: state?.previousPath },
    });
  };

  return (
    <Page pageTitle={pageTitle} noPadding noMaxWidth>
      <Stack sx={{ background: hootTokens.palette.white }}>
        <SubHeader
          title={{
            label: headerTitle,
            isPII: false,
            isLoading,
          }}
          backButton={{
            show: true,
            navigateBackPath: state?.previousPath ? state.previousPath : undefined,
          }}
          breadcrumbs={['Schools', 'School Details']}
        />

        <PageLayout RootBoxProps={{ py: 0 }}>
          <Tabs value={getActiveTab()} onChange={handleTabChange} variant="fullWidth" sx={{ px: 3 }}>
            <Tab label="Students" value={HFSSchoolPageTabs.Students} isSelected={getActiveTab() === HFSSchoolPageTabs.Students} />
            <Tab label="Enrolments" value={HFSSchoolPageTabs.Enrolments} isSelected={getActiveTab() === HFSSchoolPageTabs.Enrolments} />
            {showReportingTab ? (
              <Tab label="Reporting" value={HFSSchoolPageTabs.Reporting} isSelected={getActiveTab() === HFSSchoolPageTabs.Reporting} />
            ) : null}
          </Tabs>
        </PageLayout>
      </Stack>
      <Outlet />
    </Page>
  );
};

export default HFSSchoolsHeader;
