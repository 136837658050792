import { Fade, LinearProgress, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { zIndexes } from '@hoot/constants/zIndices';
import usePutBookmark from '@hoot/hooks/api/student-library/usePutBookmark';
import usePageReadAuditor from '@hoot/hooks/usePageReadAuditor';
import { UserProfileType } from '@hoot/models/api/user';
import { getCorrectedPageId, getNextPageId, getPreviousPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import Page from '@hoot/ui/components/v2/core/Page';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import CountdownToLesson from '@hoot/ui/components/v2/library/CountdownToLesson';
import { useStudentJoinLessonCtx } from '@hoot/ui/context/StudentJoinLessonContext';
import StudentReaderWithControls from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentReaderWithControls';
import StudentLibraryReaderLeftPane from '@hoot/ui/pages/v2/student/library/reader/StudentLibraryReaderLeftPane';
import useStudentSoloReaderController from '@hoot/ui/pages/v2/student/library/reader/useStudentSoloReaderController';

export enum StudentLibraryReaderPageQueryParams {
  PageId = 'pageId',
  DoublePage = 'doublePage',
}

const StudentLibraryReaderPage = () => {
  const { bookId: bookIdPath } = useParams() as { bookId: string };

  const { showCountdownButton } = useStudentJoinLessonCtx();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageIdQuery = searchParams.get(StudentLibraryReaderPageQueryParams.PageId);
  const isDoublePageQuery = searchParams.get(StudentLibraryReaderPageQueryParams.DoublePage);

  const { studentSpecificLibraryReader } = useReaders();
  const { book: loadedBook, pageIndex, isDoublePage, pageZoom } = studentSpecificLibraryReader;
  const loadedBookId = loadedBook?.id;

  // The loaded book in redux may not be the book that we want to open.
  // If this is the case, then show a loading indicator.
  const readerBook = bookIdPath === loadedBookId ? loadedBook : null;

  const readerController = useStudentSoloReaderController({ bookId: bookIdPath });

  usePageReadAuditor({ book: readerBook ?? undefined, pageIndex, userProfileType: UserProfileType.Student });

  const putBookmarkRequest = usePutBookmark(readerBook?.id ?? '', {});

  useEffect(
    () => {
      // If the reader book hasn't been loaded, or if the loaded book's ID doesn't match the book ID in the current path,
      // then load the book.
      if (bookIdPath !== loadedBookId) {
        readerController.loadBook();
      } else if (pageIdQuery) {
        // Else if the book is already loaded, and a default page ID was specific, then flip to this page.
        readerController.onGoToPageWithId(pageIdQuery);
      } else {
        // Else, flip to the first page.
        readerController.onGoToFirstPage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookIdPath, loadedBookId, pageIdQuery],
  );

  // Every time we flip a page, we tell the API to bookmark the current page.
  useEffect(() => {
    if (!readerBook) {
      return;
    }
    const newPageId = readerBook!.pages[pageIndex]?.id;
    if (newPageId) {
      // Bookmark the current page.
      putBookmarkRequest.mutate({ pageId: newPageId });
    }
  }, [pageIndex, readerBook]);

  // Update double-page mode based on URL query.
  useEffect(
    () => {
      const isDoublePage = isDoublePageQuery === Boolean(true).toString();
      readerController.onChangeDoublePageMode(isDoublePage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDoublePageQuery],
  );

  const onGoToFirstPage = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(StudentLibraryReaderPageQueryParams.PageId);
    setSearchParams(newSearchParams);
  };

  const onGoToNextPage = () => {
    const newPageId = getNextPageId(readerBook!, isDoublePage, pageIndex);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(StudentLibraryReaderPageQueryParams.PageId, newPageId);
    setSearchParams(newSearchParams, { replace: true });
  };

  const onGoToPreviousPage = () => {
    const newPageId = getPreviousPageId(readerBook!, isDoublePage, pageIndex);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(StudentLibraryReaderPageQueryParams.PageId, newPageId);
    setSearchParams(newSearchParams, { replace: true });
  };

  const _onToggleDoublePage = () => {
    if (!readerBook) {
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);

    const updatedIsDoublePage = !isDoublePage;
    newSearchParams.set(StudentLibraryReaderPageQueryParams.DoublePage, Boolean(updatedIsDoublePage).toString());

    // If we're going into double-page mode AND the book is "standard double page", then we may need to update the
    // current page index so that we keep the correct pair of pages visible.
    if (updatedIsDoublePage) {
      const updatedPageId = getCorrectedPageId(readerBook, updatedIsDoublePage, pageIndex);
      newSearchParams.set(StudentLibraryReaderPageQueryParams.PageId, updatedPageId);
    }
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <Page
      pageTitle="Library | Hoot Reading"
      noPadding
      noMaxWidth
      RootBoxProps={{ sx: { height: '100%', overflow: 'hidden' } }}
      sx={{ height: '100%', display: 'flex', flexDirection: 'row' }}
    >
      <StudentLibraryReaderLeftPane
        toggleDoublePage={_onToggleDoublePage}
        toggleFullscreen={readerController.toggleFullscreen}
        toggleFavouriteBook={readerController.toggleFavouriteBook}
        onGoToFirstPage={onGoToFirstPage}
        closeBook={readerController.closeBook}
      />
      <PageLayout
        RootBoxProps={{
          sx: (theme) => ({
            position: 'relative',
            flex: 1,
            height: '100%',
            p: 2,
            [theme.breakpoints.down('xl')]: {
              p: 1,
            },
          }),
        }}
        sx={{
          height: '100%',
        }}
      >
        {!readerBook && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
        {readerBook && (
          <Fade in>
            <Box
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <StudentReaderWithControls
                book={readerBook}
                pageIndex={pageIndex}
                pageZoom={pageZoom}
                isDoublePage={isDoublePage}
                previousPage={onGoToPreviousPage}
                nextPage={onGoToNextPage}
              />
            </Box>
          </Fade>
        )}
      </PageLayout>

      {showCountdownButton && (
        <Box
          sx={{
            position: showCountdownButton ? 'absolute' : 'unset',
            top: showCountdownButton ? 40 : 'unset',
            right: showCountdownButton ? 40 : 'unset',
            animation: showCountdownButton ? '1.25s countdownAnimation' : 'unset',

            '@keyframes countdownAnimation': {
              '0%': { opacity: 0, transform: 'scale(.4)', marginBottom: theme.spacing(1) },
              '50%': { opacity: 0.5, transform: 'scale(1.3)' },
              '100%': { display: 'block', opacity: 1, transform: 'scale(1)', marginBottom: theme.spacing(5) },
            },
          }}
          zIndex={zIndexes.studentJoinLessonReminder}
        >
          <CountdownToLesson />
        </Box>
      )}
    </Page>
  );
};

export default StudentLibraryReaderPage;
