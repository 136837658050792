import { featureFlags } from '@hoot/constants/featureFlags';
import { externalRoutes } from '@hoot/routes/externalRoutes';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { NavList } from '@hoot/ui/components/v2/core/NavMenuList';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import { LIVE_CHAT_TITLE, zendeskOpenLiveChat } from '@hoot/utils/zendesk-methods';
import NewTabIcon from '../components/v2/icons/NewTabIcon';

interface TeacherNavOptions {
  isInactiveTeacher: boolean;
  showCookieConsentModal: () => void;
  showAudioVideoSettingsModal: () => void;
}

const useTeacherNavItems = (options: TeacherNavOptions): NavList[] => {
  const { isInactiveTeacher, showCookieConsentModal, showAudioVideoSettingsModal } = options;

  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);

  const navItems: NavList[] = [
    { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
    {
      label: routesDictionary.myStudents.label,
      url: routesDictionary.myStudents.url,
      disabled: isInactiveTeacher,
    },
    {
      label: routesDictionary.schedule.label,
      subList: [
        { label: 'Calendar', url: routesDictionary.schedule.url, disabled: isInactiveTeacher },
        {
          label: routesDictionary.opportunities.label,
          url: routesDictionary.opportunities.url,
          disabled: isInactiveTeacher,
        },
      ],
    },
    { label: routesDictionary.invoicing.label, url: routesDictionary.invoicing.url },
    {
      label: 'Learning & Support',
      subList: [
        {
          label: 'Library Sandbox',
          url: routesDictionary.library.url,
          disabled: isInactiveTeacher,
        },
        ...(isScopeAndSequenceEnabled
          ? [
              {
                label: 'Scope and Sequence',
                url: routesDictionary.scopeAndSequence.url,
              },
            ]
          : []),
        { label: 'Teacher Help Center', url: externalRoutes.zendeskHelpCenter.url, external: true },
        { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
      ],
    },
    {
      label: 'Settings',
      subList: [
        { label: routesDictionary.profile.label, url: routesDictionary.profile.url },
        {
          label: routesDictionary.settings.audioVideo.label,
          url: '',
          onClick: showAudioVideoSettingsModal,
          hideOnMobile: true,
        },
        { label: 'Manage Cookies', url: '', onClick: showCookieConsentModal },
        {
          label: externalRoutes.dailyNetworkTest.label,
          url: externalRoutes.dailyNetworkTest.url,
          external: true,
          icon: <NewTabIcon />,
          hideOnMobile: true,
        },
      ],
    },
  ];
  // A little hacky, but we need to remove any undefined items here.
  return navItems.filter((x) => !!x) as NavList[];
};

export default useTeacherNavItems;
