import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { TeacherShiftStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import { Stack, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useGetTeacherShifts } from '@hoot/hooks/api/availability/useGetTeacherShifts';
import { useGetTeacherAvailabilityExceptions } from '@hoot/hooks/api/availability/useTeacherAvailabilityException';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { CalendarV3 } from '@hoot/ui/components/v2/schedule/CalendarV3';
import { CreateTimeOffRequestDialog } from '@hoot/ui/components/v2/schedule/CreateTimeOffRequestDialog';
import {
  AvailabilityExceptionSlot,
  AvailabilityTimeSlot,
  LessonTimeSlot,
  ShiftTimeSlot,
  WeeklySchedule,
  shiftColor,
} from '@hoot/ui/components/v2/schedule/WeeklySchedule';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { chunkTimeSpan } from '@hoot/utils/date';
import { isBetween } from '@hoot/utils/isBetween';
import useTeacherAvailabilityQuery from '../../../../../hooks/api/availability/useTeacherAvailability';
import useGetTeacherLessons from '../../../../../hooks/api/lesson/useGetTeacherLessons';
import useTeacherMonthlyScheduledLessonsQuery from '../../../../../hooks/api/lesson/useTeacherMonthlyScheduledLessonsQuery';
import SubHeader from '../../../../components/v2/SubHeader';
import Badge from '../../../../components/v2/core/Badge';
import Card from '../../../../components/v2/core/Card';
import Page from '../../../../components/v2/core/Page';
import { TeacherScheduleDrawer } from './TeacherScheduleDrawer';
import { TimeOffAlert } from './TimeOffAlert';

enum TeacherScheduleTab {
  WeeklyView = 0,
  MonthlyView = 1,
}

export function TeacherScheduleV2() {
  const [tab, setTab] = useState<TeacherScheduleTab>(TeacherScheduleTab.WeeklyView);
  const [showCancelledEvents, setShowCancelledEvents] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getUser } = useAuth();
  const user = getUser();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showNewEventDialog, setShowNewEventDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateTime>();

  useEffect(() => {
    if (isMobile) {
      setTab(TeacherScheduleTab.WeeklyView);
    }
  }, [isMobile]);

  const handleTabChange = (_event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleScheduleDrawerClose = () => {
    setShowDrawer(false);
  };

  const handleDateClick = (date: DateTime) => () => {
    setSelectedDate(date);
    setShowDrawer(true);
  };

  const handleShowCancelledEventsClick = () => {
    setShowCancelledEvents((currentState) => !currentState);
  };

  const handleNewEventClick = () => {
    setShowNewEventDialog(true);
  };

  const handleCreateTimeOffRequestDialogClose = () => {
    setShowNewEventDialog(false);
  };

  const handleEditMyAvailability = () => {
    window.open(
      `https://forms.zohopublic.com/hootreading1/form/AvailabilityChangeRequestForm1/formperma/RPQ4PO6GC8D0afWM0w86f-7BICSe4kfYncAcsjkYOxo?Name_First=${user.firstName}&Name_Last=${user.lastName}&Email=${user.email}`,
      '_blank',
    );
  };

  return (
    <Page data-sentry-mask pageTitle="Schedule" noPadding noMaxWidth>
      <SubHeader
        title={{
          label: 'Schedule',
          isPII: false,
        }}
        secondaryAction={
          isMobile
            ? undefined
            : {
                label: 'Edit My Availability',
                onClick: handleEditMyAvailability,
                props: {
                  startIcon: <Icon name="new_tab" />,
                },
              }
        }
      />

      {/* If it's mobile, we will hide tabs */}
      {isMobile ? null : (
        <Tabs value={tab} onChange={handleTabChange} variant="fullWidth" sx={{ backgroundColor: hootTokens.palette.white }}>
          <Tab label="Week view" value={TeacherScheduleTab.WeeklyView} isSelected={tab === TeacherScheduleTab.WeeklyView} />
          <Tab label="Month view" value={TeacherScheduleTab.MonthlyView} isSelected={tab === TeacherScheduleTab.MonthlyView} />
        </Tabs>
      )}

      <TimeOffAlert />

      <Stack>
        {tab === TeacherScheduleTab.WeeklyView ? (
          <WeeklyView
            onDateClick={handleDateClick}
            onNewEventClick={isMobile ? undefined : handleNewEventClick}
            showCancelledEvents={showCancelledEvents}
            onShowCancelledEventsClick={handleShowCancelledEventsClick}
          />
        ) : null}
        {tab === TeacherScheduleTab.MonthlyView ? (
          <MonthlyView
            onDateClick={handleDateClick}
            showCancelledEvents={showCancelledEvents}
            onShowCancelledEventsClick={handleShowCancelledEventsClick}
            onNewEventClick={handleNewEventClick}
          />
        ) : null}
      </Stack>
      {showDrawer ? (
        <TeacherScheduleDrawer
          onShowCancelledEventsClick={handleShowCancelledEventsClick}
          showCancelledEvents={showCancelledEvents}
          open={showDrawer}
          onClose={handleScheduleDrawerClose}
          selectedDate={selectedDate}
        />
      ) : null}
      <CreateTimeOffRequestDialog
        key={`${showNewEventDialog}-create-time-off`}
        open={showNewEventDialog}
        onClose={handleCreateTimeOffRequestDialogClose}
      />
    </Page>
  );
}

function WeeklyView(props: {
  onNewEventClick?: () => void;
  onDateClick: (date: DateTime) => () => void;
  showCancelledEvents: boolean;
  onShowCancelledEventsClick: () => void;
}) {
  const [startOfWeek, setStartOfWeek] = useState<DateTime>(DateTime.now().startOf('week'));

  const startDate = startOfWeek.toMillis();
  const endDate = startOfWeek.endOf('week').toMillis();

  const availabilityRequest = useTeacherAvailabilityQuery();

  const availabilityExceptionsRequest = useGetTeacherAvailabilityExceptions();

  const lessonsRequest = useGetTeacherLessons({
    fromDate: startDate,
    toDate: endDate,
  });

  const getTeacherShiftsRequest = useGetTeacherShifts({
    startsAt: startDate,
    endsAt: endDate,
  });

  const handleSelectedItem = (dayOfWeek: DateTime) => {
    props.onDateClick(dayOfWeek)();
  };

  const handleStartOfWeekChange = (updatedVal: DateTime) => {
    setStartOfWeek(updatedVal);
  };

  const lessons =
    lessonsRequest.data?.lessons.map<LessonTimeSlot>((l) => ({
      id: l.id,
      prefixedStudentNumber: l.prefixedStudentNumber,
      prefixedLessonNumber: l.prefixedLessonNumber,
      startAt: DateTime.fromMillis(l.startsAt),
      endsAt: DateTime.fromMillis(l.startsAt).plus({ minute: l.durationInMinutes }),
      status: l.status,
    })) ?? [];

  const shifts =
    getTeacherShiftsRequest.data?.shifts?.map<ShiftTimeSlot>((s) => ({
      id: s.id,
      startAt: DateTime.fromMillis(s.startsAt),
      endsAt: DateTime.fromMillis(s.endsAt),
      status: s.status,
    })) ?? [];

  const availability =
    availabilityRequest.data?.timeblocks?.map<AvailabilityTimeSlot>((a) => ({
      dayOfWeek: a.dayOfWeek,
      startAt: a.startTime,
      endAt: a.endTime,
      zone: availabilityRequest.data.timezone,
    })) ?? [];

  const availabilityExceptions =
    availabilityExceptionsRequest?.data
      ?.flatMap((ae) => chunkTimeSpan(DateTime.fromMillis(ae.startsAt), DateTime.fromMillis(ae.endsAt)))
      ?.map<AvailabilityExceptionSlot>((ae) => ({
        startsAt: ae.startsAt,
        endsAt: ae.endsAt,
      })) ?? [];

  const isLoading =
    availabilityRequest.isFetching || availabilityExceptionsRequest.isFetching || lessonsRequest.isLoading || getTeacherShiftsRequest.isLoading;

  return (
    <Stack padding="24px">
      <Card isLoading={false}>
        <WeeklySchedule
          isLoading={isLoading}
          startOfWeek={startOfWeek}
          onStartOfWeekChange={handleStartOfWeekChange}
          availability={availability}
          availabilityExceptions={availabilityExceptions}
          lessons={lessons}
          shifts={shifts}
          onSelected={handleSelectedItem}
          onNewEventClick={props.onNewEventClick}
          showCancelledEvents={props.showCancelledEvents}
          onShowCancelledEventsClick={props.onShowCancelledEventsClick}
        />
      </Card>
    </Stack>
  );
}

function MonthlyView(props: {
  onDateClick: (date: DateTime) => () => void;
  showCancelledEvents: boolean;
  onShowCancelledEventsClick: () => void;
  onNewEventClick?: () => void;
}) {
  const [month, setMonth] = useState<DateTime>(DateTime.now().startOf('month'));

  const getTeacherShiftsRequest = useGetTeacherShifts({
    startsAt: month.startOf('month').startOf('week').minus({ day: 1 }).toMillis(),
    endsAt: month.endOf('month').endOf('week').minus({ day: 1 }).toMillis(),
  });

  const availablityExceptionsRequest = useGetTeacherAvailabilityExceptions();
  const availablityExeptionDates = availablityExceptionsRequest.data?.flatMap((exception) =>
    chunkTimeSpan(DateTime.fromMillis(exception.startsAt), DateTime.fromMillis(exception.endsAt)),
  );

  const monthlyScheduledLessons = useTeacherMonthlyScheduledLessonsQuery({ dateInMonth: month.toMillis() });

  const handleMonthChange = (newVal: DateTime) => {
    setMonth(newVal);
  };

  const onRenderCalendarDate = (date: DateTime) => {
    if (!monthlyScheduledLessons.data || !getTeacherShiftsRequest.data) {
      return null;
    }

    const lessonCount =
      monthlyScheduledLessons.data.lessonDates
        ?.find((ld) => ld.date === date.toSQLDate())
        ?.dayDetails.statuses.filter((s) => (props.showCancelledEvents ? s : s.status !== ScheduledLessonStatus.Cancelled))
        ?.map((d) => parseInt(d.count.toString()))
        ?.reduce((a, b) => a + b, 0) ?? 0;

    const shifts = getTeacherShiftsRequest.data.shifts
      .filter((s) => isBetween(s.startsAt, date.toMillis(), date.endOf('day').toMillis()))
      .sort((a, b) => a.startsAt - b.startsAt);

    const timeoff = availablityExeptionDates
      ?.filter((a) => isBetween(a.startsAt.toMillis(), date.toMillis(), date.endOf('day').toMillis()))
      .sort((a, b) => a.startsAt.toMillis() - b.startsAt.toMillis());

    return (
      <Stack spacing="16px" sx={{ width: '100%' }}>
        {shifts?.map((shift) => (
          <Shift
            status={shift.status}
            startsAt={DateTime.fromMillis(shift.startsAt).toFormat('h:mm a')}
            endsAt={DateTime.fromMillis(shift.endsAt).toFormat('h:mm a')}
          />
        ))}
        {lessonCount ? <LessonContainer lessonCount={lessonCount} /> : null}
        {timeoff?.map((to) => <TimeOff startsAt={to.startsAt.toFormat('h:mm a')} endsAt={to.endsAt.toFormat('h:mm a')} />)}
      </Stack>
    );
  };

  const isLoading = getTeacherShiftsRequest.isFetching || availablityExceptionsRequest.isLoading || monthlyScheduledLessons.isLoading;

  return (
    <Stack padding="24px">
      <Card isLoading={false}>
        <CalendarV3
          isLoading={isLoading}
          month={month}
          onMonthChange={handleMonthChange}
          renderCalendarDate={onRenderCalendarDate}
          onDateClick={props.onDateClick}
          showCancelledEvents={props.showCancelledEvents}
          onShowCancelledEventsClick={props.onShowCancelledEventsClick}
          onNewEventClick={props.onNewEventClick}
        />
      </Card>
    </Stack>
  );
}

function Shift(props: { startsAt: string; endsAt: string; status: TeacherShiftStatus }) {
  return (
    <Stack
      sx={{
        backgroundColor: shiftColor(props.status),
        borderRadius: '8px',
        padding: '8px',
        cursor: 'pointer',
      }}
      alignItems="center"
    >
      <Typography variant="labelsmall">Shift{props.status === TeacherShiftStatus.Cancelled ? ' (Cancelled)' : undefined}</Typography>
      <Typography variant="labelsmall">
        {props.startsAt} - {props.endsAt}
      </Typography>
    </Stack>
  );
}

function LessonContainer(props: { lessonCount: number }) {
  return (
    <Stack
      sx={{
        backgroundColor: '#FAFAFA',
        boxShadow: hootTokens.elevation.elevation1,
        borderRadius: '8px',
        padding: '8px',
        cursor: 'pointer',
      }}
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing="8px"
    >
      <Badge badgeContent={props.lessonCount} color="error.80" />
      <Typography variant="labelsmall">Lessons</Typography>
    </Stack>
  );
}

function TimeOff(props: { startsAt: string; endsAt: string }) {
  return (
    <Stack
      sx={{
        backgroundColor: hootTokens.palette.warning[180],
        borderRadius: '8px',
        padding: '8px',
        cursor: 'pointer',
      }}
      alignItems="center"
    >
      <Typography variant="labelsmall">Time Off</Typography>
      <Typography variant="labelsmall">
        {props.startsAt} - {props.endsAt}
      </Typography>
    </Stack>
  );
}

export const MyComp: React.FC<{ name: string }> = (props: { name: string }) => <div>{props.name}</div>;
