import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDistrictRepGetStudentProfileSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetStudentProfileSnippet';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Page from '@hoot/ui/components/v2/core/Page';

const DistrictRepStudentReportingPage = () => {
  const { studentProfileId } = useParams();
  const navigate = useNavigate();

  const { data: studentProfileSnippet } = useDistrictRepGetStudentProfileSnippet(studentProfileId ?? '', {
    enabled: !!studentProfileId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this student, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.myStudents.url, { replace: true });
      }
    },
  });
  const pageTitle = studentProfileSnippet?.name ? `${studentProfileSnippet.name}'s Reporting | Hoot Reading` : 'Student Reporting | Hoot Reading';

  const { districtRepLocationDetails } = useDistrictRepLocationDetails();

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingPage = locationHasReporting;

  if (!showReportingPage) {
    return <Navigate to="/" />;
  }

  return <Page pageTitle={pageTitle}>{/* sc-12355 will complete this portion */}</Page>;
};

export default DistrictRepStudentReportingPage;
