import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AssessmentUnitSubmission, useSubmitAssessment } from '@hoot/hooks/api/assessment/useSubmitAssessment';
import { UnitIdentifierHRAV2 } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import {
  Questionnaire4Form,
  Questionnaire4Submission,
  QuestionnaireLayout4,
} from '../../../../../../components/v2/assessments/teacher/questionnaires/QuestionnaireLayout4';
import useTeacherHRAController from '../useTeacherHRAController';

export interface HRAQuestionnaireLayout_4_Props {
  assessmentId: string;
  unitIdentifier: UnitIdentifierHRAV2.U_4_1;
  unitId: string;
  unitTitle: string;
  instructions: string;
  submissionId?: string;
  submissionData?: HRAQuestionnaireLayout_4_SubmitDataType;
  isPreviousUnitEnabled: boolean;
}

// This is how the API has this unit submission type formatted.
interface HRAQuestionnaireLayout_4_SubmitDataType {
  // Book
  U_4_1_S1_F1: {
    id: string;
    title: string;
  };
  // Word count
  U_4_1_S2_F1: number;
  // Number of errors
  U_4_1_S2_F2: number;
  // Time
  U_4_1_S2_F3: number;
  // Word count per minute
  U_4_1_S3_F1: number;
  // Accuracy rate
  U_4_1_S3_F2: number;
}

export function HRA_QUESTIONNAIRE_LAYOUT_4(props: HRAQuestionnaireLayout_4_Props) {
  const { assessmentId, unitId, unitIdentifier, unitTitle, instructions, submissionId, submissionData, isPreviousUnitEnabled } = props;

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { studentId, teacherId } = useSelector((state: RootState) => state.activeLesson.inLesson)!;
  const defaultWordCount = useSelector((state: RootState) => state.readers.inLessonReader.book?.wordCount);

  const dispatch = useAppDispatch();
  const hraController = useTeacherHRAController();
  const submitUnitRequest = useSubmitAssessment(assessmentId, unitId);

  useEffect(
    () => {
      // We don't show the "complete" state on this unit for students. Instead, we show that on the next unit 4.2 since
      // the student doesn't do anything there. We need a way to show the book to the student on this unit regardless if
      // this unit has been submitted or not.
      hraController.onUnitComplete(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const defaultFormValues = useMemo<Questionnaire4Form | undefined>(() => {
    if (!submissionData) {
      return {
        errors: 0,
        wordCount: defaultWordCount ?? 0,
        time: '',
      };
    }
    const timeInSeconds = submissionData.U_4_1_S2_F3;
    const minutes = Math.floor(timeInSeconds / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const seconds = (timeInSeconds % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const durationFormatted = `${minutes}:${seconds}`;

    return {
      time: durationFormatted,
      wordCount: submissionData.U_4_1_S2_F1 ?? defaultWordCount,
      errors: submissionData.U_4_1_S2_F2,
    };
  }, [submissionData, defaultWordCount]);

  const handleAllQuestionsAnswered = () => {
    // Do nothing here. If the teacher navigates back to this component after it has already been submitted, we need a
    // way to show the book to the student again. Instead, we'll show the "completed" screen at the next unit;
    // The student doesn't do anything on unit 4.2
  };

  const onPreviousUnitClick = () => {
    if (isDirty) {
      setLeaveDialog(true);
    } else {
      hraController.onPrevUnit();
    }
  };

  const confirmPrevUnitClick = () => {
    hraController.onPrevUnit();
  };

  const onNextUnit = () => {
    hraController.onNextUnit();
  };

  const handleSubmit = (data: Questionnaire4Submission) => {
    // We've basically abandoned the form builder, so we kinda need to hardcode these field keys (yeah, it's ugly).
    const submissionData: HRAQuestionnaireLayout_4_SubmitDataType = {
      U_4_1_S1_F1: {
        id: data.bookId,
        title: data.bookTitle,
      },
      U_4_1_S2_F1: Number(data.wordCount),
      U_4_1_S2_F2: Number(data.errors),
      U_4_1_S2_F3: data.timeInSeconds,
      U_4_1_S3_F1: data.wcpm,
      U_4_1_S3_F2: data.accuracyRate,
    };
    const unitSubmission: AssessmentUnitSubmission = {
      submissionId: submissionId,
      studentProfileId: studentId,
      teacherAccountId: teacherId,
      score: undefined,
      instructionIndicator: undefined,
      transitionScore: undefined,
      submissionData,
    };
    submitUnitRequest.mutate(unitSubmission, {
      onSuccess: () => {
        dispatch(createFlashMessage({ message: `Unit ${unitTitle} successfully submitted.` }));
        hraController.onNextUnit();
      },
      onError: (err) => {
        console.log(err);
        dispatch(createFlashMessage({ message: `Oops! There was a problem submitting unit ${unitTitle}.` }));
      },
    });
  };

  return (
    <QuestionnaireLayout4
      title="Hoot Reading Assessment"
      unitIdentifier={unitIdentifier}
      unitTitle={unitTitle}
      instruction={instructions}
      value={defaultFormValues ?? undefined}
      isSubmitting={submitUnitRequest.isLoading}
      isNextEnabled={true}
      isPrevEnabled={isPreviousUnitEnabled}
      isUnitQuestionnaireDirty={isDirty}
      leaveDialog={leaveDialog}
      onSubmit={handleSubmit}
      onNext={onNextUnit}
      confirmPrevUnitClick={confirmPrevUnitClick}
      onPreviousUnitClick={onPreviousUnitClick}
      onAllQuestionsAnswered={handleAllQuestionsAnswered}
      setLeaveDialog={setLeaveDialog}
      isDirty={isDirty}
      setIsDirty={setIsDirty}
      onExitAssessment={hraController.onExitAssessment}
    />
  );
}
