import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { UseGetOptions } from '@hoot/models/api/axios-options';
import {
  LessonReviewEngagement,
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusUnit,
  ResourceReader,
} from '@hoot/models/api/enums/lesson-review-enums';
import { ResourceProgress } from '../../../models/api/library';
import { QueryKey } from '../queryKeys';

export interface GetLessonReviewDetailsResponse {
  formativeAssessment: {
    wordReadingBooks: LessonReviewWordReadingFormativeAssessmentBook[];
    textReadingBooks: LessonReviewTextReadingFormativeAssessmentBook[];
    lettersAndSoundsBooks: LessonReviewLetterAndSoundsFormativeAssessmentBook[];
  };
  instructionalFocus: {
    primaryFocus: LessonReviewInstructionalFocus;
    secondaryFocus?: LessonReviewInstructionalFocus;
  };
  resourceProgress: {
    books: LessonReviewResourceProgressBook[];
  };
  studentEngagement: LessonReviewEngagement;
  studentEngagementExternalFactors: LessonReviewEngagementExternalFactors | undefined;
  lessonNotes: string;
  version: number;
}

interface LessonReviewEngagementExternalFactors {
  classroomOrSurroundingsWereLoud: boolean;
  peopleInSurroundingsDistracting: boolean;
  audioOrVideoIssues: boolean;
}

interface LessonReviewTextReadingFormativeAssessmentBook {
  bookId: string;
  title: string;
  imageUrl: string | undefined;
  wordCount: number;
  errors: number;
  runningTime: number;
}
interface LessonReviewWordReadingFormativeAssessmentBook {
  bookId: string;
  title: string;
  imageUrl: string | undefined;
  words: LessonReviewFormativeAssessmentBookWord[];
}

interface LessonReviewLetterAndSoundsFormativeAssessmentBook {
  bookId: string;
  title: string;
  imageUrl: string | undefined;
  letters: LessonReviewFormativeAssessmentBookLetter[];
}

interface LessonReviewFormativeAssessmentBookWord {
  word: string;
  readCorrectly: boolean;
}

interface LessonReviewFormativeAssessmentBookLetter {
  letter: string;
  readCorrectly: boolean;
}

interface LessonReviewInstructionalFocus {
  focus: LessonReviewFocus;
  books: LessonReviewFocusBook[];
  unit?: LessonReviewFocusUnit;
  skill?: LessonReviewFocusSkill;
}

interface LessonReviewFocusBook {
  bookId: string;
  title: string;
  imageUrl: string | undefined;
  progress?: ResourceProgress;
  reader?: ResourceReader;
}

interface LessonReviewResourceProgressBook {
  bookId: string;
  title: string;
  imageUrl: string | undefined;
  progress: ResourceProgress;
  reader: ResourceReader;
}

const useGetLessonReviewDetails = (lessonId: string, options?: UseGetOptions<GetLessonReviewDetailsResponse>) => {
  return useQuery(
    [QueryKey.GetLessonReviewDetails, lessonId],
    async () => {
      return (await axios.get<GetLessonReviewDetailsResponse>(`${config.apiUrl}/public/lesson-review/lesson/${lessonId}`)).data;
    },
    options,
  );
};

export default useGetLessonReviewDetails;
