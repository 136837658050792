import { Skeleton, SkeletonProps } from '@mui/material';
import React, { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';

interface ImageProps extends Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'alt'> {
  alt: string;
  skeletonProps?: SkeletonProps;
  onImageLoaded?: () => void;
}

/**
 * This component renders a regular <img> tag for an image, but also will render an MUI skeleton in place of the image
 * until it has been loaded.
 */
const AsyncImage = (props: ImageProps) => {
  const { skeletonProps, style: imgStyle, onImageLoaded, alt, ...imgProps } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setIsImageLoaded(true);
    onImageLoaded?.();
  };

  return (
    <>
      <img
        width={imgProps.width}
        height={imgProps.height}
        onLoad={handleImageLoaded}
        alt={alt}
        style={{
          display: !isImageLoaded ? 'none' : 'block',
          ...imgStyle,
        }}
        {...imgProps}
      />
      {!isImageLoaded ? (
        <Skeleton
          // Just use the same dimensions as the <img>.
          // NOTE: If no dimensions are passed in, this will probably look messed up.
          width={imgProps.width}
          height={imgProps.height}
          variant="rectangular"
          sx={{
            ...imgStyle,
            ...(skeletonProps?.sx ?? {}),
          }}
        />
      ) : null}
    </>
  );
};

export default AsyncImage;
