import { Stack } from '@mui/material';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import AssessmentReminderCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/AssessmentReminder';
import AssessmentHistoryCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/AssessmentHistoryCard';
import StudentInstructionalFocusAreas from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/instructional-focus-areas/StudentInstructionalFocusAreas';
import FormativeAssessmentHistoryCard from './FormativeAssessmentHistoryCard';
import ProgressMonitoringAssessmentHistoryCard from './ProgressMonitoringAssessmentHistoryCard';

const StudentAssessments = () => {
  return (
    <PageLayout>
      <Stack gap={2}>
        <AssessmentReminderCard />
        <StudentInstructionalFocusAreas />
        <AssessmentHistoryCard />
        <ProgressMonitoringAssessmentHistoryCard />
        <FormativeAssessmentHistoryCard />
      </Stack>
    </PageLayout>
  );
};

export default StudentAssessments;
