import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface PublicStudentScheduleResponse {
  studentNumber: number;
  lessonDays: LessonDays;
}

interface LessonDays {
  sunday: string[];
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  [key: string]: string[];
}

async function getPublicStudentSchedule(locationId: string, studentHfsId: string, userTimeZone: string): Promise<PublicStudentScheduleResponse> {
  const { data } = await axios.get<PublicStudentScheduleResponse>(
    `${config.apiUrl}/public/scheduler/student-schedule/${locationId}/${studentHfsId}?userTimeZone=${userTimeZone}`,
  );
  return data;
}

export default function useGetPublicStudentSchedule(
  locationId: string,
  studentHfsId: string,
  userTimeZone: string,
  options?: Omit<UseQueryOptions<PublicStudentScheduleResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetPublicStudentSchedule], () => getPublicStudentSchedule(locationId, studentHfsId, userTimeZone), options);
}
