import { Tabs, TabsProps } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDistrictRepGetStudentProfileSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetStudentProfileSnippet';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import useDistrictRepLocationDetails from '@hoot/hooks/useDistrictRepLocationDetails';
import useProfile from '@hoot/hooks/useProfile';
import { updateDistrictRepEnrolmentsLessons } from '@hoot/redux/reducers/districtRepSlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, useHasDistrictPermission } from '@hoot/routes/secure/DistrictRepProtectedRoute';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export enum HFSStudentPageTabs {
  Details = 'Details',
  Schedule = 'Schedule',
  Reporting = 'Reporting',
  Enrolments = 'Enrolments',
}

const tabToRouteDictionary: Record<HFSStudentPageTabs, (studentProfileId: string) => string> = {
  [HFSStudentPageTabs.Details]: (studentProfileId: string) => routesDictionary.students.student.details.url(studentProfileId),
  [HFSStudentPageTabs.Schedule]: (studentProfileId: string) => routesDictionary.students.student.schedule.url(studentProfileId),
  [HFSStudentPageTabs.Reporting]: (studentProfileId: string) => routesDictionary.students.student.reporting.url(studentProfileId),
  [HFSStudentPageTabs.Enrolments]: (studentProfileId: string) => routesDictionary.students.student.enrolments.url(studentProfileId),
};

const HFSStudentHeader = () => {
  const { studentProfileId } = useParams();
  const { data: studentProfileSnippet, isLoading } = useDistrictRepGetStudentProfileSnippet(studentProfileId ?? '', {
    enabled: !!studentProfileId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this student, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.myStudents.url, { replace: true });
      }
    },
  });

  const { districtRepLocationDetails } = useDistrictRepLocationDetails();
  const { isDistrictRep, profile } = useProfile();
  const { data: districtRepPermissions } = useGetDistrictRepPermissions(profile?.id || '', { enabled: isDistrictRep });
  const hasEnrolmentEditLevelPermissions = useHasDistrictPermission(DistrictRepAccessLevelCombinations.EnrolmentEditLevel, districtRepPermissions);

  const locationHasReporting = !!districtRepLocationDetails?.reporting;
  const showReportingTab = locationHasReporting && hasEnrolmentEditLevelPermissions;

  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateDistrictRepEnrolmentsLessons({ lessons: [] }));
    };
  }, [dispatch]);

  const pageTitle = studentProfileSnippet?.name ? `${studentProfileSnippet.name} | Hoot Reading` : 'Student | Hoot Reading';

  const isActiveTabPath = (tab: HFSStudentPageTabs) => {
    return !!matchPath(tabToRouteDictionary[tab](studentProfileId!), pathname);
  };

  const getActiveTab = (): HFSStudentPageTabs => {
    if (isActiveTabPath(HFSStudentPageTabs.Details)) {
      return HFSStudentPageTabs.Details;
    } else if (isActiveTabPath(HFSStudentPageTabs.Schedule)) {
      return HFSStudentPageTabs.Schedule;
    } else if (isActiveTabPath(HFSStudentPageTabs.Enrolments)) {
      return HFSStudentPageTabs.Enrolments;
    } else if (isActiveTabPath(HFSStudentPageTabs.Reporting)) {
      return HFSStudentPageTabs.Reporting;
    } else {
      return HFSStudentPageTabs.Details;
    }
  };

  const handleTabChange: TabsProps['onChange'] = (_, newTab: HFSStudentPageTabs) => {
    navigate(tabToRouteDictionary[newTab]?.(studentProfileId!) ?? routesDictionary.students.student.details.url(studentProfileId!), {
      state: { previousPath: state?.previousPath },
    });
  };

  return (
    <>
      <SubHeader
        title={{
          label: studentProfileSnippet?.name ?? 'Student',
          isPII: true,
          isLoading,
        }}
        backButton={{
          show: true,
          navigateBackPath: state?.previousPath ? state.previousPath : undefined,
        }}
      />
      <Page pageTitle={pageTitle} noPadding noMaxWidth>
        <Tabs value={getActiveTab()} onChange={handleTabChange} variant="fullWidth" sx={{ px: 3, backgroundColor: hootTokens.palette.white }}>
          <Tab label="Details" value={HFSStudentPageTabs.Details} isSelected={getActiveTab() === HFSStudentPageTabs.Details} />
          <Tab label="Schedule" value={HFSStudentPageTabs.Schedule} isSelected={getActiveTab() === HFSStudentPageTabs.Schedule} />
          <Tab label="Enrolments" value={HFSStudentPageTabs.Enrolments} isSelected={getActiveTab() === HFSStudentPageTabs.Enrolments} />
          {showReportingTab ? (
            <Tab label="Reporting" value={HFSStudentPageTabs.Reporting} isSelected={getActiveTab() === HFSStudentPageTabs.Reporting} />
          ) : null}
        </Tabs>
        <Outlet />
      </Page>
    </>
  );
};

export default HFSStudentHeader;
