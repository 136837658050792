import { Stack, Tabs, TabsProps } from '@mui/material';
import { Outlet, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Page from '@hoot/ui/components/v2/core/Page';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { Tab } from '@hoot/ui/components/v2/core/Tab';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

enum StudentDetailTabs {
  Profile,
  Timeline,
  Reviews,
  Assessments,
}

const tabToRouteDictionary: Record<StudentDetailTabs, (studentProfileId: string) => string> = {
  [StudentDetailTabs.Profile]: (studentProfileId: string) => routesDictionary.myStudents.details.profile.url(studentProfileId),
  [StudentDetailTabs.Timeline]: (studentProfileId: string) => routesDictionary.myStudents.details.timeline.url(studentProfileId),
  [StudentDetailTabs.Reviews]: (studentProfileId: string) => routesDictionary.myStudents.details.reviews.url(studentProfileId),
  [StudentDetailTabs.Assessments]: (studentProfileId: string) => routesDictionary.myStudents.details.assessments.url(studentProfileId),
};

const StudentDetailsHeader = () => {
  const { studentProfileId } = useParams();
  const { studentProfileSnippet } = useStudentDetailsContext();

  const navigate = useNavigate();
  const location = useLocation();
  const navBackPath = location.state?.navigateBackPath;
  const showStudentTimeline = studentProfileSnippet.showTimeLine;

  const pageTitle = studentProfileSnippet?.name ? `${studentProfileSnippet.name} | Hoot Reading` : 'Student | Hoot Reading';

  const isActiveTabPath = (tab: StudentDetailTabs) => {
    return !!matchPath(tabToRouteDictionary[tab](studentProfileId!), location.pathname);
  };

  const getActiveTab = (): StudentDetailTabs => {
    if (isActiveTabPath(StudentDetailTabs.Profile)) {
      return StudentDetailTabs.Profile;
    } else if (isActiveTabPath(StudentDetailTabs.Timeline)) {
      return StudentDetailTabs.Timeline;
    } else if (isActiveTabPath(StudentDetailTabs.Reviews)) {
      return StudentDetailTabs.Reviews;
    } else if (isActiveTabPath(StudentDetailTabs.Assessments)) {
      return StudentDetailTabs.Assessments;
    } else {
      return StudentDetailTabs.Profile;
    }
  };

  const handleTabChange: TabsProps['onChange'] = (_, newTab: StudentDetailTabs) => {
    navigate(tabToRouteDictionary[newTab]?.(studentProfileId!) ?? routesDictionary.myStudents.details.profile.url(studentProfileId!));
  };

  const onOpenLibraryClick = () => {
    if (!!studentProfileId) {
      window.open(routesDictionary.myStudents.details.library.url(studentProfileId), '_blank');
    }
  };

  return (
    <Page data-sentry-mask pageTitle={pageTitle} noPadding noMaxWidth>
      <Stack sx={{ background: hootTokens.palette.white }}>
        <SubHeader
          title={{
            label: studentProfileSnippet?.name,
            isPII: true,
          }}
          backButton={{
            show: true,
            navigateBackPath: navBackPath,
          }}
          breadcrumbs={['My Students', studentProfileSnippet.name]}
          primaryAction={{
            label: `Open Library`,
            onClick: onOpenLibraryClick,
            props: {
              startIcon: <Icon name="new_tab" htmlColor={hootTokens.palette.white} sx={{ marginLeft: '8px' }} />,
            },
          }}
        />
        <PageLayout RootBoxProps={{ py: 0 }}>
          <Tabs value={getActiveTab()} onChange={handleTabChange} variant="fullWidth" sx={{ px: 3 }}>
            <Tab label="Profile" value={StudentDetailTabs.Profile} isSelected={getActiveTab() === StudentDetailTabs.Profile} />
            {showStudentTimeline ? (
              <Tab label="Timeline" value={StudentDetailTabs.Timeline} isSelected={getActiveTab() === StudentDetailTabs.Timeline} />
            ) : null}
            <Tab label="Reviews" value={StudentDetailTabs.Reviews} isSelected={getActiveTab() === StudentDetailTabs.Reviews} />
            <Tab label="Assessments" value={StudentDetailTabs.Assessments} isSelected={getActiveTab() === StudentDetailTabs.Assessments} />
          </Tabs>
        </PageLayout>
      </Stack>
      <Outlet />
    </Page>
  );
};

export default StudentDetailsHeader;
