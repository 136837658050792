import { Box, Card, Dialog, DialogContent, DialogTitle, Skeleton, Stack, SvgIcon, SvgIconProps, Tooltip, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { featureFlags } from '@hoot/constants/featureFlags';
import { BookAttribute, BookResponse } from '@hoot/events/interfaces/book-response';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import useProfile from '@hoot/hooks/useProfile';
import { ReadingLevelType } from '@hoot/models/api/enums';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { BookType, DecodableFormat, resourceProgressTypes, resourceStateTypes, subjectTypes } from '@hoot/models/api/library';
import { UserProfileType } from '@hoot/models/api/user';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import TextField from '@hoot/ui/components/v2/core/TextField';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import theme from '@hoot/ui/theme/v2';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { toProperCase } from '@hoot/utils/text-display';
import BookDisplay from './BookDisplay';

type SupportedAttributeNames =
  | 'resourceState'
  | 'resourceProgress'
  | 'subject'
  | 'instructionalFocuses'
  | 'minAgeLevel'
  | 'instructionalUnits'
  | 'bookLevelPrioritizedSkills'
  | 'decodableFormats'
  | 'hiLo'
  | 'nonConforming'
  | 'series'
  | 'bookContentTags'
  | 'readingLevel'
  | 'sequenceNumber'
  | 'wordCount';

export interface AttributeItem {
  attributeName: SupportedAttributeNames;
  label: string;
  value?: string | number | null;
  icon?: ReactElement<SvgIconProps, typeof SvgIcon> | null;
  onClick?: (value?: any) => void;
  color?: ColorPaletteOption;
}

const BookAttributes = (props: {
  book: BookResponse;
  onDismiss: () => void;
  showResourceProgressAndState: boolean;
  query?: QueryLibraryV2;
  onLibraryQueryChanged?: (newQuery: QueryLibraryV2) => void;
}) => {
  const { book, onDismiss, showResourceProgressAndState, query, onLibraryQueryChanged } = props;
  const isDecodable = book.readingLevel.readingLevelTypeId === ReadingLevelType.Decodables;
  const hasAllSkillAttributes =
    isDecodable &&
    book.filterLevelPrioritizedSkills.length > 0 &&
    book.instructionalUnits.length > 0 &&
    book.instructionalFocuses.length > 0 &&
    !!book.resourceType;

  const handleQueryByPrioritySkill = () => {
    if (query && onLibraryQueryChanged && hasAllSkillAttributes && !!book.resourceType) {
      onLibraryQueryChanged({
        ...query,
        title: '',
        resourceType: book.resourceType,
        instructionalFocusIds: book.instructionalFocuses.map((i) => i.id),
        instructionalUnitIds: book.instructionalUnits.map((i) => i.id),
        filterLevelPrioritizedSkillIds: book.filterLevelPrioritizedSkills.map((i) => i.id),
        page: 1,
      });
    }
    onDismiss();
  };

  const handleQueryBySeries = () => {
    if (query && onLibraryQueryChanged && book.seriesId) {
      onLibraryQueryChanged({
        ...query,
        title: '',
        seriesId: book.seriesId,
        page: 1,
      });
    }
    onDismiss();
  };

  const getAttributeNames = (attributes?: BookAttribute[]): string | null => {
    return attributes ? attributes.map((s) => s.name)?.join(', ') : null;
  };

  const getReadingLevel = (): string | null => {
    return book?.readingLevel.name === 'N/A' ? null : book?.readingLevel.name;
  };

  const attributesToShow: AttributeItem[] = [
    { attributeName: 'subject', label: 'Subject', value: subjectTypes(book.subject) },
    {
      attributeName: 'instructionalFocuses',
      label: 'Instructional Focus(es)',
      value: getAttributeNames(book.instructionalFocuses),
    },
    { attributeName: 'minAgeLevel', label: 'Minimum Age', value: book.minAgeLevel },
    {
      attributeName: 'instructionalUnits',
      label: 'Instructional Unit(s)',
      value: getAttributeNames(book.instructionalUnits),
    },
    {
      attributeName: 'bookLevelPrioritizedSkills',
      label: 'Prioritized Skill(s)',
      value: getAttributeNames(book.bookLevelPrioritizedSkills),
      icon: hasAllSkillAttributes ? <Icon name="new_tab" onClick={handleQueryByPrioritySkill} /> : null,
    },
    {
      attributeName: 'decodableFormats',
      label: 'Decodable Format(s)',
      value: book.decodableFormatId ? toProperCase(book.decodableFormatId) : '',
    },
    { attributeName: 'hiLo', label: 'Hi-Lo', value: book.hiLo ? 'True' : '' },
    { attributeName: 'nonConforming', label: 'Non-Conforming', value: book.nonConforming ? 'True' : '' },
    {
      attributeName: 'series',
      label: 'Series',
      value: book.series,
      icon: book.seriesId ? <Icon name="new_tab" onClick={handleQueryBySeries} /> : null,
    },
    { attributeName: 'readingLevel', label: 'Level', value: getReadingLevel() },
  ];

  if (book.bookSequence !== null && book.bookSequence !== undefined) {
    attributesToShow.push({
      attributeName: 'sequenceNumber',
      label: 'Sequence',
      value: book.decodableFormatId === DecodableFormat.DecodablePassages ? `Passage ${book.bookSequence}` : `Book ${book.bookSequence}`,
    });
  }

  if (showResourceProgressAndState) {
    attributesToShow.splice(0, 0, {
      attributeName: 'resourceState',
      label: 'Resource State',
      value: resourceStateTypes(book.resourceState),
    });
    attributesToShow.splice(0, 0, {
      attributeName: 'resourceProgress',
      label: 'Resource Progress',
      value: resourceProgressTypes(book.resourceProgress),
    });
  }

  if (
    book.resourceType === BookType.HootReadingAssessment &&
    book.instructionalFocuses.find((i) => i.id === InstructionalFocus.AssessmentTextReading)
  ) {
    attributesToShow.push({ attributeName: 'wordCount', label: 'Word Count', value: book.wordCount });
  } else if (
    book.resourceType === BookType.FormativeAssessment &&
    book.instructionalFocuses.find((i) => i.id === InstructionalFocus.InstructionTextReading)
  ) {
    attributesToShow.push({ attributeName: 'wordCount', label: 'Word Count', value: book.wordCount });
  }

  return (
    <Grid container spacing={2} sx={{ py: { xs: 3, md: 0 } }} alignContent="flex-start">
      {attributesToShow
        .filter((a) => !!a.value)
        .map((item) => {
          return (
            <Grid size={{ xs: 12, md: 6 }} key={item.attributeName}>
              <TextField
                label={item.label}
                variant="filled"
                readOnly
                value={item.value}
                emphasizeLabel={true}
                filledColour={item.color ? item.color : 'primary.190'}
                InputProps={{
                  endAdornment:
                    item.icon && onLibraryQueryChanged ? (
                      <Stack alignSelf="center" sx={{ cursor: 'pointer', pr: 2 }}>
                        {item.icon}
                      </Stack>
                    ) : null,
                }}
              />
            </Grid>
          );
        })}
      {book.bookContentTags ? (
        <Grid size={12} key={'Content-Tags'}>
          <TextField
            label="Content Tags"
            variant="filled"
            readOnly
            value={book.bookContentTags?.join(', ')}
            emphasizeLabel={true}
            filledColour="secondary.190"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

interface Props {
  show: boolean;
  book: BookResponse;
  query?: QueryLibraryV2;
  studentProfileId?: string;
  isInLesson: boolean;
  onDismiss: (invalidateFavs: boolean) => void;
  onLibraryQueryChanged?: (newQuery: QueryLibraryV2) => void;
  onPreviewPreviousBook?: () => void;
  onPreviewNextBook?: () => void;
  onToggleFavouriteBook: (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => void;
  onOpenBook?: (book: BookResponse, defaultPageId?: string) => void;
}

const TeacherBookPreviewModal = (props: Props) => {
  const {
    show,
    book,
    query,
    isInLesson,
    studentProfileId,
    onDismiss,
    onLibraryQueryChanged,
    onPreviewPreviousBook,
    onToggleFavouriteBook,
    onPreviewNextBook,
    onOpenBook,
  } = props;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { profile } = useProfile();

  const viewState = !book ? ViewStateEnum.Loading : ViewStateEnum.Results;
  const showResourceProgressAndState = !!studentProfileId;

  const bookmarkedPageIndex = useMemo(() => {
    if (!book.studentBookmarkPageId) {
      return undefined;
    }
    return book.pages.findIndex((page) => page.id === book.studentBookmarkPageId);
  }, [book]);

  const [wasFavToggled, setWasFavToggled] = useState(false);

  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);

  useEffect(() => {
    if (show) {
      setWasFavToggled(false);
    }
  }, [show]);

  const handlePreviousBook = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    onPreviewPreviousBook?.();
  };

  const handleNextBook = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    onPreviewNextBook?.();
  };

  const _onToggleFavouriteBook = (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => {
    onToggleFavouriteBook(bookId, profileId, profileType, markAsFav);
    setWasFavToggled(true);
  };

  const _onDismiss = () => {
    onDismiss(wasFavToggled);
  };

  function PreviousBookButton() {
    if (isDesktop && !isInLesson && onPreviewPreviousBook) {
      return (
        <Tooltip title="Previous Book">
          <span>
            <IconButton onClick={handlePreviousBook} sx={{ mr: 2 }}>
              <Icon name="chevron" />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
    return null;
  }

  function CloseButton() {
    if (!isDesktop) {
      return (
        <Button variant="outlined" size="medium" onClick={_onDismiss} fullWidth>
          Close
        </Button>
      );
    }
    return null;
  }

  function OpenBookButton() {
    const openBookAtBookmark = () => {
      onOpenBook?.(book, book?.studentBookmarkPageId ?? undefined);
    };

    const buttonLabel = bookmarkedPageIndex !== undefined ? `Resume (${bookmarkedPageIndex + 1})` : 'Open Book';

    return (
      <Button variant="contained" size="medium" onClick={openBookAtBookmark} startIcon={<PlayIcon htmlColor="#FFF" />} fullWidth>
        {buttonLabel}
      </Button>
    );
  }

  function StartFromBeginningButton() {
    const onOpenBookAtFirstPage = () => {
      onOpenBook?.(book);
    };

    return (
      <Tooltip title="Start from Beginning">
        {isScopeAndSequenceEnabled ? (
          <Button
            onClick={onOpenBookAtFirstPage}
            variant="outlined"
            size="medium"
            startIcon={<Icon name="redo" />}
            sx={{ backgroundColor: hootTokens.palette.white }}
          >
            Start Over
          </Button>
        ) : (
          <span>
            <Button onClick={onOpenBookAtFirstPage} variant="outlined" size="medium">
              <Icon name="redo" />
            </Button>
          </span>
        )}
      </Tooltip>
    );
  }

  function NextBookButton() {
    if (isDesktop && !isInLesson && onPreviewNextBook) {
      return (
        <Tooltip title="Next Book">
          <span>
            <IconButton onClick={handleNextBook} sx={{ ml: 2 }}>
              <Icon name="chevron" sx={{ rotate: '180deg' }} />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return null;
  }

  return (
    <>
      <Dialog
        open={show}
        onClose={_onDismiss}
        fullWidth
        maxWidth={'lg'}
        PaperProps={
          isInLesson && isDesktop
            ? {
                sx: {
                  marginLeft: '288px',
                  marginBottom: '128px',
                },
              }
            : undefined
        } // TODO: Remove a some point, just a hack right now to get it to work
      >
        {book ? (
          <>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Stack direction="row" alignItems="center" spacing={isScopeAndSequenceEnabled ? 2 : 0}>
                {isScopeAndSequenceEnabled ? (
                  //ToDo Add scope and sequence button logic for this component in sc-13169
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '50px',
                      height: '50px',
                      borderRadius: '8px',
                      border: '1px solid',
                      borderColor: hootTokens.surface[4],
                      background: hootTokens.palette.white,
                      boxShadow: hootTokens.elevation.elevation2,
                    }}
                  >
                    <img src="/images/hoot-shapes.svg" alt="scope-and-sequence-logo" height="33px" width="33px" />
                  </Box>
                ) : null}
                <Stack>
                  {book.title}
                  <HootTypography isPII={false} variant="bodysmall">
                    {book.publisher.name}
                  </HootTypography>
                </Stack>
              </Stack>
              <IconButton onClick={_onDismiss}>
                <Icon name="close" />
              </IconButton>
            </DialogTitle>

            <DialogContent
              sx={{
                pb: 3,
                overflowY: 'auto',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <PreviousBookButton />

                <Grid container spacing={{ xs: 2, md: 6 }}>
                  <Grid container size={{ xs: 12, md: 4 }} justifyContent="center" alignSelf="center">
                    {isScopeAndSequenceEnabled ? (
                      <Card sx={{ backgroundColor: hootTokens.palette.primary[190] }}>
                        <Stack sx={{ paddingX: { md: 4, lg: 6, xl: 8 } }}>
                          <BookDisplay
                            book={{
                              id: book.id,
                              title: book.title,
                              coverUrl: book.coverUrl,
                              publisherName: book.publisher.name,
                            }}
                          />
                        </Stack>
                        <Stack width={'100%'} gap="8px">
                          <BookFavouriteToggleButton
                            book={book}
                            profileId={studentProfileId ?? profile!.id}
                            profileType={studentProfileId ? UserProfileType.Student : UserProfileType.Teacher}
                            onToggleFavouriteBook={_onToggleFavouriteBook}
                            isScopeAndSequenceEnabled={isScopeAndSequenceEnabled}
                          />

                          {bookmarkedPageIndex !== undefined ? <StartFromBeginningButton /> : null}

                          {onOpenBook ? <OpenBookButton /> : null}
                        </Stack>
                      </Card>
                    ) : (
                      <>
                        <BookDisplay
                          book={{
                            id: book.id,
                            title: book.title,
                            coverUrl: book.coverUrl,
                            publisherName: book.publisher.name,
                          }}
                        />
                        <Stack width={'100%'} gap="8px">
                          {onOpenBook && (
                            <Stack gap="8px" direction="row">
                              {bookmarkedPageIndex !== undefined && <StartFromBeginningButton />}
                              <OpenBookButton />
                            </Stack>
                          )}
                          <BookFavouriteToggleButton
                            book={book}
                            profileId={studentProfileId ?? profile!.id}
                            profileType={studentProfileId ? UserProfileType.Student : UserProfileType.Teacher}
                            onToggleFavouriteBook={_onToggleFavouriteBook}
                            isScopeAndSequenceEnabled={isScopeAndSequenceEnabled}
                          />
                        </Stack>
                      </>
                    )}
                  </Grid>

                  <Grid container size={{ xs: 12, md: 8 }}>
                    <BookAttributes
                      book={book}
                      query={query}
                      onDismiss={_onDismiss}
                      onLibraryQueryChanged={onLibraryQueryChanged}
                      showResourceProgressAndState={showResourceProgressAndState}
                    />
                    <CloseButton />
                  </Grid>
                </Grid>

                <NextBookButton />
              </Stack>
            </DialogContent>
          </>
        ) : (
          <DialogContent>
            <ViewState state={viewState} loadingContent={<SkeletonModal />} />
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

const SkeletonModal = () => (
  <Grid container p={{ xs: 3, md: 3 }} sx={{ width: { xs: '100%' }, height: { xs: '100%' } }}>
    <Skeleton variant="rounded" sx={{ width: '100%', minHeight: '60px' }} />
    <Grid container p={{ xs: 0, md: 0 }} height="100%">
      <Grid container p={{ xs: 3, md: 3 }} size={{ xs: 12, md: 4 }}>
        <Skeleton variant="rounded" sx={{ width: '256px', height: '300px' }} />
      </Grid>
      <Grid container p={{ xs: 0, md: 3 }} size={{ xs: 12, md: 8 }}>
        <Grid container spacing={2} alignContent="flex-start">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((idx) => (
            <Grid key={idx} size={{ xs: 12, md: 6 }}>
              <Skeleton variant="rounded" sx={{ width: '100%', minHeight: '75px' }} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

interface FavouritesToggleButtonProps {
  book: BookResponse;
  profileId: string;
  profileType: UserProfileType;
  onToggleFavouriteBook: (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => void;
  isScopeAndSequenceEnabled: boolean;
}

const BookFavouriteToggleButton = (props: FavouritesToggleButtonProps) => {
  const { book, profileId, profileType, onToggleFavouriteBook, isScopeAndSequenceEnabled } = props;

  const isFavourite = book.shelf[profileId];

  const _onToggleFavouriteBook = () => {
    const markAsFav = !book!.shelf[profileId];
    onToggleFavouriteBook(book.id, profileId, profileType, markAsFav);
  };

  const getLabel = () => {
    switch (profileType) {
      case UserProfileType.Student:
        return isFavourite
          ? `Remove from${isScopeAndSequenceEnabled ? '' : ' Student'} Favorites`
          : `Add to${isScopeAndSequenceEnabled ? '' : ' Student'} Favorites`;
      case UserProfileType.Teacher:
        return isFavourite ? 'Remove from my Favorites list' : 'Add to my Favorites list';
    }
  };

  return (
    <Button
      variant="outlined"
      size="medium"
      onClick={_onToggleFavouriteBook}
      startIcon={isFavourite ? <Icon name="favourite_filled" htmlColor={hootTokens.palette.error[140]} /> : <Icon name="favourite" />}
      fullWidth
      sx={{ backgroundColor: hootTokens.palette.white }}
    >
      {getLabel()}
    </Button>
  );
};

export default TeacherBookPreviewModal;
