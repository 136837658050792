import { HootHttpHeader } from '@hoot-reading/hoot-core/dist/enums/hoot-http-header';
import axios from 'axios';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ExchangeSsoTokenResponse, useSsoTokenExchange } from '@hoot/hooks/api/auth/useSsoTokenExchange';
import useProfile from '@hoot/hooks/useProfile';
import { useSessionStorage } from '@hoot/hooks/useSessionStorage';
import { LoginType } from '@hoot/models/loginType';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import { resetActiveLesson } from '@hoot/redux/reducers/activeLessonSlice';
import { error, success } from '@hoot/redux/reducers/alertSlice';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { resetAllLibraries } from '@hoot/redux/reducers/librarySlice';
import { resetAllReaders } from '@hoot/redux/reducers/readingSlice';
import { resetUpcomingLessons } from '@hoot/redux/reducers/upcomingLessonsSlice';
import { sentry } from '@hoot/telemetry/sentry';
import { zendeskInitializeLiveChatScript, zendeskLiveChatScriptInitialized, zendeskLoginUser, zendeskLogoutUser } from '@hoot/utils/zendesk-methods';
import { LOCAL_APP_VERSION, LOCAL_STORAGE_USER_KEY, LOCAL_STORAGE_USER_TOKEN_KEY } from '../../constants/constants';
import useAssumeProfile from '../../hooks/api/auth/useAssumeProfile';
import { useImpersonateLogin } from '../../hooks/api/auth/useImpersonateLogin';
import useLogin, { CredentialType } from '../../hooks/api/auth/useLogin';
import useLogout from '../../hooks/api/auth/useLogout';
import { useRefreshToken } from '../../hooks/api/auth/useRefreshToken';
import useGetUser from '../../hooks/api/user/useGetUser';
import { useInterval } from '../../hooks/useInterval';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Tokens } from '../../models/api/auth';
import { Profile, User, UserProfileType } from '../../models/api/user';
import { resetProfile, setLoginType, updateProfile, updateProfiles } from '../../redux/reducers/profileSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import { routesDictionary } from '../../routes/routesDictionary';
import { clearLocalStorage } from '../../utils/localStorage-whiteList';
import { useCurrentTime } from './CurrentTimeContext';

interface Props {
  children: React.ReactNode;
}

interface Values {
  tokens?: Tokens;
  getUser: () => User;
  isAuthenticated?: boolean;
  login: (params: {
    credentials: string;
    password: string;
    friendlyId?: string;
    credentialType: CredentialType;
    cb?: (user: User) => void;
  }) => Promise<void>;
  impersonateLogin: (impersonationToken: string) => Promise<void>;
  handleSsoAuthRequest: (authCode: string, state: string | null) => Promise<boolean>;
  isPerformingSsoExchange: boolean;
  assumeProfile: (profile: Profile, cb?: () => void) => void;
  logout: (redirectToLoginPage?: boolean, notifyAPI?: boolean) => void;
  updateLocalUser: () => void;
  jwtHasProfileId: boolean;
  isAssumingProfileId: string | undefined;
}

interface AccessTokenPayload extends JwtPayload {
  scope: string[];
  permissions?: string[];
  sessionId: string;
  role: string;
  // Note: Previous fields not used within Reader
  profileId?: string;
}

const AuthStateContext = React.createContext<Values>(undefined!);

axios.defaults.headers.common[HootHttpHeader.ReaderAppVersion] = LOCAL_APP_VERSION;

export const filterProfilesForEnabledOnly = (user: User) => {
  const filteredProfiles: Profile[] = [];

  user.profiles
    .filter((p) => (p.type === UserProfileType.Parent || p.type === UserProfileType.Student) && p.isEnabled)
    .forEach((p) => {
      filteredProfiles.push({
        id: p.id,
        number: p.number,
        name: p.name,
        type: p.type,
        isEnabled: p.isEnabled,
      });
    });

  user.profiles
    .filter((p) => p.type === UserProfileType.Teacher)
    .forEach((p) => {
      filteredProfiles.push({
        id: p.id,
        number: p.number,
        name: p.name,
        type: p.type,
        isEnabled: p.isEnabled,
      });
    });

  user.profiles
    .filter((p) => p.type === UserProfileType.DistrictRep && p.isEnabled)
    .forEach((p) => {
      filteredProfiles.push({
        id: p.id,
        number: p.number,
        name: p.name,
        type: p.type,
        isEnabled: p.isEnabled,
      });
    });

  return filteredProfiles;
};

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const newAppVersionAvailable = useSelector((state: RootState) => state.application.newAppVersionAvailable);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [jwtHasProfileId, SetJwtHasProfileId] = useState<boolean>(false);
  const [tokens, setTokens] = useLocalStorage<Tokens | undefined>(LOCAL_STORAGE_USER_TOKEN_KEY);
  const [user, setUser] = useLocalStorage<User | undefined>(LOCAL_STORAGE_USER_KEY);
  const [hootBrowserSessionId] = useSessionStorage<string>(SessionStorageKey.HOOT_SESSION_ID, uuidv4());
  const loggingInterceptorHandle = useRef<number | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { getCurrentTime } = useCurrentTime();
  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [isAssumingProfileId, setIsAssumingProfileId] = useState<string>();

  const refreshTokenMutation = useRefreshToken();
  const loginMutation = useLogin();
  const impersonateLoginMutation = useImpersonateLogin();
  const ssoTokenExchangeMutation = useSsoTokenExchange();
  const assumeProfileMutation = useAssumeProfile();
  const logoutMutation = useLogout();
  const { profile } = useProfile();

  useEffect(() => {
    if (!!profile?.type) {
      zendeskInitializeLiveChatScript(profile.type);
    }
  }, [profile]);

  useEffect(() => {
    if (!!tokens?.zendeskToken) {
      zendeskLoginUser(tokens?.zendeskToken);
    }
  }, [tokens?.zendeskToken]);

  const isAccessTokenExpired = useCallback(() => {
    if (tokens) {
      const decodedAccessToken = jwtDecode<JwtPayload>(tokens.accessToken);
      const timeRemainingInMs = decodedAccessToken.exp! * 1000 - getCurrentTime();
      return timeRemainingInMs <= 0;
    }
    return true;
  }, [tokens, getCurrentTime]);

  const isRefreshTokenExpired = useCallback(() => {
    if (tokens && tokens.refreshToken) {
      const decodedRefreshToken = jwtDecode<JwtPayload>(tokens.refreshToken);
      const timeRemainingInMs = decodedRefreshToken.exp! * 1000 - getCurrentTime();
      return timeRemainingInMs <= 0;
    }
    return true;
  }, [tokens, getCurrentTime]);

  const refreshTokens = () => {
    if (tokens) {
      const accessTokenExpired = isAccessTokenExpired();
      const refreshTokenExists = !!tokens.refreshToken;
      const refreshTokenExpired = isRefreshTokenExpired();
      const shouldAttemptRefresh = accessTokenExpired && refreshTokenExists && !refreshTokenExpired;
      if (shouldAttemptRefresh && !refreshInProgress) {
        setRefreshInProgress(true);

        refreshTokenMutation.mutate(tokens?.refreshToken!, {
          onSuccess: (data) => {
            setTokens(data);
          },
          onError: (err) => {
            console.error(err);
            if (err.response?.status === 401) {
              // refresh token itself was invalid
              logout(true, false);
            }
          },
          onSettled: () => {
            setRefreshInProgress(false);
          },
        });
      } else if (accessTokenExpired && (!refreshTokenExists || refreshTokenExpired)) {
        logout(true, false);
      }
    }
  };

  useInterval(() => {
    refreshTokens();
  }, 5000);

  useEffect(() => {
    refreshTokens(); // Initial call so we don't wait 5 seconds on initial load if tokens are expired
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tokens) {
      const isExpired = isAccessTokenExpired();
      if (!isExpired) {
        setIsAuthenticated(true);
        setAuthHeader(tokens);
      }
      const decodedAccessToken = jwtDecode<AccessTokenPayload>(tokens.accessToken);
      SetJwtHasProfileId(!!decodedAccessToken.profileId);
    } else {
      setIsAuthenticated(false);
      SetJwtHasProfileId(false);
    }
  }, [tokens]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user) {
      setIsAuthenticated(false);
    }
  }, [user]);

  useEffect(() => {
    axios.defaults.headers.common[HootHttpHeader.SessionId] = hootBrowserSessionId;
  }, [hootBrowserSessionId]);

  useEffect(() => {
    if (loggingInterceptorHandle.current !== undefined) {
      axios.interceptors.request.eject(loggingInterceptorHandle.current);
    }

    loggingInterceptorHandle.current = axios.interceptors.request.use(
      (config) => {
        config.headers[HootHttpHeader.RequestId] = uuidv4();
        config.headers[HootHttpHeader.UserId] = user?.id;
        config.headers[HootHttpHeader.ProfileType] = profile?.type;
        config.headers[HootHttpHeader.ProfileId] = profile?.id;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }, [user?.id, profile?.id, profile?.type]);

  /**
   * This function provides a non-optional reference to the current User so most components don't have to deal with optional
   * semantics - we'll always have a User when they're logged in.
   * There are rare exceptions where we have to work around this (see FreeLessonRegistrationWizard).
   */
  const getUser = () => {
    if (user) {
      return user;
    }
    throw new Error('User not defined - may be accessing outside authenticated context');
  };

  const login = async (params: {
    credentials: string;
    password: string;
    friendlyId?: string;
    credentialType: CredentialType;
    cb?: (user: User) => void;
  }) => {
    const { credentials, password, friendlyId, credentialType } = params;
    await loginMutation.mutateAsync(
      {
        credentials: credentials,
        password: password,
        friendlyId: friendlyId,
        credentialType: credentialType,
      },
      {
        onSuccess: (loginData) => {
          if (loginData.profileToAssume) {
            setUser(loginData.user);
            setTokens(loginData.tokens);
            dispatch(updateProfile(loginData.profileToAssume));
            console.log(
              `Logged in with profile already assumed. Profile type: ${loginData.profileToAssume.type} | Profile id: ${loginData.profileToAssume.id}`,
            );
          } else {
            const filteredProfiles = filterProfilesForEnabledOnly(loginData.user);
            dispatch(setLoginType(LoginType.Email));
            dispatch(updateProfiles(filteredProfiles));
            setUser(loginData.user);
            setTokens(loginData.tokens);
            console.log(`Logged in, profile not yet assumed.`);
          }
          if (params.cb) {
            params.cb(loginData.user);
          }
        },
      },
    );
  };

  const impersonateLogin = async (impersonationToken: string) => {
    await impersonateLoginMutation.mutateAsync(
      {
        token: impersonationToken,
      },
      {
        onSuccess: (data) => {
          logout(false, false, () => {
            setUser(data.user);
            setTokens(data.tokens);
            dispatch(setLoginType(LoginType.Email));
            const filteredProfiles = filterProfilesForEnabledOnly(data.user);
            dispatch(success(`Successfully impersonated ${data.user.email}`));
            dispatch(createFlashMessage({ message: `Successfully impersonated ${data.user.email}` }));
            dispatch(updateProfiles(filteredProfiles));
          });
        },
        onError: (err) => {
          dispatch(error(`Error impersonating user: ${err.message}`));
          dispatch(createFlashMessage({ message: `Error impersonating user: ${err.message}`, variant: 'error' }));
        },
      },
    );
  };

  const handleSsoAuthRequest = async (authCode: string, state: string | null): Promise<boolean> => {
    await ssoTokenExchangeMutation.mutateAsync(
      {
        code: authCode,
        state: state ?? undefined,
      },
      {
        onSuccess: (data: ExchangeSsoTokenResponse) => {
          logout(false, false, () => {
            setUser(data.user);
            setTokens(data.tokens);
            dispatch(setLoginType(LoginType.Student));
            const filteredProfiles = filterProfilesForEnabledOnly(data.user);
            dispatch(updateProfiles(filteredProfiles));
            console.log(`SSO: Logged in, profile not yet assumed - ${filteredProfiles.length} active profiles`);
          });
        },
        onError: (err) => {
          console.error(`SSO: Error logging in: ${err.message}`);
        },
      },
    );

    dispatch(createFlashMessage({ message: `Successfully Logged in` }));
    return true;
  };

  const assumeProfile = async (newProfile: Profile, cb?: () => void) => {
    // If you're already logged in as this person, then just nav back home.
    if (newProfile.id === profile?.id) {
      if (cb) {
        cb();
      } else {
        navigate(routesDictionary.home.url);
      }
      return;
    }
    if (tokens) {
      setIsAssumingProfileId(newProfile.id);
      assumeProfileMutation.mutate(newProfile.id, {
        onSuccess: (data) => {
          setTokens(data);
          dispatch(updateProfile(newProfile));
          console.log(`Assumed profile. Profile type: ${newProfile.type} | Profile id: ${newProfile.id}`);
          if (cb) {
            cb();
          }
        },
        onSettled: () => {
          setIsAssumingProfileId(undefined);
        },
      });
    } else {
      console.error('Can not assume profile. Missing authentication token.');
    }
  };

  // Order of operation is important here, because we can't call getUser until
  // we have a token and thus have the authorization header set in axios
  const userQuery = useGetUser(user?.id || '', {
    enabled: !!tokens?.accessToken && isAuthHeaderSet() && !!user,
    onSuccess: (data: User) => {
      setUser(data);
      dispatch(updateProfiles(data.profiles));
    },
  });

  const performLogoutRedirect = (shouldRedirect: boolean) => {
    if (!shouldRedirect) {
      return;
    }
    if (window.sessionStorage.getItem(SessionStorageKey.DISTRICT_LOGIN_FRIENDLY_ID)) {
      // If we have a value set for the friendlyId (from logging in via District Rep Login page)
      // Then we redirect to their district/school specific login page instead.
      navigate(routesDictionary.login.district.url(window.sessionStorage.getItem(SessionStorageKey.DISTRICT_LOGIN_FRIENDLY_ID)!));
    } else {
      navigate(routesDictionary.home.url);
    }
  };

  const logout = (redirectToLoginPage = true, notifyAPI = true, callback?: () => void) => {
    if (notifyAPI) {
      logoutMutation.mutate(undefined, {
        onSettled: () => {
          clearProfileData();
          performLogoutRedirect(redirectToLoginPage);
          if (zendeskLiveChatScriptInitialized()) {
            zendeskLogoutUser();
          }
          dispatch(success(`Successfully logged out.`));
          dispatch(createFlashMessage({ message: `Successfully logged out.` }));
          if (newAppVersionAvailable) {
            window.location.reload();
          }
        },
      });
    } else {
      clearProfileData();
      performLogoutRedirect(redirectToLoginPage);
      if (newAppVersionAvailable) {
        window.location.reload();
      }
    }

    if (callback) {
      callback();
    }
  };

  const clearProfileData = () => {
    sentry.clearSentryUser();

    dispatch(resetProfile());
    dispatch(resetUpcomingLessons());
    dispatch(resetActiveLesson());
    dispatch(resetAllLibraries());
    dispatch(resetAllReaders());
    setTokens(undefined);
    setUser(undefined);
    setIsAuthenticated(false);
    clearLocalStorage();
    window.sessionStorage.removeItem(SessionStorageKey.EDLINK_SSO_STATE);
  };

  const updateLocalUser = async () => {
    await userQuery.refetch();
  };

  return (
    <AuthStateContext.Provider
      value={{
        isAuthenticated,
        tokens,
        getUser,
        login,
        impersonateLogin,
        handleSsoAuthRequest,
        isPerformingSsoExchange: ssoTokenExchangeMutation.isLoading,
        assumeProfile,
        logout,
        updateLocalUser,
        jwtHasProfileId,
        isAssumingProfileId: isAssumingProfileId,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

function setAuthHeader(tokens: Tokens) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`;
}

function isAuthHeaderSet(): boolean {
  return !!axios.defaults.headers.common['Authorization'];
}

export const useAuth = () => {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
