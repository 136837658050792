import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { InputAdornment, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSearchDistrictRepresentativeEnrolmentsFilters from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolmentsFilters';
import useSearchDistrictRepresentativeStudentsResponse, {
  DistrictRepresentativeStudentResponse,
  DistrictRepresentativeStudentsQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeStudents';
import useGetDistrictRepPermissions from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { PasswordStatus } from '@hoot/models/api/student';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { DistrictRepAccessLevelCombinations, useHasDistrictPermission } from '@hoot/routes/secure/DistrictRepProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import Page from '@hoot/ui/components/v2/core/Page';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import PasswordDialog from './PasswordDialog';
import StudentsFiltersDialog, { DialogFormFields, FieldsToShow } from './StudentsFiltersDialog';

interface StudentRow {
  id: string;
  name: React.ReactNode;
  number: string;
  school: React.ReactNode;
  enrolmentFriendlyIds: React.ReactNode;
  enrolmentStatuses: React.ReactNode;
  passwordStatus: React.ReactNode;
}

interface DistrictRepresentativeStudents {
  count: number;
  page: number;
  pageSize: number;
  students: StudentRow[];
}

const IGNORE_FILTERS = ['searchText', 'page', 'pageSize', 'sortBy', 'orderBy'];
type DistrictRepresentativeStudentsQueryKeys = keyof DistrictRepresentativeStudentsQuery;

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  query: DistrictRepresentativeStudentsQuery;
  setQuery: React.Dispatch<React.SetStateAction<DistrictRepresentativeStudentsQuery>>;
  districtRepresentativeId?: string;
}) => {
  const { badgesWithMultiSelectors, query, setQuery, districtRepresentativeId } = props;
  const enrolments = useSearchDistrictRepresentativeEnrolmentsFilters(districtRepresentativeId!, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
  });

  return (
    <Grid container gap={2}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterKey, filterValue]: [DistrictRepresentativeStudentsQueryKeys, any] = badge;
        let key = filterKey;
        let value;

        if (filterKey === 'startDate' || filterKey === 'endDate') {
          value = DateTime.fromMillis(filterValue).toFormat('LL-dd-yyyy');
        } else if (filterKey === 'enrolmentId') {
          value = enrolments.data?.find((enrolment) => enrolment.id === filterValue)?.friendlyId;
        } else {
          value = capitalCase(filterValue);
        }

        return (
          <Chip
            key={`${key}-${value}`}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              setQuery((prevState) => {
                const value = query[filterKey];
                return {
                  ...prevState,
                  [filterKey]: Array.isArray(value) ? (value as any)?.filter((val: string) => val !== filterValue) : undefined,
                };
              });
            }}
          />
        );
      })}
    </Grid>
  );
};

export function DistrictRepStudentsPage() {
  const [searchParams, _] = useSearchParams();
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);

  const [searchText, setSearchText] = useState<string>('');
  const [query, setQuery] = useState<DistrictRepresentativeStudentsQuery>({
    searchText: undefined,
    passwordStatus: undefined,
    enrolmentId: searchParams.get('enrolmentId') ?? undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    grade: undefined,
    page: 1,
    pageSize: 10,
    sortBy: 'name',
    orderBy: OrderBy.Asc,
  });
  const [data, setData] = useState<DistrictRepresentativeStudents>();
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [studentInfo, setStudentInfo] = useState<DistrictRepresentativeStudentResponse | undefined>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const isEditMode = studentInfo?.passwordStatus === PasswordStatus.Set;
  const { data: districtRepPermissions } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasEnrolmentEditLevelOrHigherPermission = useHasDistrictPermission(
    DistrictRepAccessLevelCombinations.EnrolmentViewLevel,
    districtRepPermissions,
  );
  const hasSchoolOrDistrictPermission = useHasDistrictPermission(DistrictRepAccessLevelCombinations.SchoolOrDistrict, districtRepPermissions);

  const headers: HeaderData<StudentRow>[] = [
    { name: 'Name', property: 'name', isSortable: true },
    { name: 'Student Number', property: 'number', isSortable: true },
    { name: 'School', property: 'school', isSortable: true },
    { name: 'Enrolment ID(s)', property: 'enrolmentFriendlyIds' },
    { name: 'Enrolment Status(es)', property: 'enrolmentStatuses' },
    { name: 'Password Actions', property: 'passwordStatus' },
  ];

  const filterFieldToShow: FieldsToShow = {
    status: true,
    startDate: true,
    endDate: true,
    enrolmentId: true,
    grade: true,
    passwordStatus: true,
  };

  const passwordActionButton = (student: DistrictRepresentativeStudentResponse) => {
    const isPasswordSet = student.passwordStatus === PasswordStatus.Set;
    const buttonText = isPasswordSet ? 'Change' : 'Set';
    const buttonVariant = isPasswordSet ? 'outlined' : 'contained';

    const handleClickName = (student: DistrictRepresentativeStudentResponse) => () => {
      setStudentInfo(student);
      setShowPasswordDialog(true);
    };

    return (
      <Button onClick={handleClickName(student)} size="small" variant={buttonVariant}>
        {buttonText}
      </Button>
    );
  };
  const { isLoading } = useSearchDistrictRepresentativeStudentsResponse(districtRepresentativeId!, query, {
    onSuccess: (data) => {
      const students = data.data.map(
        (student): StudentRow => ({
          id: student.id,
          name: (
            <ProtectedLink to={routesDictionary.students.student.details.url(student.id)} hasPermission={hasEnrolmentEditLevelOrHigherPermission}>
              {student.name}
            </ProtectedLink>
          ),
          number: student.hfsStudentNumber ?? 'Not Set',
          school:
            student.schoolId && student.schoolName ? (
              <ProtectedLink to={routesDictionary.schools.school.students.url(student.schoolId)} hasPermission={hasSchoolOrDistrictPermission}>
                {student.schoolName}
              </ProtectedLink>
            ) : (
              <span>N/A</span>
            ),
          enrolmentFriendlyIds: student.enrolments.map((enrolment) => (
            <ProtectedLink
              to={routesDictionary.enrolments.enrolment.details.url(enrolment.id)}
              hasPermission={hasEnrolmentEditLevelOrHigherPermission}
            >
              <p>{enrolment.friendlyId}</p>
            </ProtectedLink>
          )),
          enrolmentStatuses: student.enrolments.map((enrolment) => <p>{capitalCase(enrolment.status)}</p>),
          passwordStatus: passwordActionButton(student),
        }),
      );
      const studentResponse = {
        count: data.count,
        page: data.page,
        pageSize: data.pageSize,
        students: students,
      };

      setData(studentResponse);
    },
  });

  const badges = () => {
    return Object.entries(query).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true)) as [
      DistrictRepresentativeStudentsQueryKeys,
      any,
    ][];
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const clearInput = () => {
    setSearchText('');
  };

  const handleInputChange: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    setQuery((current) => ({ ...current, searchText: text }));
  };

  const handleClose = () => {
    setShowDialog(false);
    setShowError('');
  };

  const onFilterChange = (updatedFields: DialogFormFields) => {
    setQuery((current) => ({ ...current, ...updatedFields }));
  };

  return (
    <>
      <SubHeader title={{ label: 'Students', isPII: false }} />

      <Page pageTitle="Hoot for Schools Enrolments | Hoot Reading">
        <Stack>
          <Stack>
            <Card title="Students" isLoading={isLoading} sx={{ overflowX: 'scroll' }}>
              <Stack marginTop="16px" direction="row" alignItems="center" alignSelf="center" gap={2}>
                <SearchTextField
                  label="Student Search"
                  searchInput={searchText}
                  onSearchInputChanged={handleInputChange}
                  onClearButtonClicked={clearInput}
                  onSearchInputDebounced={onSearchInputDebounced}
                  clearable
                  placeholder="Search"
                />

                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  startIcon={
                    <InputAdornment position="start">
                      <FilterAltOutlinedIcon sx={{ color: 'white' }} />
                    </InputAdornment>
                  }
                  sx={{ padding: '16px' }}
                  onClick={() => setShowFiltersDialog(true)}
                >
                  Filter
                </Button>
              </Stack>

              <Stack sx={{ marginTop: '20px' }}>
                <FilterChips
                  badgesWithMultiSelectors={badgesWithMultiSelectors}
                  query={query}
                  setQuery={setQuery}
                  districtRepresentativeId={districtRepresentativeId}
                />
              </Stack>

              <Stack marginTop="16px">
                <TableV2
                  allowRowsPerPage
                  isPaginated
                  isSortable
                  count={data?.count ?? 0}
                  data={data?.students ?? []}
                  headers={headers}
                  onPageChange={(event, page) => {
                    setQuery((prevState) => ({ ...prevState, page: page + 1 }));
                  }}
                  onRowsPerPageChange={(event) => {
                    setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
                  }}
                  onSortBy={(val: any) => {
                    setQuery((prevState) => ({
                      ...prevState,
                      page: 1,
                      sortBy: val,
                      orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                    }));
                  }}
                  page={data?.page ? data.page - 1 : 0}
                  sortOrder={query.orderBy}
                  sortBy={query.sortBy as any}
                  rowsPerPage={query.pageSize}
                />
              </Stack>
            </Card>
            <Grid container size={8}></Grid>
          </Stack>
          {showFiltersDialog ? (
            <StudentsFiltersDialog
              show={showFiltersDialog}
              onDismiss={() => setShowFiltersDialog(false)}
              showFields={filterFieldToShow}
              values={query}
              onChange={onFilterChange}
            />
          ) : null}
          {showPasswordDialog && studentInfo ? (
            <PasswordDialog
              show={showPasswordDialog}
              onDismiss={() => setShowPasswordDialog(false)}
              student={studentInfo}
              setShowSuccess={setShowSuccess}
              setShowError={setShowError}
            />
          ) : null}
          <Snackbar
            variant="dark"
            message={`${studentInfo?.name} password ${isEditMode ? 'updated' : 'set'}!`}
            open={showSuccess}
            onClose={() => setShowSuccess(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
          <Snackbar
            variant="error"
            message={'An unexpected error occurred'}
            actionLabel="View Error"
            open={!!showError}
            onAction={() => setShowDialog(true)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
          <BasicAlertDialog
            show={showDialog}
            onDismiss={handleClose}
            title={'Error'}
            content={showError}
            primaryAction={{
              onClick: handleClose,
              label: 'OK',
            }}
          />
        </Stack>
      </Page>
    </>
  );
}
