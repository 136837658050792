import { Box, CircularProgress, Divider, Skeleton, Stack, useTheme } from '@mui/material';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { featureFlags } from '@hoot/constants/featureFlags';
import { GetSsoLaunchInfoResponse, useGetEdlinkSsoLaunchInfo } from '@hoot/hooks/api/auth/useGetEdlinkSsoLaunchInfo';
import { EdlinkIntegrationProvider, GetLocationResponse, useGetLocation } from '@hoot/hooks/api/auth/useGetLocation';
import { CredentialType } from '@hoot/hooks/api/auth/useLogin';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import EdlinkSsoRedirectHandler, { handleSsoLaunchClick } from '@hoot/ui/components/v2/EdlinkSsoRedirectHandler';
import PrivacyPolicyTerms from '@hoot/ui/components/v2/PrivacyPolicyTerms';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import TextField from '@hoot/ui/components/v2/core/TextField';
import HootLogo from '@hoot/ui/components/v2/icons/HootLogo';
import ClassLink from '@hoot/ui/components/v2/icons/sso/ClassLink';
import Clever from '@hoot/ui/components/v2/icons/sso/Clever';
import Google from '@hoot/ui/components/v2/icons/sso/Google';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import ForgotPasswordModalStudent from '@hoot/ui/pages/v2/public/login/ForgotPasswordModalStudent';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Form {
  studentId: string;
  password: string;
}

const DistrictLoginPage = () => {
  usePageTitle('District Login | Hoot Reading');
  const { friendlyId } = useParams() as { friendlyId: string };

  const { login, isPerformingSsoExchange } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<GetLocationResponse | undefined>(undefined);
  const { isFeatureEnabled } = useFeatureFlags();
  const isEdlinkEnabled = isFeatureEnabled(featureFlags.SC_10618_Edlink);
  const [ssoLaunchInfo, setSsoLaunchInfo] = useState<GetSsoLaunchInfoResponse | undefined>(undefined);

  const { isFetching: isLocationFetching } = useGetLocation(friendlyId, {
    enabled: friendlyId !== 'sso',
    retry: false,
    onSuccess: (data: GetLocationResponse) => {
      setLocation(data);
    },
    onError: (err) => {
      const axiosError = isAxiosError(err) ? err : undefined;
      let errMsg = '';

      navigate(routesDictionary.login.url);

      if (axiosError?.response?.status === 400) {
        errMsg = `District/School "${friendlyId}" not found`;
      }

      dispatch(
        createFlashMessage({
          variant: 'error',
          message: errMsg ?? axiosError?.response?.data.message ?? 'Login failed.',
        }),
      );
    },
  });

  useGetEdlinkSsoLaunchInfo(location?.integrationId || null, {
    enabled: !!location && !!location.integrationId,
    cacheTime: 0,
    onSuccess: (data: GetSsoLaunchInfoResponse) => {
      setSsoLaunchInfo(data);
    },
    onError: () => {
      window.sessionStorage.removeItem(SessionStorageKey.EDLINK_SSO_STATE);
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
    control,
  } = useForm<Form>({
    defaultValues: {
      studentId: '',
      password: '',
    },
  });

  const onSubmit = async (data: Form) => {
    await login({
      credentials: data.studentId,
      password: data.password,
      credentialType: CredentialType.HFSNumber,
      friendlyId: friendlyId,
    })
      .then(() => {
        window.sessionStorage.setItem(SessionStorageKey.DISTRICT_LOGIN_FRIENDLY_ID, friendlyId);
      })
      .catch((err) => {
        console.error(err);
        setError('studentId', {
          type: 'manual',
        });
        setError('password', {
          type: 'manual',
          message: 'Incorrect student number or password.',
        });
      });
  };

  const SsoLogin = () => {
    if (!isEdlinkEnabled) {
      return null;
    }

    if (!location || !location.integrationId || !location.integrationProvider) {
      return null;
    }

    const LmsSpecificButton = () => {
      if (location.integrationProvider === EdlinkIntegrationProvider.Canvas) {
        return (
          <Button onClick={() => handleSsoLaunchClick(ssoLaunchInfo, friendlyId)} variant="outlined" fullWidth>
            Login with Canvas
          </Button>
        );
      } else if (location.integrationProvider === EdlinkIntegrationProvider.ClassLink) {
        return (
          <Button
            onClick={() => handleSsoLaunchClick(ssoLaunchInfo, friendlyId)}
            variant="outlined"
            fullWidth
            sx={{
              color: '#4e95cc',
              gap: '24px',
            }}
          >
            <ClassLink />
            Login with ClassLink
          </Button>
        );
      } else if (location.integrationProvider === EdlinkIntegrationProvider.Clever) {
        return (
          <Button
            onClick={() => handleSsoLaunchClick(ssoLaunchInfo, friendlyId)}
            variant="outlined"
            fullWidth
            sx={{
              color: '#1464FF',
              gap: '24px',
            }}
          >
            <Clever />
            <HootTypography isPII={false} variant="titlemedium">
              Sign in with Clever
            </HootTypography>
          </Button>
        );
      } else if (location.integrationProvider === EdlinkIntegrationProvider.Google) {
        return (
          <Button
            onClick={() => handleSsoLaunchClick(ssoLaunchInfo, friendlyId)}
            variant="outlined"
            fullWidth
            sx={{
              color: '#1464FF',
              gap: '24px',
            }}
          >
            <Google />
            <HootTypography isPII={false} variant="titlemedium">
              Sign in with Google
            </HootTypography>
          </Button>
        );
      }
      return null;
    };

    return (
      <>
        <Divider
          sx={{
            marginBottom: '24px',
            '&::before, &::after': {
              borderColor: hootTokens.palette.black,
            },
          }}
        >
          <HootTypography variant="labelsmall" isPII={false}>
            Or continue with
          </HootTypography>
        </Divider>
        <Box mb={3}>
          <LmsSpecificButton />
        </Box>
      </>
    );
  };

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          padding: '24px',
          backgroundImage: 'url("/images/flf-background.webp")',
          backgroundSize: 'cover',
          '@media (max-height: 700px)': {
            height: '800px',
            justifyContent: 'flex-start',
          },
        }}
      >
        <Stack
          sx={{
            width: '100%',
            maxWidth: '400px',
            padding: '24px',
            borderRadius: '8px',
            background: hootTokens.palette.white,
          }}
        >
          <EdlinkSsoRedirectHandler />

          <HootLogo sx={{ width: 134, height: 48, margin: theme.spacing(4, 'auto', 5) }} />

          {isLocationFetching ? (
            <Skeleton variant="text" width={220} height={40} sx={{ m: theme.spacing(0, 'auto', 2) }} />
          ) : (
            <HootTypography isPII={true} variant="titlelarge" textAlign="center" mb={2}>
              {location?.name}
            </HootTypography>
          )}

          {isPerformingSsoExchange ? (
            <Stack alignItems="center" gap={3}>
              <HootTypography variant="bodylarge" textAlign="center" isPII={false}>
                Logging you in...
              </HootTypography>
              <CircularProgress />
            </Stack>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2}>
                {/* Student ID */}
                <Controller
                  name="studentId"
                  control={control}
                  render={({ field: { onChange }, fieldState }) => (
                    <TextField
                      data-hoot-canary-id="studentId"
                      label={'Student ID'}
                      helperText={fieldState.error?.message || ''}
                      error={!!fieldState.error}
                      onChange={onChange}
                      fullWidth
                      required
                      autoFocus
                    />
                  )}
                />

                {/* Password */}
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange }, fieldState }) => (
                    <TextField
                      data-hoot-canary-id="password"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message || ''}
                      label={'Password'}
                      fullWidth
                      required
                      onChange={onChange}
                      type="password"
                    />
                  )}
                />
                <Button data-hoot-canary-id="btnLogin" color="primary" isLoading={isSubmitting} type="submit" variant="contained" fullWidth>
                  Login
                </Button>
                <Button onClick={() => setOpen(true)} variant="text">
                  I forgot my password
                </Button>

                <SsoLogin />
                <PrivacyPolicyTerms />
              </Stack>
            </form>
          )}
        </Stack>

        {/* `Not a Student?` section */}
        <Box
          sx={{
            width: '100%',
            maxWidth: '400px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderRadius: '8px',
            background: hootTokens.palette.warning[180],
          }}
        >
          <HootTypography isPII={false} variant="bodylarge">
            Not a Student?
          </HootTypography>
          <Button variant="outlined" size="small" onClick={() => navigate(routesDictionary.login.url)}>
            Login with Email
          </Button>
        </Box>
      </Stack>

      <ForgotPasswordModalStudent open={open} setOpen={setOpen} />
    </>
  );
};

export default DistrictLoginPage;
