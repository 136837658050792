import { useDailyEvent, useParticipantIds } from '@daily-co/daily-react';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ScheduledLesson } from '@hoot/events/interfaces/scheduled-lesson';
import useLessonMessageHandler from '@hoot/hooks/lessons/useLessonMessageHandler';
import { useInLessonLogger } from '@hoot/hooks/useInLessonLogger';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { useReaders } from '@hoot/redux/reducers/readingSlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import CelebrationsDisplay from '@hoot/ui/components/v2/core/CelebrationsDisplay';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import useStudentLessonController from '@hoot/ui/pages/v2/student/lesson/useStudentLessonController';
import useHRAMessageHandler from '../../../../../hooks/hra/useHRAMessageHandler';
import usePMAMessageHandler from '../../../../../hooks/pma/usePMAMessageHandler';
import StudentBlade from '../../../../components/v2/student-blade/StudentBlade';

const JOIN_DELAY_MS = 1000;
const HOOT_KIDS_BOOK_ID = '3f69f9d0-d776-4ed3-9e97-8d2547aafeb0';

interface StudentInLessonLayoutProps {
  inLesson: ScheduledLesson;
}

/**
 * Wrapped for all routes considered to be in a lesson.
 *
 * NOTE: You can't just land on this page without having gone through the "Get Ready" modal first.
 * Attempting to manually navigate to this page should end up redirecting the user back to the home page.
 */
const StudentInLessonLayout = (props: StudentInLessonLayoutProps) => {
  const { inLesson } = props;
  const lessonId = inLesson.lessonId;

  const [joined, setJoined] = useState(false);

  const { activePage } = useActiveLessonState();
  const { inLessonReader } = useReaders();
  const { book } = inLessonReader;
  const activeBookId = book?.id;

  // Controller to publish outgoing messages.
  const lessonController = useStudentLessonController({ inLesson: inLesson! });

  // Listen for incoming lesson messages.
  useLessonMessageHandler();

  // Listen for incoming PMA messages
  usePMAMessageHandler();

  // Listen for incoming HRA messages
  useHRAMessageHandler();

  const navigate = useNavigate();

  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const hasTeacherJoined = !!remoteParticipantIds.length;

  useInLessonLogger(inLesson?.startsAt);

  useDailyEvent('joined-meeting', (_event) => {
    // Hacky fix!
    // When a student joins a lesson, the remote participant (teacher) data still takes a second to come back.
    // So, so avoid showing the "Hoot Kids" book flashing on the screen right after joining, just wait one more second.
    // If the teacher isn't in the lesson, then the student will see the Hoot Kids book. Else, the student will just
    // see the expected lesson UI _without_ seeing the Hoot Kids book at all.
    setTimeout(() => {
      setJoined(true);
    }, JOIN_DELAY_MS);
  });

  // Load the lesson state on page-load.
  useEffect(
    () => {
      lessonController.refreshLessonState();

      // Publish leave-lesson event when this page unmounts.
      return () => {
        lessonController.leaveLesson();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // When the lesson's "active page" changes, then we also need to change the current path.
  useEffect(() => {
    // If we don't even know what page we're supposed to be looking at, then we know the lesson refresh request hasn't
    // come back yet. Don't display any content the UI just yet.
    if (!joined || !activePage) {
      navigate(routesDictionary.lesson.url(lessonId), { replace: true });
    } else if (!hasTeacherJoined) {
      // Else if no teacher is in the lesson, then the student gets to read the Hoot Kids book.
      // That's pretty much all the student can do until the teacher joins.
      navigate(routesDictionary.lesson.library.soloBook.url(lessonId, HOOT_KIDS_BOOK_ID), { replace: true });
      return;
    } else {
      // Else, both participants are in the lesson. Now we track the lesson's active page. When this property changes,
      // so does the URL, and so does the UI.
      switch (activePage) {
        case ActiveLessonPage.Library:
          navigate(routesDictionary.lesson.library.url(lessonId), { replace: true });
          return;
        case ActiveLessonPage.Reader:
          // A book ID must also exist if we're going to show the reader.
          if (activeBookId) {
            navigate(routesDictionary.lesson.library.book.url(lessonId!, activeBookId), { replace: true });
          }
          return;
        case ActiveLessonPage.PMA:
          navigate(routesDictionary.lesson.progressMonitoringAssessment.url(lessonId), { replace: true });
          break;
        case ActiveLessonPage.HRA:
          navigate(routesDictionary.lesson.hootReadingAssessment.url(lessonId), { replace: true });
          break;
        case ActiveLessonPage.Whiteboard:
          navigate(routesDictionary.lesson.whiteboard.url(lessonId), { replace: true });
          break;
      }
    }
  }, [joined, activePage, activeBookId, lessonId, hasTeacherJoined, navigate]);

  return (
    <>
      <Stack direction="row" height="100%" sx={{ overflowY: 'hidden' }}>
        <StudentBlade />

        <Box
          sx={{
            flex: 1,
            position: 'relative',
            '&::after': {
              content: '""',
              backgroundImage: 'url("/images/logo-v2.svg")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '443px 167px',
              backgroundPosition: 'center center',
              opacity: 0.1,
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: -1,
            },
          }}
        >
          <Outlet />
        </Box>
      </Stack>

      <CelebrationsDisplay />
    </>
  );
};

const Wrapper = () => {
  const { inLesson } = useActiveLessonState();
  const lessonId = inLesson?.lessonId;

  const navigate = useNavigate();

  const { onDismissStudent: onDismissAvSettingsDialog } = useAudioVideoSettingsDialog();

  useEffect(
    () => {
      // This is a hack. The "Get Ready" modal doesn't dismiss itself upon joining the lesson, so do it here (shrug).
      // There is probably a better way of handling this.
      onDismissAvSettingsDialog();

      // If we're not actually in a lesson, then redirect back to the home page.
      if (!lessonId) {
        navigate(routesDictionary.home.url, { replace: true });
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lessonId],
  );

  return <>{inLesson && <StudentInLessonLayout inLesson={inLesson} />}</>;
};

export default Wrapper;
