import { CancellationReason, ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface LessonSearchRequest {
  fromDate: number;
  toDate: number;
}

export interface LessonSearchResponseLesson {
  id: string;
  prefixedLessonNumber: string;
  startsAt: number;
  durationInMinutes: number;
  studentId: string | undefined;
  studentName: string | undefined;
  prefixedStudentNumber: string;
  status: ScheduledLessonStatus;
  cancellationReason: CancellationReason | null;
}

export interface LessonSearchResponse {
  lessons: LessonSearchResponseLesson[];
}

export async function getTeacherLessons(query: LessonSearchRequest) {
  const { data } = await axios.post<LessonSearchResponse>(`${config.apiUrl}/public/scheduler/teacher-lessons`, query);
  return data;
}

export default function useGetTeacherLessons(query: LessonSearchRequest) {
  return useQuery([QueryKey.TeacherLessonSearch, query], () => getTeacherLessons(query));
}
