import { Navigate, Route, Routes } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { AlreadyAuthenticatedSsoRequestHandler } from '@hoot/ui/components/v2/EdlinkSsoRedirectHandler';
import ImpersonateLoginView from '@hoot/ui/components/v2/ImpersonateLoginView';
import Logout from '@hoot/ui/components/v2/Logout';
import PublicApplicationLayout from '@hoot/ui/components/v2/layout/PublicApplicationLayout';
import SelectProfilePage from '@hoot/ui/pages/v2/no-profile/SelectProfilePage';
import ChangePasswordV2 from '@hoot/ui/pages/v2/public/change-password/ChangePasswordV2';
import { FreeLessonLogin } from '@hoot/ui/pages/v2/public/free-lesson/login/FreeLessonLogin';
import PublicStudentSchedule from '@hoot/ui/pages/v2/public/public-student-schedule/PublicStudentSchedule';
import FreeLessonRegistrationLayout from '../../../ui/pages/v2/public/free-lesson/registration/FreeLessonRegistrationLayout';
import FreeLessonRegistrationWizard from '../../../ui/pages/v2/public/free-lesson/registration/FreeLessonRegistrationWizard';

/**
 * When we're authenticated, but we haven't selected a profile yet.
 */
const NoProfileRouter = () => {
  return (
    <Routes>
      <Route path={routesDictionary.login.path} element={<AlreadyAuthenticatedSsoRequestHandler />} />
      {/* Main Routes */}
      <Route element={<PublicApplicationLayout />}>
        <Route path={routesDictionary.freeLesson.path} element={<FreeLessonRegistrationLayout />}>
          <Route path={routesDictionary.freeLesson.login.path} element={<FreeLessonLogin />} />
          <Route path={routesDictionary.freeLesson.registration.path} element={<FreeLessonRegistrationWizard />} />
          <Route index element={<Navigate replace to={routesDictionary.freeLesson.registration.path} />} />
        </Route>
        <Route path={routesDictionary.changePassword.path} element={<ChangePasswordV2 />} />
        <Route path={routesDictionary.selectProfile.path} element={<SelectProfilePage />} />
        <Route path={routesDictionary.impersonateLogin.path} element={<ImpersonateLoginView />} />
        <Route path={routesDictionary.publicStudentSchedule.path}>
          <Route path={routesDictionary.publicStudentSchedule.location.path} element={<PublicStudentSchedule />} />
        </Route>
      </Route>

      {/* Catch-all & Logout Route */}
      <Route path={routesDictionary.logout.path} element={<Logout />} />
      <Route path="*" element={<Navigate to={routesDictionary.selectProfile.url} />} />
    </Routes>
  );
};

export default NoProfileRouter;
