import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import useGetStudentProfileV2 from '@hoot/hooks/api/user/useGetStudentProfileV2';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import ParentDetailsCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/profile/ParentDetailsCard';
import StudentDetailsCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/profile/StudentDetailsCard';
import ActionRequiredCard from './ActionRequiredCard';
import TimelineSnapshotCard from './TimelineSnapshotCard';

const StudentProfilePage = () => {
  const { studentProfileId } = useParams();
  const theme = useTheme();
  const sub900px = useMediaQuery(theme.breakpoints.down('md'));
  const { data: student, isFetching } = useGetStudentProfileV2(studentProfileId!);

  return (
    <PageLayout>
      <Grid container spacing={2} flexDirection={sub900px ? 'column-reverse' : 'row'}>
        <Grid size={{ xs: sub900px ? 12 : 6 }}>
          <Stack gap={2}>
            <StudentDetailsCard isLoading={isFetching} student={student} />
            {student?.parent && <ParentDetailsCard parent={student.parent} />}
          </Stack>
        </Grid>

        <Grid size={{ xs: sub900px ? 12 : 6 }}>
          <Stack gap={2}>
            <TimelineSnapshotCard student={student} />
            <ActionRequiredCard />
          </Stack>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default StudentProfilePage;
